import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import cc from 'currency-codes';
import { Grid, Loader, Item, Button, Input, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { fetchTransaction, fetchMoreTransaction } from '../actions';
import ErrorMessage from './ErrorMessage';
import HistoryTransactions from './HistoryTransactions';
import AuthenticatedLayout from './AuthenticatedLayout';
import { useTranslation } from 'react-i18next';
const History = ({
  fetchTransaction,
  fetchMoreTransaction,
  transactions,
  errors,
  isFetching,
  currentUser,
  hasMore,
}) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [currency, setCurrency] = useState();
  const [isEdit, setIsEdit] = useState(false);

  const currencyCodes = () => {
    return _.map(cc.codes(), (code) => {
      return {
        key: code,
        text: code,
        value: code,
      };
    });
  };

  const handleLoadMoreButton = async () => {
    const lastTransaction = transactions[transactions.length - 1];
    if (lastTransaction) {
      await fetchMoreTransaction(
        startDate,
        endDate,
        currency,
        lastTransaction.createdAt
      );
    }
  };

  const renderInput = () => {
    return (
      <div>
        <Grid>
          <Grid.Column mobile={16} computer={5}>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              customInput={
                <Input
                  type="text"
                  label={{ basic: true, content: t('Start') }}
                  labelPosition="left"
                />
              }
            />
          </Grid.Column>
          <Grid.Column mobile={16} computer={5}>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              customInput={
                <Input
                  type="text"
                  label={{ basic: true, content: t('End') }}
                  labelPosition="left"
                />
              }
            />
          </Grid.Column>
          <Grid.Column mobile={16} computer={5}>
            <Dropdown
              clearable
              selection
              search
              value={currency}
              onChange={(e, data) => setCurrency(data.value)}
              options={currencyCodes()}
              placeholder={t('Currency')}
            />
          </Grid.Column>
        </Grid>
      </div>
    );
  };

  useEffect(() => {
    const getTransaction = async () => {
      await fetchTransaction(startDate, endDate, currency);
    };
    if (startDate && endDate) {
      getTransaction();
    }
  }, [startDate, endDate, currency, currentUser]);

  if (isFetching) {
    return (
      <div>
        <AuthenticatedLayout>
          <h2>{t('History')}</h2>
          <Loader inline="centered" active size="medium" content="Loading" />
        </AuthenticatedLayout>
      </div>
    );
  } else if (errors) {
    return (
      <div>
        <AuthenticatedLayout>
          <h2>{t('History')}</h2>
          {renderInput()}
          <ErrorMessage errors={errors} />
        </AuthenticatedLayout>
      </div>
    );
  } else {
    return (
      <div>
        <AuthenticatedLayout>
          <Item.Group>
            <Item>
              <h2>{t('History')}</h2>
            </Item>
            <Item>
              <Item.Content>{renderInput()}</Item.Content>
              {_.size(transactions) > 0 && (
                <Item.Content>
                  <Button
                    basic
                    color={isEdit ? 'grey' : 'blue'}
                    onClick={(e) => setIsEdit(!isEdit)}
                    floated="right"
                  >
                    {isEdit ? t('Cancel') : t('Edit')}
                  </Button>
                </Item.Content>
              )}
            </Item>
          </Item.Group>
          <HistoryTransactions transactions={transactions} isEdit={isEdit} />
          <br />
          {hasMore && (
            <Button primary onClick={handleLoadMoreButton}>
              Load more
            </Button>
          )}
        </AuthenticatedLayout>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    transactions: state.transactions?.results,
    isFetching: state.transactions?.isFetching,
    errors: state.transactions?.errors,
    hasMore: state.transactions?.hasMore,
  };
};

export default connect(mapStateToProps, {
  fetchTransaction,
  fetchMoreTransaction,
})(History);
