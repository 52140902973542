import React, { useEffect, useRef } from 'react';
import {
  Modal,
  Button,
  Input,
  Label,
  Form as SemanticForm,
  StatisticLabel,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { StyledButton } from './styledComponents';
import ErrorMessage from './ErrorMessage';
import { sendReceipt, clearReceipt } from '../actions';
import ReceiptToPrint from './ReceiptToPrint';
import ReactToPrint from 'react-to-print';

const renderInput = ({ input, placeholder, meta }) => {
  const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
  return (
    <div className={className}>
      <Input autoComplete="off" placeholder={placeholder} {...input} />
      {renderError(meta)}
    </div>
  );
};

const renderError = ({ error, touched }) => {
  if (touched && error) {
    return (
      <Label basic color="red" pointing>
        {error}
      </Label>
    );
  }
};

const AutoReceiptEmailModal = ({
  open,
  didPressClose,
  email,
  groupId,
  sendReceipt,
  clearReceipt,
  isSending,
  isSuccess,
  errors,
  lastTransactions,
}) => {
  useEffect(() => {
    if (isSuccess && !isSending) {
      form.reset();
      form.resetFieldState('email');
    }
  }, [isSuccess, isSending]);
  let submit, form;
  const onSubmit = async (formValues) => {
    await sendReceipt(formValues.email, groupId);
    form.restart();
  };

  const componentRef = useRef();

  return (
    <div>
      <Modal
        onClose={() => {
          form.restart();
          didPressClose();
          clearReceipt();
        }}
        open={open}
      >
        <Modal.Header>Send receipt</Modal.Header>
        <Modal.Content>
          <Form
            onSubmit={onSubmit}
            initialValues={{ email }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = 'Enter an email to send';
              }
              return errors;
            }}
            render={({ handleSubmit, form: renderForm }) => {
              submit = handleSubmit;
              form = renderForm;
              return (
                <SemanticForm>
                  <Field name="email" placeholder="Email">
                    {(props) => {
                      return renderInput(props);
                    }}
                  </Field>
                </SemanticForm>
              );
            }}
          />
          {errors && <ErrorMessage errors={errors} />}
        </Modal.Content>
        <Modal.Actions>
          <ReactToPrint
            trigger={() => (
              <StyledButton floated="left" primary>
                Print Receipt
              </StyledButton>
            )}
            content={() => componentRef.current}
          />
          <Button
            content={isSuccess ? 'Go back' : 'No Receipt'}
            onClick={() => {
              didPressClose();
              clearReceipt();
            }}
          />
          <StyledButton
            primary={!isSuccess}
            color={isSuccess ? 'green' : null}
            type="submit"
            loading={isSending}
            content={isSuccess ? 'Sent' : 'Send Receipt'}
            disabled={isSuccess}
            onClick={(e) => {
              submit(e);
            }}
          />
        </Modal.Actions>
      </Modal>
      <div style={{ display: 'none' }}>
        <ReceiptToPrint ref={componentRef} transactions={lastTransactions} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isSending: state.receipt.isFetching,
    isSuccess: state.receipt.isSuccess,
    errors: state.receipt.errors,
    lastTransactions: state.transactions.lastTransactions,
  };
};

export default connect(mapStateToProps, { sendReceipt, clearReceipt })(
  AutoReceiptEmailModal
);
