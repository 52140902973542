import React, { useEffect, useState } from 'react';
import {
  Modal,
  Button,
  Input,
  Label,
  Form as SemanticForm,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { StyledButton } from './styledComponents';
import ErrorMessage from './ErrorMessage';
import { sendReceipt, clearReceipt } from '../actions';
import { useTranslation } from 'react-i18next';

const renderInput = ({ input, placeholder, meta }) => {
  const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
  return (
    <div className={className}>
      <Input autoComplete="off" placeholder={placeholder} {...input} />
      {renderError(meta)}
    </div>
  );
};

const renderError = ({ error, touched }) => {
  if (touched && error) {
    return (
      <Label basic color="red" pointing>
        {error}
      </Label>
    );
  }
};

const ReceiptEmailModal = ({
  email,
  groupId,
  sendReceipt,
  clearReceipt,
  isSending,
  isSuccess,
  errors,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isSuccess && !isSending) {
      form.reset();
      form.resetFieldState('email');
    }
  }, [isSuccess, isSending]);

  let submit, form;
  const onSubmit = async (formValues) => {
    await sendReceipt(formValues.email, groupId);
    form.restart();
  };

  return (
    <div>
      <Modal
        onClose={() => {
          setOpen(false);
          clearReceipt();
        }}
        onOpen={() => {
          setOpen(true);
        }}
        open={open}
        trigger={<StyledButton primary>{t('Email Receipt')}</StyledButton>}
      >
        <Modal.Header>Send receipt</Modal.Header>
        <Modal.Content>
          <Form
            onSubmit={onSubmit}
            initialValues={{ email }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = 'Enter an email to send';
              }
              return errors;
            }}
            render={({ handleSubmit, form: renderForm }) => {
              submit = handleSubmit;
              form = renderForm;
              return (
                <SemanticForm>
                  <Field name="email" placeholder="Email">
                    {(props) => {
                      return renderInput(props);
                    }}
                  </Field>
                </SemanticForm>
              );
            }}
          />
          {errors && <ErrorMessage errors={errors} />}
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={isSuccess ? 'Go back' : 'No Receipt'}
            onClick={() => {
              form.restart();
              setOpen(false);
              clearReceipt();
            }}
          />
          <StyledButton
            primary={!isSuccess}
            color={isSuccess ? 'green' : null}
            type="submit"
            loading={isSending}
            content={isSuccess ? 'Sent' : 'Send Receipt'}
            disabled={isSuccess}
            onClick={(e) => {
              submit(e);
            }}
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isSending: state.receipt.isFetching,
    isSuccess: state.receipt.isSuccess,
    errors: state.receipt.errors,
  };
};

export default connect(mapStateToProps, { sendReceipt, clearReceipt })(
  ReceiptEmailModal
);
