import React from 'react';
import Layout from './Layout';
import { Link } from 'react-router-dom';
import { Container, Header, Icon } from 'semantic-ui-react';
import { StyledButton } from './styledComponents';

const AboutUs = () => {
  return (
    <div>
      <Layout>
        <Container text>
          <Header as="h2">About Us</Header>
          <p>
            Gold Gold Bird is a software solution for foreign exchange shop.
            Initituve software allow you to run your shop's daily operations
            with ease. It is easy to set up your rates and start recording
            transactions, managing inventory, calculating shop's profit. Have
            multiple employees at the shop? No problem, the system supports
            multiple employee logins.
          </p>
          <p>
            Start your free trial today. There's no contract, no setup fees,
            just pay as you go.
          </p>
          <Link to="/signup">
            <StyledButton primary size="large">
              Get Started
              <Icon name="right arrow" />
            </StyledButton>
          </Link>
        </Container>
      </Layout>
    </div>
  );
};

export default AboutUs;
