export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';
export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';
export const EMPLOYEE_SIGN_UP = 'EMPLOYEE_SIGN_UP';
export const EMPLOYEE_SIGN_UP_SUCCESS = 'EMPLOYEE_SIGN_UP_SUCCESS';
export const EMPLOYEE_SIGN_UP_ERROR = 'EMPLOYEE_SIGN_UP_ERROR';
export const CURRENT_USER = 'CURRENT_USER';
export const FETCH_RATES = 'FETCH_RATES';
export const CREATE_RATES = 'CREATE_RATES';
export const EDIT_RATES = 'EDIT_RATES';
export const DELETE_RATES = 'DELETE_RATES';
export const INVERSE_RATES = 'INVERSE_RATES';
export const FETCH_INVENTORY = 'FETCH_INVENTORY';
export const FETCH_INVENTORY_ERROR = 'FETCH_INVENTORY_ERROR';
export const EDIT_INVENTORY = 'EDIT_INVENTORY';
export const EDIT_INVENTORY_SUCCESS = 'EDIT_INVENTORY_SUCCESS';
export const EDIT_INVENTORY_ERROR = 'EDIT_INVENTORY_ERROR';
export const EDIT_INVENTORIES = 'EDIT_INVENTORIES';
export const EDIT_INVENTORIES_SUCCESS = 'EDIT_INVENTORIES_SUCCESS';
export const EDIT_INVENTORIES_ERROR = 'EDIT_INVENTORIES_ERROR';
export const CREATE_TRANSACTION = 'CREATE_TRANSACTION';
export const CREATE_TRANSACTION_SUCCESS = 'CREATE_TRANSACTION_SUCCESS';
export const CREATE_TRANSACTION_ERROR = 'CREATE_TRANSACTION_ERROR';
export const FETCH_TRANSACTIONS = 'FETCH_TRANSACTIONS';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_MORE_TRANSACTIONS = 'FETCH_MORE_TRANSACTION';
export const FETCH_MORE_TRANSACTIONS_SUCCESS = 'FETCH_MORE_TRANSACTION_SUCCESS';
export const FETCH_MORE_TRANSACTIONS_ERROR = 'FETCH_MORE_TRANSACTION_ERROR';
export const FETCH_TRANSACTIONS_ERROR = 'FETCH_TRANSACTIONS_ERROR';
export const EDIT_TRANSACTION = 'EDIT_TRANSACTION';
export const EDIT_TRANSACTION_SUCCESS = 'EDIT_TRANSACTION_SUCCESS';
export const EDIT_TRANSACTION_ERROR = 'EDIT_TRANSACTION_ERROR';
export const DELETE_TRANSACTION = 'DELETE_TRANSACTION';
export const DELETE_TRANSACTION_SUCCESS = 'DELETE_TRANSACTION_SUCCESS';
export const DELETE_TRANSACTION_ERROR = 'DELETE_TRANSACTION_ERROR';
export const FETCH_REPORT = 'FETCH_REPORT';
export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS';
export const FETCH_REPORT_ERROR = 'FETCH_REPORT_ERROR';
export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_ERROR = 'FETCH_CUSTOMERS_ERROR';
export const FETCH_CUSTOMER_TRANSACTION = 'FETCH_CUSTOMER_TRANSACTION';
export const FETCH_CUSTOMER_TRANSACTION_SUCCESS =
  'FETCH_CUSTOMER_TRANSACTION_SUCCESS';
export const FETCH_CUSTOMER_TRANSACTION_ERROR =
  'FETCH_CUSTOMER_TRANSACTION_ERROR';
export const CLEAR_ALL_CUSTOMER_TRANSACTIONS =
  'CLEAR_ALL_CUSTOMER_TRANSACTIONS';
export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS';
export const ADD_EMPLOYEE_ERROR = 'ADD_EMPLOYEE_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const SEND_RECEIPT = 'SEND_RECEIPT';
export const SEND_RECEIPT_SUCCESS = 'SEND_RECEIPT_SUCCESS';
export const SEND_RECEIPT_ERROR = 'SEND_RECEIPT_ERROR';
export const CLEAR_RECEIPT_DATA = 'CLEAR_RECEIPT_DATA';
export const EXPORT_REPORT = 'EXPORT_REPORT';
export const EXPORT_REPORT_SUCCESS = 'EXPORT_REPORT_SUCCESS';
export const EXPORT_REPORT_ERROR = 'EXPORT_REPORT_ERROR';
export const CLEAR_EXPORT_REPORT_DATA = 'CLEAR_EXPORT_REPORT_DATA';
export const FETCH_CURRENCY_REPORT = 'FETCH_CURRENCY_REPORT';
export const FETCH_CURRENCY_REPORT_SUCCESS = 'FETCH_CURRENCY_REPORT_SUCCESS';
export const FETCH_CURRENCY_REPORT_ERROR = 'FETCH_CURRENCY_REPORT_ERROR';
export const CLEAR_CURRENCY_REPORT_DATA = 'CLEAR_CURRENCY_REPORT_DATA';
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'UPLOAD_IMAGE_ERROR';
export const CLEAR_UPLOAD_IMAGE_STATUS = 'CLEAR_UPLOAD_IMAGE_STATUS';
export const CLEAR_UPLOAD_IMAGE_DATA = 'CLEAR_UPLOAD_IMAGE_DATA';
export const DELETE_IMAGE = 'DELETE_IMAGE';
export const FETCH_CUSTOMER_IMAGE_URLS = 'FETCH_CUSTOMER_IMAGE_URLS';
export const FETCH_CUSTOMER_IMAGE_URLS_SUCCESS =
  'FETCH_CUSTOMER_IMAGE_URLS_SUCCESS';
export const FETCH_CUSTOMER_IMAGE_URLS_ERROR =
  'FETCH_CUSTOMER_IMAGE_URLS_ERROR';
export const FETCH_STRIPE_CHECKOUT_SESSION = 'FETCH_STRIPE_CHECKOUT_SESSION';
export const FETCH_STRIPE_CHECKOUT_SESSION_SUCCESS =
  'FETCH_STRIPE_CHECKOUT_SESSION_SUCCESS';
export const FETCH_STRIPE_CHECKOUT_SESSION_ERROR =
  'FETCH_STRIPE_CHECKOUT_SESSION_ERROR';
export const FETCH_STRIPE_PORTAL = 'FETCH_STRIPE_PORTAL';
export const FETCH_STRIPE_PORTAL_SUCCESS = 'FETCH_STRIPE_PORTAL_SUCCESS';
export const FETCH_STRIPE_PORTAL_ERROR = 'FETCH_STRIPE_PORTAL_ERROR';
export const FETCH_ADMIN_COMPANIES = 'FETCH_ADMIN_COMPANIES';
export const FETCH_ADMIN_COMPANIES_SUCCESS = 'FETCH_ADMIN_COMPANIES_SUCCESS';
export const FETCH_ADMIN_COMPANIES_ERROR = 'FETCH_ADMIN_COMPANIES_ERROR';
export const ADMIN_SIGN_IN = 'ADMIN_SIGN_IN';
export const ADMIN_SIGN_IN_SUCCESS = 'ADMIN_SIGN_IN_SUCCESS';
export const ADMIN_SIGN_IN_ERROR = 'ADMIN_SIGN_IN_ERROR';
export const LOAD_DATA = 'LOAD_DATA';
export const TRANSACTION_PAGE_SIZE = 50;
