import _, { mapKeys } from 'lodash';
import { combineReducers } from 'redux';
import { errorReducer } from './error';
import { inv } from 'mathjs';

import {
  SIGN_IN,
  SIGN_IN_ERROR,
  SIGN_OUT,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  SIGN_UP_ERROR,
  EMPLOYEE_SIGN_UP,
  EMPLOYEE_SIGN_UP_SUCCESS,
  EMPLOYEE_SIGN_UP_ERROR,
  CURRENT_USER,
  FETCH_RATES,
  CREATE_RATES,
  EDIT_RATES,
  DELETE_RATES,
  INVERSE_RATES,
  FETCH_INVENTORY,
  FETCH_INVENTORY_ERROR,
  EDIT_INVENTORY,
  EDIT_INVENTORY_SUCCESS,
  EDIT_INVENTORY_ERROR,
  EDIT_INVENTORIES,
  EDIT_INVENTORIES_SUCCESS,
  EDIT_INVENTORIES_ERROR,
  CREATE_TRANSACTION,
  CREATE_TRANSACTION_SUCCESS,
  CREATE_TRANSACTION_ERROR,
  FETCH_TRANSACTIONS,
  FETCH_TRANSACTIONS_SUCCESS,
  FETCH_TRANSACTIONS_ERROR,
  FETCH_MORE_TRANSACTIONS,
  FETCH_MORE_TRANSACTIONS_SUCCESS,
  FETCH_MORE_TRANSACTIONS_ERROR,
  EDIT_TRANSACTION,
  EDIT_TRANSACTION_SUCCESS,
  EDIT_TRANSACTION_ERROR,
  DELETE_TRANSACTION,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_ERROR,
  FETCH_REPORT,
  FETCH_REPORT_SUCCESS,
  FETCH_REPORT_ERROR,
  FETCH_CUSTOMERS,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMERS_ERROR,
  FETCH_CUSTOMER_TRANSACTION,
  FETCH_CUSTOMER_TRANSACTION_SUCCESS,
  FETCH_CUSTOMER_TRANSACTION_ERROR,
  CLEAR_ALL_CUSTOMER_TRANSACTIONS,
  ADD_EMPLOYEE,
  ADD_EMPLOYEE_SUCCESS,
  ADD_EMPLOYEE_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  SEND_RECEIPT,
  SEND_RECEIPT_SUCCESS,
  SEND_RECEIPT_ERROR,
  CLEAR_RECEIPT_DATA,
  EXPORT_REPORT,
  EXPORT_REPORT_SUCCESS,
  EXPORT_REPORT_ERROR,
  CLEAR_EXPORT_REPORT_DATA,
  FETCH_CURRENCY_REPORT,
  FETCH_CURRENCY_REPORT_SUCCESS,
  FETCH_CURRENCY_REPORT_ERROR,
  CLEAR_CURRENCY_REPORT_DATA,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_ERROR,
  CLEAR_UPLOAD_IMAGE_STATUS,
  CLEAR_UPLOAD_IMAGE_DATA,
  DELETE_IMAGE,
  LOAD_DATA,
  TRANSACTION_PAGE_SIZE,
  FETCH_CUSTOMER_IMAGE_URLS,
  FETCH_CUSTOMER_IMAGE_URLS_SUCCESS,
  FETCH_CUSTOMER_IMAGE_URLS_ERROR,
  FETCH_STRIPE_CHECKOUT_SESSION,
  FETCH_STRIPE_CHECKOUT_SESSION_SUCCESS,
  FETCH_STRIPE_CHECKOUT_SESSION_ERROR,
  FETCH_STRIPE_PORTAL,
  FETCH_STRIPE_PORTAL_SUCCESS,
  FETCH_STRIPE_PORTAL_ERROR,
  FETCH_ADMIN_COMPANIES,
  FETCH_ADMIN_COMPANIES_SUCCESS,
  FETCH_ADMIN_COMPANIES_ERROR,
  ADMIN_SIGN_IN,
  ADMIN_SIGN_IN_SUCCESS,
  ADMIN_SIGN_IN_ERROR,
} from '../actions/types';

const signinReducer = (state = {}, action) => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...errorReducer(state, action),
        isSignedIn: true,
        userId: action.payload.id,
        email: action.payload.email,
      };
    case SIGN_IN_ERROR:
      return {
        ...errorReducer(state, action),
      };
    case SIGN_UP:
    case EMPLOYEE_SIGN_UP:
      return {
        ...errorReducer(state, action),
        isFetching: true,
        isSuccess: false,
      };

    case SIGN_UP_SUCCESS:
    case EMPLOYEE_SIGN_UP_SUCCESS:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: true,
        isSignedIn: true,
        userId: action.payload.id,
        email: action.payload.email,
      };

    case SIGN_UP_ERROR:
    case EMPLOYEE_SIGN_UP_ERROR:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: false,
      };
    case SIGN_OUT:
      return {
        ...state,
        isSignedIn: false,
        userId: null,
        email: null,
      };
    default:
      return state;
  }
};

const currentUserReducer = (state = {}, action) => {
  switch (action.type) {
    case CURRENT_USER:
      return {
        ...state,
        ...action.payload.currentUser,
        selectedCompany: action.payload.currentUser?.companies[0], //TODO hardcoded to always select first one
      };
    case SIGN_UP:
      return { ...state, ...action.payload };
    case SIGN_OUT:
      return {};
    default:
      return state;
  }
};

const ratesReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_RATES:
      const rates = _.mapKeys(action.payload, 'id');
      return {
        ...state,
        ..._.mapValues(rates, (object) => {
          object.buy = parseFloat(object.buy).toPrecision(6);
          object.sell = parseFloat(object.sell).toPrecision(6);
          return object;
        }),
      };
    case CREATE_RATES:
      return { ...state, [action.payload.id]: action.payload };
    case EDIT_RATES:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_RATES:
      return _.omit(state, action.payload);
    case INVERSE_RATES:
      return {
        ...state,
        ..._.mapValues(action.payload, (object) => {
          object.buy = inv(parseFloat(object.buy)).toPrecision(6);
          object.sell = inv(parseFloat(object.sell)).toPrecision(6);
          return object;
        }),
      };
    case SIGN_OUT:
      return {};
    default:
      return state;
  }
};

const inventoryReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_INVENTORY:
      return {
        ...errorReducer(state, action),
        /*currencies: _.map(action.payload.currencies, (currency) => {
          currency.avgCost = Number(currency.avgCost?.toPrecision(6));
          return currency;
        }),*/
        results: mapKeys(action.payload, 'id'),
      };
    case FETCH_INVENTORY_ERROR:
      return {
        ...errorReducer(state, action),
      };
    case EDIT_INVENTORY:
      return {
        ...errorReducer(state, action),
        isFetching: true,
        isSuccess: false,
      };
    case EDIT_INVENTORY_SUCCESS:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: true,
        results: {
          [action.payload.id]: action.payload,
        },
      };
    case EDIT_INVENTORY_ERROR:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: false,
      };
    case EDIT_INVENTORIES:
      return {
        ...errorReducer(state, action),
        isFetching: true,
        isSuccess: false,
      };
    case EDIT_INVENTORIES_SUCCESS:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: true,
        results: mapKeys(action.payload, 'id'),
      };
    case EDIT_INVENTORIES_ERROR:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: false,
      };
    default:
      return state;
  }
};

const transactionReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_TRANSACTION:
      return {
        ...errorReducer(state, action),
        isFetching: true,
        isSuccess: false,
      };
    case CREATE_TRANSACTION_SUCCESS:
      var transactionGroupId = _.last(action.payload?.history)?.groupId;
      var lastCustomerEmail = _.last(action.payload?.history)?.customer?.email;
      var lastTransactions = _.filter(action.payload?.history, {
        groupId: transactionGroupId,
      });

      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: true,
        lastGroupId: transactionGroupId,
        lastTransactions: lastTransactions,
        lastCustomerEmail,
        [action.payload.id]: action.payload,
      };
    case CREATE_TRANSACTION_ERROR:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: false,
      };
    case FETCH_TRANSACTIONS:
      return {
        ...errorReducer(state, action),
        isFetching: true,
        isSuccess: false,
        hasMore: false,
      };
    case FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: true,
        results: action.payload,
        hasMore: action.payload.length === TRANSACTION_PAGE_SIZE,
      };
    case FETCH_TRANSACTIONS_ERROR:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: false,
      };
    case FETCH_MORE_TRANSACTIONS:
      return {
        ...errorReducer(state, action),
        isFetching: true,
        isSuccess: false,
        hasMore: false,
      };
    case FETCH_MORE_TRANSACTIONS_SUCCESS:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: true,
        results: [...state.results, ...action.payload],
        hasMore: action.payload.length === TRANSACTION_PAGE_SIZE,
      };
    case FETCH_MORE_TRANSACTIONS_ERROR:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: false,
      };
    case EDIT_TRANSACTION:
      return {
        ...errorReducer(state, action),
        isFetching: true,
        isSuccess: false,
      };
    case EDIT_TRANSACTION_SUCCESS:
      // Replace item in results with new edited one
      var index = _.findIndex(state.results, (o) => {
        return o.id === action.payload.id;
      });
      state.results.splice(index, 1, action.payload);
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: true,
        results: state.results,
      };
    case EDIT_TRANSACTION_ERROR:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: false,
      };
    case DELETE_TRANSACTION:
      return {
        ...errorReducer(state, action),
        isFetching: true,
        isSuccess: false,
      };
    case DELETE_TRANSACTION_SUCCESS:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: true,
        results: _.omitBy(state.results, (o) => {
          return o.id === action.payload.id;
        }),
      };
    case DELETE_TRANSACTION_ERROR:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: false,
      };
    default:
      return state;
  }
};

const reportReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_REPORT:
      return {
        ...errorReducer(state, action),
        isFetching: true,
        isSuccess: false,
      };

    case FETCH_REPORT_SUCCESS:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: true,
        result: action.payload,
      };

    case FETCH_REPORT_ERROR:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: false,
      };

    default:
      return state;
  }
};

const exportReportReducer = (state = {}, action) => {
  switch (action.type) {
    case EXPORT_REPORT:
      return {
        ...errorReducer(state, action),
        isFetching: true,
        isSuccess: false,
        file: null,
      };

    case EXPORT_REPORT_SUCCESS:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: true,
        file: action.payload,
      };

    case EXPORT_REPORT_ERROR:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: false,
      };
    case CLEAR_EXPORT_REPORT_DATA:
      return {};

    default:
      return state;
  }
};

const currencyReportReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CURRENCY_REPORT:
      return {
        ...errorReducer(state, action),
        isFetching: true,
        isSuccess: false,
      };
    case FETCH_CURRENCY_REPORT_SUCCESS:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: true,
        result: action.payload,
      };
    case FETCH_CURRENCY_REPORT_ERROR:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: false,
      };
    case CLEAR_CURRENCY_REPORT_DATA:
      return {};
    default:
      return state;
  }
};

const customerReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CUSTOMERS:
      return {
        ...errorReducer(state, action),
        isFetching: true,
        isSuccess: false,
      };
    case FETCH_CUSTOMERS_SUCCESS:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: true,
        results: _.mapKeys(action.payload, 'id'),
      };
    case FETCH_CUSTOMERS_ERROR:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: false,
      };
    default:
      return state;
  }
};

const customerTransactionReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_TRANSACTION:
      return {
        ...errorReducer(state, action),
        isFetching: true,
        isSuccess: false,
      };
    case FETCH_CUSTOMER_TRANSACTION_SUCCESS:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: true,
        results: action.payload,
      };
    case FETCH_CUSTOMER_TRANSACTION_ERROR:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: false,
      };
    case CLEAR_ALL_CUSTOMER_TRANSACTIONS:
      return {
        results: [],
      };
    default:
      return state;
  }
};

const addEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_EMPLOYEE:
      return {
        ...errorReducer(state, action),
        isFetching: true,
        isSuccess: false,
      };
    case ADD_EMPLOYEE_SUCCESS:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: true,
      };
    case ADD_EMPLOYEE_ERROR:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: false,
      };
    default:
      return state;
  }
};

const resetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case RESET_PASSWORD:
      return {
        ...errorReducer(state, action),
        isFetching: true,
        isSuccess: false,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: true,
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: false,
      };
    default:
      return state;
  }
};

const forgotPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD:
      return {
        ...errorReducer(state, action),
        isFetching: true,
        isSuccess: false,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: true,
      };

    case FORGOT_PASSWORD_ERROR:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: false,
      };
    default:
      return state;
  }
};

const receiptReducer = (state = {}, action) => {
  switch (action.type) {
    case SEND_RECEIPT:
      return {
        ...errorReducer(state, action),
        isFetching: true,
        isSuccess: false,
      };
    case SEND_RECEIPT_SUCCESS:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: true,
      };
    case SEND_RECEIPT_ERROR:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: false,
      };
    case CLEAR_RECEIPT_DATA:
      return {};
    default:
      return state;
  }
};

const imageUploadReducer = (state = { results: [] }, action) => {
  switch (action.type) {
    case UPLOAD_IMAGE:
      return {
        ...errorReducer(state, action),
        isFetching: true,
        isSuccess: false,
      };

    case UPLOAD_IMAGE_SUCCESS:
      return {
        ...errorReducer(state, action),
        results: [
          ...state.results,
          { name: action.payload.name, key: action.payload.Key },
        ],
        isFetching: false,
        isSuccess: true,
      };

    case UPLOAD_IMAGE_ERROR:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: false,
      };

    case CLEAR_UPLOAD_IMAGE_STATUS:
      return {
        ...errorReducer(state, action),
        results: [...state.results],
        isFetching: false,
        isSuccess: false,
      };

    case CLEAR_UPLOAD_IMAGE_DATA:
      return { results: [] };

    case DELETE_IMAGE:
      _.remove(state.results, { key: action.payload.key });
      return { results: [...state.results] };

    default:
      return state;
  }
};

const customerImageDownloadReducer = (state = { results: [] }, action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_IMAGE_URLS:
      return {
        ...errorReducer(state, action),
        isFetching: true,
        isSuccess: false,
      };
    case FETCH_CUSTOMER_IMAGE_URLS_SUCCESS:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: true,
        results: { ...state.results, ..._.mapKeys(action.payload, 'key') },
      };
    case FETCH_CUSTOMER_IMAGE_URLS_ERROR:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: false,
      };
    default:
      return state;
  }
};

const stripeReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_STRIPE_CHECKOUT_SESSION:
      return {
        ...errorReducer(state, action),
        isFetching: true,
        isSuccess: false,
      };
    case FETCH_STRIPE_CHECKOUT_SESSION_SUCCESS:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: true,
        sessionUrl: action.payload?.sessionUrl,
      };
    case FETCH_STRIPE_CHECKOUT_SESSION_ERROR:
      return {
        checkoutErrors: action.payload.errors,
        isFetching: false,
        isSuccess: false,
      };
    case FETCH_STRIPE_PORTAL:
      return {
        ...errorReducer(state, action),
        isFetching: true,
        isSuccess: false,
      };
    case FETCH_STRIPE_PORTAL_SUCCESS:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: true,
        portalUrl: action.payload?.portalUrl,
      };
    case FETCH_STRIPE_PORTAL_ERROR:
      return {
        portalErrors: action.payload.errors,
        isFetching: false,
        isSuccess: false,
      };
    default:
      return state;
  }
};

const adminReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ADMIN_COMPANIES:
      return {
        ...errorReducer(state, action),
        isFetching: true,
        isSuccess: false,
      };
    case FETCH_ADMIN_COMPANIES_SUCCESS:
      return {
        ...errorReducer(state, action),
        companies: action.payload,
        isFetching: false,
        isSuccess: true,
      };
    case FETCH_ADMIN_COMPANIES_ERROR:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: false,
      };
    case ADMIN_SIGN_IN:
      return {
        ...errorReducer(state, action),
        isFetching: true,
        isSuccess: false,
      };
    case ADMIN_SIGN_IN_SUCCESS:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: true,
        isSignedIn: true,
        userId: action.payload.id,
        email: action.payload.email,
      };
    case ADMIN_SIGN_IN_ERROR:
      return {
        ...errorReducer(state, action),
        isFetching: false,
        isSuccess: false,
      };
    default:
      return state;
  }
};

const dataReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_DATA:
      return action.data;
    case SIGN_OUT:
      return {};
    default:
      return state;
  }
};

export default combineReducers({
  signin: signinReducer,
  currentUser: currentUserReducer,
  rates: ratesReducer,
  inventory: inventoryReducer,
  transactions: transactionReducer,
  report: reportReducer,
  exportReport: exportReportReducer,
  currencyReport: currencyReportReducer,
  customers: customerReducer,
  customerTransactions: customerTransactionReducer,
  addEmployee: addEmployeeReducer,
  resetPassword: resetPasswordReducer,
  forgotPassword: forgotPasswordReducer,
  receipt: receiptReducer,
  imageUpload: imageUploadReducer,
  customerImage: customerImageDownloadReducer,
  stripe: stripeReducer,
  admin: adminReducer,
  data: dataReducer,
});
