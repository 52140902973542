import _ from 'lodash';
import React, { useState } from 'react';
import Layout from './Layout';
import { Card, Grid, Header, List, Segment, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { fetchStripeCheckoutSession } from '../actions';
import ErrorMessage from './ErrorMessage';
import { useTranslation } from 'react-i18next';

const SubscriptionPlan = ({
  currentUser,
  signin,
  fetchStripeCheckoutSession,
  checkoutUrl,
  errors,
}) => {
  const { t, i18n } = useTranslation();
  const [to, setTo] = useState();
  const handleSubscribeButton = async (e) => {
    e.preventDefault();
    if (currentUser) {
      //TODO: hardcoding priceId
      await fetchStripeCheckoutSession(process.env.REACT_APP_STRIPE_PRICE_ID);
    } else {
      setTo('/signup');
    }
  };

  if (to) {
    return (
      <Redirect
        push
        to={{ pathname: `${to}`, state: { from: '/subscription-plan' } }}
      />
    );
  } else if (checkoutUrl) {
    return (window.location.href = `${checkoutUrl}`);
  }
  return (
    <div>
      <Segment
        inverted
        textAlign="center"
        style={{
          minHeight: 400,
          padding: '1em 0em',
          backgroudColor: '#F9C326',
        }}
        vertical
      >
        <Layout headerColor="white">
          <Header
            as="h2"
            content={t('No setup fees.') + ', ' + t('Cancel anytime')}
            inverted
            style={{
              fontSize: '3.7em',
              fontWeight: 'normal',
              marginTop: '1.5em',
            }}
          />
        </Layout>
      </Segment>
      <Grid padded>
        <Grid.Row centered>
          <Card>
            <Card.Content>
              <Header as="h1">{t('Standard Plan')}</Header>
            </Card.Content>
            <Card.Content>
              <Grid textAlign="center">
                <Grid.Row verticalAlign="bottom">
                  <Grid.Column width={3}>
                    <Header as="h1">$29</Header>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Header as="h3">/month (USD)</Header>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid>
                <Grid.Row>
                  <Grid.Column textAlign="left">
                    <List bulleted>
                      <List.Item key="1">{t('Inventory tracking')}</List.Item>
                      <List.Item key="2">{t('Set currency rate')}</List.Item>
                      <List.Item key="3">{t('Reporting')}</List.Item>
                      <List.Item key="5">{t('Compliance')}</List.Item>
                      <List.Item key="6">
                        {t('Edit past transactions')}
                      </List.Item>
                    </List>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card.Content>
            <Card.Content>
              <Button color="green" fluid onClick={handleSubscribeButton}>
                {t('Subscribe')}
              </Button>
            </Card.Content>
          </Card>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <ErrorMessage errors={errors} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    signin: state.signin,
    checkoutUrl: state.stripe.sessionUrl,
    errors: state.stripe.checkoutErrors,
  };
};

export default connect(mapStateToProps, { fetchStripeCheckoutSession })(
  SubscriptionPlan
);
