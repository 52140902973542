import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Table, Segment, Header, Container, Divider } from 'semantic-ui-react';
import TransactionTotal from './TransactionTotal';

const renderHeader = (currentUser) => {
  return (
    <div>
      <Header as="h2" textAlign="center">
        {currentUser.selectedCompany?.name}
      </Header>
      <Container textAlign="center">
        {currentUser.selectedCompany?.address}
      </Container>
      <Container textAlign="center">
        {currentUser.selectedCompany?.phoneNumber}
      </Container>
    </div>
  );
};

const renderRow = (
  { type, amountCent, baseAmountCent, rate, currencyCode, id },
  baseCurrencyCode
) => {
  const action = type === 'buy' ? 'Received from customer' : 'Sold to customer';
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
  });
  var baseFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: baseCurrencyCode,
  });
  var formattedTotal = baseFormatter.format(baseAmountCent / 100);
  var formattedAmount = formatter.format(amountCent / 100);
  return (
    <div key={id}>
      <b>{`${action} ${currencyCode}`}</b>
      <br />
      {`${formattedAmount} * (${rate}) = ${formattedTotal}`}
      <br />
    </div>
  );
};

const renderTable = (transactions, baseCurrencyCode) => {
  return (
    <div>
      <Divider horizontal>Transactions</Divider>
      {_.map(transactions, (o) => {
        return renderRow(o, baseCurrencyCode);
      })}
    </div>
  );
};

const renderEntry = (title, content) => {
  return (
    <div>
      <b>{`${title}: `}</b>
      {`${content}`}
    </div>
  );
};

const renderCustomer = ({ fullName, email, address }) => {
  return (
    <div>
      <Divider horizontal>Customer</Divider>
      {fullName && renderEntry('Name', fullName)}
      {email && renderEntry('Email', email)}
      {address && renderEntry('Address', address)}
      <br />
    </div>
  );
};

const ReceiptContent = ({ currentUser, transactions }) => {
  const customer = _.first(transactions)?.customer;
  return (
    <div>
      <Segment>
        {renderHeader(currentUser)}
        {customer && renderCustomer(customer)}
        {renderTable(
          transactions,
          currentUser.selectedCompany?.baseCurrencyCode
        )}

        <Divider horizontal>Total</Divider>
        <TransactionTotal transactions={transactions} />
      </Segment>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};
export default connect(mapStateToProps, {})(ReceiptContent);
