import _ from 'lodash';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, isSignedIn, ...rest }) => {
  const [cookies] = useCookies(['express:sess']);
  const isLoggedIn = !_.isNil(cookies['express:sess']);
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

const mappStateToProps = (state) => {
  return {
    isSignedIn: state.signin.isSignedIn,
  };
};

export default connect(mappStateToProps)(PrivateRoute);
