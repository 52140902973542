/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import { createMedia } from '@artsy/fresnel';
import PropTypes from 'prop-types';
import React, { useState, Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
  Visibility,
  Dropdown,
  Card,
  List,
} from 'semantic-ui-react';
import currencyImage from '../images/undraw_digital_currency_qpak.svg';
import rateImage from '../images/rates.png';
import inventoryImage from '../images/inventory.png';
import transactionImage from '../images/transaction.png';
import historyImage from '../images/history.png';
import reportImage from '../images/report.png';
import customerImage from '../images/customer.png';
import { StyledButton } from './styledComponents';
import iconImage from '../images/bird_icon.png';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import overview from '../images/overview.gif';

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

const languageOptions = [
  {
    key: 'english',
    text: 'English',
    value: 'en',
  },
  {
    key: 'zh-TW',
    text: '繁體中文',
    value: 'zh-TW',
  },
  {
    key: 'th',
    text: 'Thai',
    value: 'th',
  },
];

/* Heads up!
 * HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled
 * components for such things.
 */
const HomepageHeading = ({ mobile }) => {
  const { t, i18n } = useTranslation();
  return (
    <Container text>
      <Header
        as="h1"
        content={t('Gold Gold Bird')}
        inverted
        style={{
          fontSize: mobile ? '2em' : '4em',
          fontWeight: 'normal',
          marginBottom: 0,
          marginTop: mobile ? '1.5em' : '3em',
        }}
      />
      <Header
        as="h2"
        content={t(
          'Software solution for managing foreign exchange shop. (Work in progress, not for public use)'
        )}
        inverted
        style={{
          fontSize: mobile ? '1.5em' : '1.7em',
          fontWeight: 'normal',
          marginTop: mobile ? '0.5em' : '1.5em',
        }}
      />
      <Link to="/signup">
        <StyledButton primary size="huge">
          {t('Get Started')}
          <Icon name="right arrow" />
        </StyledButton>
      </Link>
    </Container>
  );
};

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
};

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
const DesktopContainer = ({ children }) => {
  const cookies = new Cookies();
  const { t, i18n } = useTranslation();
  const [fixed, setFixed] = useState(false);
  const [activeItem, setActiveItem] = useState('home');

  const didChangeLanguage = (e, data) => {
    i18n.changeLanguage(data.value);
    cookies.set('i18next', data.value, { path: '/' });
  };

  const showFixedMenu = () => {
    setFixed(true);
  };

  const hideFixedMenu = () => {
    setFixed(false);
  };

  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
  };
  var verticalPadding = fixed ? '2px' : '0px';

  return (
    <div>
      <CookieConsent>
        This website uses cookies to enhance the user experience.
      </CookieConsent>

      <Media greaterThan="mobile">
        <Visibility
          once={false}
          onBottomPassed={showFixedMenu}
          onBottomPassedReverse={hideFixedMenu}
        >
          <Segment
            inverted
            textAlign="center"
            style={{
              minHeight: 700,
              padding: '1em 0em',
              backgroudColor: '#F9C326',
            }}
            vertical
          >
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size="large"
            >
              <Container>
                <Image
                  inline
                  size="tiny"
                  src={iconImage}
                  style={{
                    alignSelf: 'flex-start',
                    borderRadius: 0,
                    paddingBottom: verticalPadding,
                    paddingTop: verticalPadding,
                  }}
                  avatar
                />
                <Menu.Item
                  as={Link}
                  to="/"
                  name={t('Home')}
                  active={activeItem === 'home'}
                  onClick={handleItemClick}
                />
                <Menu.Item
                  as={Link}
                  to="/subscription-plan"
                  name={t('Pricing')}
                  active={activeItem === 'pricing'}
                  onClick={handleItemClick}
                />
                <Menu.Item as="a">{t('FAQ')}</Menu.Item>
                <Menu.Item position="right">
                  <Dropdown
                    placeholder="Select Language"
                    selection
                    defaultValue={cookies.get('i18next') ?? 'en'}
                    onChange={didChangeLanguage}
                    options={languageOptions}
                  />
                </Menu.Item>
                <Menu.Item position="right">
                  <Link to="/signin">
                    <Button inverted={!fixed}>{t('Login')}</Button>
                  </Link>
                  <Link to="/signup">
                    <Button
                      inverted={!fixed}
                      primary={fixed}
                      style={{ marginLeft: '0.5em' }}
                    >
                      {t('Sign Up')}
                    </Button>
                  </Link>
                </Menu.Item>
              </Container>
            </Menu>
            <HomepageHeading />
          </Segment>
        </Visibility>
        {children}
      </Media>
    </div>
  );
};

DesktopContainer.propTypes = {
  children: PropTypes.node,
};

const MobileContainer = ({ children }) => {
  const cookies = new Cookies();
  const { t, i18n } = useTranslation();
  const [sidebarOpened, setSidebarOpened] = useState(false);

  const didChangeLanguage = (e, data) => {
    i18n.changeLanguage(data.value);
    cookies.set('i18next', data.value, { path: '/' });
  };

  const handleSidebarHide = () => {
    setSidebarOpened(false);
  };

  const handleToggle = () => {
    setSidebarOpened(true);
  };

  return (
    <Media as={Sidebar.Pushable} at="mobile">
      <Sidebar.Pushable>
        <Sidebar
          as={Menu}
          animation="overlay"
          inverted
          onHide={handleSidebarHide}
          vertical
          visible={sidebarOpened}
        >
          <Menu.Item as="a" active>
            {t('Home')}
          </Menu.Item>
          <Menu.Item as="a">{t('Pricing')}</Menu.Item>
          <Menu.Item as="a">{t('FAQ')}</Menu.Item>

          <Link to="/signin">
            <Menu.Item>{t('Login')}</Menu.Item>
          </Link>

          <Link to="/signup">
            <Menu.Item>{t('Sign Up')}</Menu.Item>
          </Link>
          <Menu.Item position="right">
            <Dropdown
              placeholder="Select Language"
              selection
              defaultValue={cookies.get('i18next') ?? 'en'}
              onChange={didChangeLanguage}
              options={languageOptions}
            />
          </Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            inverted
            textAlign="center"
            style={{ minHeight: 350, padding: '1em 0em' }}
            vertical
          >
            <Container>
              <Menu inverted pointing secondary size="large">
                <Menu.Item onClick={handleToggle}>
                  <Icon name="sidebar" />
                </Menu.Item>
                <Menu.Item position="right">
                  <Dropdown
                    placeholder="Select Language"
                    selection
                    defaultValue={cookies.get('i18next') ?? 'en'}
                    onChange={didChangeLanguage}
                    options={languageOptions}
                  />
                </Menu.Item>
                <Menu.Item position="right">
                  <Link to="/signin">
                    <Button inverted>{t('Login')}</Button>
                  </Link>
                  <Link to="/signup">
                    <Button inverted style={{ marginLeft: '0.5em' }}>
                      {t('Sign Up')}
                    </Button>
                  </Link>
                </Menu.Item>
              </Menu>
            </Container>
            <HomepageHeading mobile />
          </Segment>

          {children}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </Media>
  );
};

MobileContainer.propTypes = {
  children: PropTypes.node,
};

const ResponsiveContainer = ({ children }) => (
  /* Heads up!
   * For large applications it may not be best option to put all page into these containers at
   * they will be rendered twice for SSR.
   */
  <MediaContextProvider>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </MediaContextProvider>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

const HomepageLayout = () => {
  const { t, i18n } = useTranslation();
  return (
    <ResponsiveContainer>
      <Segment style={{ padding: '4em 0em' }} vertical>
        <Grid container stackable verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={6}>
              <Header as="h3" style={{ fontSize: '2em' }}>
                {t('Why choose Gold Gold Bird?')}
              </Header>
              <p style={{ fontSize: '1.33em' }}>
                {t(
                  'Learn how to use in 10 minutes, 24/7 keep your FX shop running.'
                )}
              </p>
              <Header as="h3" style={{ fontSize: '2em' }}>
                {t('No commitment needed')}
              </Header>
              <p style={{ fontSize: '1.33em' }}>
                {t('No installation fees, only $29/month')}
              </p>
            </Grid.Column>
            <Grid.Column floated="right" width={10}>
              <Image bordered rounded size="huge" src={overview} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <Link to="signup">
                <StyledButton primary size="huge">
                  {t('Get Started')}
                </StyledButton>
              </Link>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <Segment style={{ padding: '4em 0em' }} vertical>
        <Grid container stackable verticalAlign="middle">
          <Grid.Row>
            <Grid.Column floated="right" width={10}>
              <Image bordered rounded size="huge" src={inventoryImage} />
            </Grid.Column>
            <Grid.Column textAlign="center" width={6}>
              <Header as="h3" style={{ fontSize: '2em' }}>
                {t('Simple to use UI')}
              </Header>
              <p style={{ fontSize: '1.33em' }}>
                {t('Pay as you go. Cancel anytime')}
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <Link
                to="#"
                onClick={(e) => {
                  window.location = 'mailto:contact@goldgoldbird.com';
                  e.preventDefault();
                }}
              >
                <StyledButton mail primary size="huge">
                  {t('Contact Us')}
                </StyledButton>
              </Link>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <Segment style={{ padding: '6em 6em' }} vertical>
        <Card.Group stackable centered itemsPerRow={3}>
          <Card>
            <Card.Content>
              <Card.Header style={{ fontSize: '2em' }}>
                {t('Pay as you go')}
              </Card.Header>
            </Card.Content>
            <Card.Content fluid>
              <List style={{ fontSize: '1.4em', padding: '1em 0em' }} as="ul">
                <List.Item as="li">{t('$29 a month')}</List.Item>
              </List>
            </Card.Content>
            <Card.Content extra>
              <Link to="/signup">
                <StyledButton style={{ fontSize: '1.4em' }} fluid primary>
                  {t('Get Started')}
                </StyledButton>
              </Link>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <Card.Header style={{ fontSize: '2em' }}>
                {t('Customer support')}
              </Card.Header>
            </Card.Content>
            <Card.Content>
              <List style={{ fontSize: '1.4em', padding: '1em 0em' }} as="ul">
                <List.Item as="li">
                  {t('Quick response within 24 hrs')}
                </List.Item>
              </List>
            </Card.Content>
            <Card.Content extra>
              <Link
                to="#"
                onClick={(e) => {
                  window.location = 'mailto:contact@goldgoldbird.com';
                  e.preventDefault();
                }}
              >
                <StyledButton style={{ fontSize: '1.4em' }} fluid primary>
                  {t('Contact Us')}
                </StyledButton>
              </Link>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <Card.Header style={{ fontSize: '2em' }}>
                {t('Simple to use UI')}
              </Card.Header>
            </Card.Content>
            <Card.Content>
              <List style={{ fontSize: '1.4em', padding: '1em 0em' }} as="ul">
                <List.Item as="li">
                  {t('Support multiple currency transaction')}
                </List.Item>
                <List.Item as="li">
                  {t('Automatic create custom compliance form to save time')}
                </List.Item>
                <List.Item as="li">
                  {t('Include profit and revenue report')}
                </List.Item>
              </List>
            </Card.Content>
            <Card.Content extra>
              <Link
                to="#"
                onClick={(e) => {
                  window.location = 'mailto:contact@goldgoldbird.com';
                  e.preventDefault();
                }}
              >
                <StyledButton style={{ fontSize: '1.4em' }} fluid primary>
                  {t('Contact Us')}
                </StyledButton>
              </Link>
            </Card.Content>
          </Card>
        </Card.Group>
      </Segment>

      {/*<Segment style={{ padding: '0em' }} vertical>
      <Grid celled="internally" columns="equal" stackable>
        <Grid.Row textAlign="center">
          <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
            <Header as="h3" style={{ fontSize: '2em' }}>
              "The software is modern, and has all the features I need to run my
              shop. I am glad I no longer need to keep track of things on
              paper."
            </Header>
            <p style={{ fontSize: '1.33em' }}>- Gordon's exchange shop</p>
          </Grid.Column>
          <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
            <Header as="h3" style={{ fontSize: '2em' }}>
              "Great customer service!"
            </Header>
            <p style={{ fontSize: '1.33em' }}>- Peter's coin shop</p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>*/}

      <Segment style={{ padding: '4em 4em' }} vertical>
        <Grid>
          <Grid.Row>
            <Grid.Column verticalAlign="middle" textAlign="center" width={6}>
              <Header as="h3" style={{ fontSize: '2em' }}>
                {t('Set your daily rate')}
              </Header>
              <p style={{ fontSize: '1.33em' }}>
                {t(
                  'Your shop, your rate. Process transaction with rates you set, change anytime'
                )}
              </p>
            </Grid.Column>
            <Grid.Column floated="right" width={10}>
              <Image rounded bordered src={rateImage} fluid />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column floated="left" width={10}>
              <Image rounded bordered src={inventoryImage} fluid />
            </Grid.Column>
            <Grid.Column verticalAlign="middle" textAlign="center" width={6}>
              <Header as="h3" style={{ fontSize: '2em' }}>
                {t('See your inventory')}
              </Header>
              <p style={{ fontSize: '1.33em' }}>
                {t('Always have your inventory available at a glance')}
              </p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column verticalAlign="middle" textAlign="center" width={6}>
              <Header as="h3" style={{ fontSize: '2em' }}>
                {t('Process transactions')}
              </Header>
              <p style={{ fontSize: '1.33em' }}>
                {t(
                  'Simple UI to process complex transaction. Support multiple currencies, capture customer info'
                )}
              </p>
            </Grid.Column>
            <Grid.Column floated="right" width={10}>
              <Image rounded bordered src={transactionImage} fluid />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column floated="left" width={10}>
              <Image rounded bordered src={historyImage} fluid />
            </Grid.Column>
            <Grid.Column verticalAlign="middle" textAlign="center" width={6}>
              <Header as="h3" style={{ fontSize: '2em' }}>
                {t('Check history')}
              </Header>
              <p style={{ fontSize: '1.33em' }}>
                {t(
                  'See all transactions from your shop. Can be modified anytime'
                )}
              </p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column verticalAlign="middle" textAlign="center" width={6}>
              <Header as="h3" style={{ fontSize: '2em' }}>
                {t('Reporting')}
              </Header>
              <p style={{ fontSize: '1.33em' }}>
                {t('Instantly view estimated profit')}
              </p>
            </Grid.Column>
            <Grid.Column floated="right" width={10}>
              <Image rounded bordered src={reportImage} fluid />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column floated="left" width={10}>
              <Image rounded bordered src={customerImage} fluid />
            </Grid.Column>
            <Grid.Column verticalAlign="middle" textAlign="center" width={6}>
              <Header as="h3" style={{ fontSize: '2em' }}>
                {t('Customer')}
              </Header>
              <p style={{ fontSize: '1.33em' }}>
                {t(
                  'Look up all your previous customers and their transactions'
                )}
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Link to="/signup">
              <StyledButton primary size="huge">
                {t('Get Started')}
                <Icon name="right arrow" />
              </StyledButton>
            </Link>
          </Grid.Row>
        </Grid>

        <Divider
          as="h4"
          className="header"
          horizontal
          style={{ margin: '3em 0em', textTransform: 'uppercase' }}
        >
          {t('Need help?')}
        </Divider>

        <Container centered textAlign="center">
          <Header as="h3" style={{ fontSize: '2em' }}>
            {t('Contact Us')}
          </Header>
          <p style={{ fontSize: '1.33em' }}>
            {t('Still have questions? Contact us for a free demo.')}
          </p>
          <Link
            to="#"
            onClick={(e) => {
              window.location = 'mailto:contact@goldgoldbird.com';
              e.preventDefault();
            }}
          >
            <StyledButton mail primary size="huge">
              {t('Contact Us')}
            </StyledButton>
          </Link>
        </Container>
      </Segment>
    </ResponsiveContainer>
  );
};

export default HomepageLayout;
