import React from 'react';
import { Table, Loader, Dimmer, Segment } from 'semantic-ui-react';
import ErrorMessage from './ErrorMessage';
import CurrencyFormat from './CurrencyFormat';

const CurrencyReport = ({
  report,
  baseCurrencyCode,
  isFetching,
  isSuccess,
  errors,
}) => {
  const renderReport = () => {
    return (
      <div>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Buy</Table.HeaderCell>
              <Table.HeaderCell>{`Buy (${baseCurrencyCode})`}</Table.HeaderCell>
              <Table.HeaderCell>Buy Transactions</Table.HeaderCell>
              <Table.HeaderCell>Sell</Table.HeaderCell>
              <Table.HeaderCell>{`Sell (${baseCurrencyCode})`}</Table.HeaderCell>
              <Table.HeaderCell>Sell Transactions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <CurrencyFormat cent={report.totalBuyCent} />
              </Table.Cell>
              <Table.Cell>
                <CurrencyFormat cent={report.totalBuyBaseCent} />
              </Table.Cell>
              <Table.Cell>{report.numBuy}</Table.Cell>
              <Table.Cell>
                <CurrencyFormat cent={report.totalSellCent} />
              </Table.Cell>
              <Table.Cell>
                <CurrencyFormat cent={report.totalSellBaseCent} />
              </Table.Cell>
              <Table.Cell>{report.numSell}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    );
  };
  if (isFetching) {
    return (
      <div>
        <Loader active size="medium" inline="centered">
          Loading
        </Loader>
      </div>
    );
  } else if (report) {
    return <div>{renderReport()}</div>;
  } else if (errors) {
    return (
      <div>
        <ErrorMessage errors={errors} />
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default CurrencyReport;
