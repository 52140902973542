import _ from 'lodash';
import React, { useEffect } from 'react';
import {
  Divider,
  Icon,
  Header,
  Table,
  Grid,
  Input,
  Label,
  Button,
  Form as SemanticForm,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import ErrorMessage from './ErrorMessage';
import { fetchInventory, editInventories } from '../actions';
import { Form, Field } from 'react-final-form';
import CurrencyFlag from 'react-currency-flags';
import { useTranslation } from 'react-i18next';

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

const validateNumber = (value) => {
  return isNumeric(value) && value > 0 ? undefined : 'Enter a number';
};

const InventorySetting = ({
  currentUser,
  inventory,
  fetchInventory,
  editInventories,
  errors,
  isFetching,
  isSuccess,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    const fetch = async () => {
      if (currentUser.companies) {
        await fetchInventory();
      }
    };
    fetch();
  }, [currentUser]);

  const onSubmit = async (formValues) => {
    const newInventory = _.mapKeys(inventory, 'currencyCode');
    var value = _.map(formValues, (value, key) => {
      return { ...newInventory[key], warningThreshold: value };
    });
    await editInventories(value);
  };

  const renderError = ({ error, touched }) => {
    if (touched && error) {
      return (
        <div>
          <Label basic color="red" pointing>
            {error}
          </Label>
        </div>
      );
    }
  };

  const renderInput = ({ input, placeholder, meta }) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
    return (
      <div className={className}>
        <Input autoComplete="off" placeholder={placeholder} {...input} />
        {renderError(meta)}
      </div>
    );
  };

  const renderRow = () => {
    return _.map(inventory, (currency) => {
      return (
        <Table.Row key={currency.id}>
          <Table.Cell>
            <Grid padded="horizontally">
              <Grid.Column width={3}>
                <CurrencyFlag currency={currency.currencyCode} size="md" />
              </Grid.Column>
              <Grid.Column>
                <b>{currency.currencyCode}</b>
              </Grid.Column>
            </Grid>
          </Table.Cell>
          <Table.Cell>
            <Field
              name={`${currency.currencyCode}`}
              placeholder="Threshold"
              validate={validateNumber}
            >
              {(props) => {
                return renderInput(props);
              }}
            </Field>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const renderTable = () => {
    return (
      <div>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('Currency')}</Table.HeaderCell>
              <Table.HeaderCell>{t('Threshold')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>{renderRow()}</Table.Body>
        </Table>
      </div>
    );
  };

  const initialValue = () => {
    const initialCurrency = _.mapValues(
      _.mapKeys(inventory, 'currencyCode'),
      'warningThreshold'
    );
    return initialCurrency;
  };

  const renderForm = () => {
    return (
      <div>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValue()}
          render={({ handleSubmit, submitting, pristine }) => {
            return (
              <div>
                <SemanticForm onSubmit={handleSubmit}>
                  {renderTable()}
                  <br />
                  <Button
                    loading={isFetching}
                    type="submit"
                    color={isSuccess && pristine ? 'green' : 'grey'}
                    disabled={!isSuccess && (submitting || pristine)}
                  >
                    {isSuccess && pristine ? t('Success') : t('Save')}
                  </Button>
                </SemanticForm>
              </div>
            );
          }}
        />
      </div>
    );
  };

  const renderEmpty = () => {
    return (
      <div>
        <h3>{t('Start by adding some transaction')}</h3>
      </div>
    );
  };

  return (
    <div>
      <Divider horizontal>
        <Header as="h4">
          <Icon name="calculator" />
          {t('Low Supply Threshold')}
        </Header>
      </Divider>
      <ErrorMessage errors={errors} />
      {inventory.length > 0 ? renderForm() : renderEmpty()}
    </div>
  );
};

const mapStateToProps = (state) => {
  var inventory = [];
  if (state.inventory.results) {
    inventory = Object.values(state.inventory.results);
  }
  return {
    inventory: inventory,
    currentUser: state.currentUser,
    errors: state.inventory.errors,
    isFetching: state.inventory.isFetching,
    isSuccess: state.inventory.isSuccess,
  };
};

export default connect(mapStateToProps, { fetchInventory, editInventories })(
  InventorySetting
);
