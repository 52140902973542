import React from 'react';
import { Field } from 'react-final-form';
import { Input, Label, Grid, Dropdown } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { format, isValid, toDate } from 'date-fns';
import ImageUploadModal from './ImageUploadModal';
import { useTranslation } from 'react-i18next';

const CustomerInput = ({ disableInput, handlePersonalIdType }) => {
  const { t } = useTranslation();
  const renderError = ({ error, touched }) => {
    if (touched && error) {
      return (
        <div>
          <Label basic color="red" pointing>
            {error}
          </Label>
        </div>
      );
    }
  };

  const renderInput = ({ input, placeholder, meta }) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
    return (
      <div className={className}>
        <Input
          disabled={disableInput}
          autoComplete="off"
          placeholder={placeholder}
          {...input}
        />
        {renderError(meta)}
      </div>
    );
  };

  const renderPersonalIdInput = ({ input, placeholder, meta }) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
    return (
      <div className={className}>
        <Input
          label={
            <Dropdown
              defaultValue="other"
              options={personalIdOptions}
              onChange={(e, data) => handlePersonalIdType(data.value)}
            />
          }
          labelPosition="right"
          disabled={disableInput}
          autoComplete="off"
          placeholder={placeholder}
          {...input}
        />
        {renderError(meta)}
      </div>
    );
  };

  const renderDatePicker = ({
    name,
    input: { value, onChange },
    placeholder,
  }) => {
    return (
      <div>
        <DatePicker
          placeholderText={placeholder}
          name={name}
          value={value}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          selected={value && isValid(value) ? toDate(value) : null} // needs to be checked if it is valid date
          onChange={(date) => {
            // On Change, you should use final-form Field Input prop to change the value
            if (isValid(date)) {
              onChange(format(new Date(date), 'MM-dd-yyyy'));
            } else {
              onChange(null);
            }
          }}
        />
      </div>
    );
  };

  const personalIdOptions = [
    { key: 'passport', text: t('Passport'), value: 'passport' },
    {
      key: 'driver_license',
      text: t('Driver License'),
      value: 'driver_license',
    },
    { key: 'other', text: t('Other'), value: 'other' },
  ];

  return (
    <div>
      <Grid columns="equal" padded>
        <Grid.Column mobile={16} computer={8}>
          <Field name="firstName" type="text" placeholder={t('First Name')}>
            {(props) => {
              return renderInput(props);
            }}
          </Field>
        </Grid.Column>
        <Grid.Column mobile={16} computer={8}>
          <Field name="lastName" type="text" placeholder={t('Last Name')}>
            {(props) => {
              return renderInput(props);
            }}
          </Field>
        </Grid.Column>

        <Grid.Row>
          <Grid.Column>
            <Field name="email" type="text" placeholder={t('Email')}>
              {(props) => {
                return renderInput(props);
              }}
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field name="address" type="text" placeholder={t('Address')}>
              {(props) => {
                return renderInput(props);
              }}
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={12}>
            <Field name="personalId" type="text" placeholder={t('Personal Id')}>
              {(props) => {
                return renderPersonalIdInput(props);
              }}
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={12}>
            <Field name="birthday" placeholder={t('Birthday')}>
              {(props) => {
                return renderDatePicker(props);
              }}
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <ImageUploadModal trigger={t('Upload')} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default CustomerInput;
