import React from 'react';
import Header from './Header';
import Menu from './Menu';
import SubscriptionStatusHeader from './SubscriptionStatusHeader';
import { Container } from 'semantic-ui-react';
import CookieConsent from 'react-cookie-consent';

const Layout = ({ children }) => {
  return (
    <div>
      <Container>
        <CookieConsent>
          This website uses cookies to enhance the user experience.
        </CookieConsent>
        <Header />
        <Menu />
        <SubscriptionStatusHeader />
        {children}
      </Container>
    </div>
  );
};

export default Layout;
