import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Table, Button } from 'semantic-ui-react';
import { fetchAdminCompanies, adminSignIn } from '../actions';
import AuthenticatedLayout from './AuthenticatedLayout';
import ErrorMessage from './ErrorMessage';

const Admin = ({
  currentUser,
  companies,
  errors,
  fetchAdminCompanies,
  adminSignIn,
}) => {
  useEffect(() => {
    if (currentUser) {
      fetchAdminCompanies();
    }
  }, [currentUser]);

  const handleLoginClick = async (e, data) => {
    e.preventDefault();
    await adminSignIn(data.companyId);
    window.location.reload();
  };

  const renderTableRows = () => {
    return _.map(companies, ({ id, name, ownerId }) => {
      return (
        <Table.Row key={id}>
          <Table.Cell>{id}</Table.Cell>
          <Table.Cell>{name}</Table.Cell>
          <Table.Cell>{ownerId?.email}</Table.Cell>
          <Table.Cell>
            <Button
              onClick={(e, data) => {
                handleLoginClick(e, data);
              }}
              companyId={id}
            >
              Login
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const renderTable = () => {
    return (
      <div>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Id</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Owner Email</Table.HeaderCell>
              <Table.HeaderCell>Login</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{renderTableRows()}</Table.Body>
        </Table>
      </div>
    );
  };
  return (
    <div>
      <h2>Admin</h2>
      {renderTable()}
      <ErrorMessage errors={errors} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    companies: state.admin.companies,
    errors: state.admin.errors,
  };
};

export default connect(mapStateToProps, { fetchAdminCompanies, adminSignIn })(
  Admin
);
