import React from 'react';
import Layout from './Layout';
import { Container, Header, Icon } from 'semantic-ui-react';

const PrivacyPolicy = () => {
  return (
    <div>
      <Layout>
        <Container text>
          <Header as="h2">Privacy Policy</Header>
        </Container>
      </Layout>
    </div>
  );
};

export default PrivacyPolicy;
