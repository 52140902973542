import _ from 'lodash';
import cc from 'currency-codes';
import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Input,
  Button,
  Grid,
  Form as SemanticForm,
  Label,
  Dropdown,
} from 'semantic-ui-react';
import { load } from '../actions';

const currencyCodes = () => {
  return _.map(cc.codes(), (code) => {
    return {
      key: code,
      text: code,
      value: code,
    };
  });
};

const renderInput = ({ input, placeholder, meta }) => {
  const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
  return (
    <div className={className}>
      <label>{placeholder}</label>
      <Input autoComplete="off" placeholder={placeholder} {...input} />
      {renderError(meta)}
    </div>
  );
};

const renderDropdown = (props) => {
  const className = `field ${
    props.meta.error && props.meta.touched ? 'error' : ''
  }`;
  return (
    <div className={className}>
      <label>{props.placeholder}</label>
      <Dropdown
        {...props}
        input={props.input}
        value={props.input.value}
        search
        selection
        options={currencyCodes()}
        onChange={(e, data) => {
          props.input.onChange(data.value);
        }}
      />
      {renderError(props.meta)}
    </div>
  );
};

const renderError = ({ error, touched }) => {
  if (touched && error) {
    return (
      <Label basic color="red" pointing>
        {error}
      </Label>
    );
  }
};

const RateModal = ({ rate = null, header, load, children, handleOnSubmit }) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const onSubmit = (formValues) => {
    handleOnSubmit(formValues);
    setOpen(false);
  };
  let submit;
  let form;
  return (
    <div>
      <Modal
        onClose={() => {
          form.reset();
          setOpen(false);
        }}
        onOpen={() => {
          load(rate);
          setOpen(true);
        }}
        open={open}
        trigger={children}
      >
        <Modal.Header>{header}</Modal.Header>
        <Modal.Content>
          <Form
            onSubmit={onSubmit}
            initialValues={rate}
            validate={(values) => {
              const errors = {};
              if (!values.currencyCode) {
                errors.currencyCode = t('Enter a currency code');
              }
              if (!values.buy) {
                errors.buy = t('Enter a buy rate');
              }
              if (!values.sell) {
                errors.sell = t('Enter a sell rate');
              }
              return errors;
            }}
            render={({ handleSubmit, form: renderForm }) => {
              submit = handleSubmit;
              form = renderForm;
              return (
                <SemanticForm onSubmit={handleSubmit}>
                  <Grid columns={3} verticalAlign="middle">
                    <Grid.Column mobile={16} computer={5}>
                      <Field
                        name="currencyCode"
                        placeholder={t('Currency Code')}
                      >
                        {(props) => {
                          return renderDropdown(props);
                        }}
                      </Field>
                    </Grid.Column>
                    <Grid.Column mobile={16} computer={5}>
                      <Field name="buy" placeholder={t('Buy')}>
                        {(props) => {
                          return renderInput(props);
                        }}
                      </Field>
                    </Grid.Column>
                    <Grid.Column mobile={16} computer={5}>
                      <Field name="sell" placeholder={t('Sell')}>
                        {(props) => {
                          return renderInput(props);
                        }}
                      </Field>
                    </Grid.Column>
                  </Grid>
                </SemanticForm>
              );
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={t('Cancel')}
            onClick={() => {
              form.reset();
              setOpen(false);
            }}
          />
          <Button type="submit" primary onClick={(e) => submit(e)}>
            {t('Submit')}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps, { load })(RateModal);
