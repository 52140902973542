import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import bigDecimal from 'js-big-decimal';
import CurrencyFormat from './CurrencyFormat';
import { useTranslation } from 'react-i18next';

const TransactionTotal = ({ transactions, formValues, currentUser, title }) => {
  const { t } = useTranslation();
  const [buyTransactions, setBuyTransactions] = useState([]);
  const [sellTransactions, setSellTransactions] = useState([]);

  useEffect(() => {
    if (transactions) {
      setBuyTransactions(_.filter(transactions, { type: 'buy' }));
      setSellTransactions(_.filter(transactions, { type: 'sell' }));
    } else if (formValues) {
      setBuyTransactions(formValues.buy);
      setSellTransactions(formValues.sell);
    }
  }, [transactions, formValues]);

  const renderTotal = (buyTransactions = [], sellTransactions = []) => {
    var buyBaseCurrency = 0;
    var sellBaseCurrency = 0;
    buyTransactions.map((value) => {
      if (value?.baseAmountCent) {
        buyBaseCurrency = buyBaseCurrency + value?.baseAmountCent / 100;
      } else if (value?.amount && value?.rate) {
        buyBaseCurrency = buyBaseCurrency + value?.amount * value?.rate;
      }
    });
    sellTransactions.map((value) => {
      if (value?.baseAmountCent) {
        sellBaseCurrency = sellBaseCurrency + value?.baseAmountCent / 100;
      } else if (value?.amount && value?.rate) {
        sellBaseCurrency = sellBaseCurrency + value?.amount * value?.rate;
      }
    });

    var totalBaseCurrency =
      Math.round((sellBaseCurrency - buyBaseCurrency + Number.EPSILON) * 100) /
      100;
    var baseAction =
      totalBaseCurrency < 0 ? t('Pay to client') : t('Receive from client');
    var baseCurrency = '';
    if (currentUser?.companies?.length > 0) {
      baseCurrency = currentUser.companies[0].baseCurrencyCode;
    }
    return (
      <div>
        {totalBaseCurrency !== 0 && title && <h2>{title}</h2>}
        {totalBaseCurrency !== 0 && (
          <div>
            <h4>
              {`${baseAction} `}
              <NumberFormat
                value={Math.abs(totalBaseCurrency)}
                displayType={'text'}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={true}
                prefix={`$`}
                suffix={` ${baseCurrency}`}
              />
            </h4>
          </div>
        )}
        {renderBuyCurrency(buyTransactions)}
        {renderSellCurrency(sellTransactions)}
      </div>
    );
  };

  const renderBuyCurrency = (transactions = []) => {
    return transactions.map((value, index) => {
      if (value?.amountCent) {
        return (
          <div key={index}>
            <h4>
              <b>{`${t('Receive from client')}:`} </b>
              <CurrencyFormat cent={value?.amountCent} />
            </h4>
          </div>
        );
      } else if (value?.amount && value?.currencyCode && value?.rate) {
        return (
          <div key={index}>
            <h4>
              <b>{`${t('Receive from client')}:`} </b>
              <NumberFormat
                value={
                  Math.round(
                    (parseFloat(value.amount) + Number.EPSILON) * 100
                  ) / 100
                }
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                suffix={` ${value.currencyCode}`}
              />
            </h4>
          </div>
        );
      } else {
        return null;
      }
    });
  };

  const renderSellCurrency = (transactions = []) => {
    return transactions.map((value, index) => {
      if (value?.amountCent) {
        return (
          <div key={index}>
            <h4>
              <b>{`${t('Pay to client')}:`} </b>
              <CurrencyFormat cent={value?.amountCent} />
            </h4>
          </div>
        );
      } else if (value?.amount && value?.currencyCode && value?.rate) {
        return (
          <div key={index}>
            <h4>
              <b>{`${t('Pay to client')}:`} </b>
              <NumberFormat
                value={
                  Math.round(
                    (parseFloat(value.amount) + Number.EPSILON) * 100
                  ) / 100
                }
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                suffix={` ${value.currencyCode}`}
              />
            </h4>
          </div>
        );
      } else {
        return null;
      }
    });
  };

  return <div>{renderTotal(buyTransactions, sellTransactions)}</div>;
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps, null)(TransactionTotal);
