import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Menu as MenuUI } from 'semantic-ui-react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Menu = () => {
  const [activeItem, setActiveItem] = useState('');
  const handleItemClick = (e, { name }) => setActiveItem(name);
  const { pathname } = useLocation();
  const { t } = useTranslation();
  useEffect(() => {
    if (_.includes(pathname, 'rate')) {
      setActiveItem('rate');
    }
    if (_.includes(pathname, 'inventory')) {
      setActiveItem('inventory');
    }
    if (_.includes(pathname, 'transaction')) {
      setActiveItem('transaction');
    }
    if (_.includes(pathname, 'history')) {
      setActiveItem('history');
    }
    if (_.includes(pathname, 'report')) {
      setActiveItem('report');
    }
    if (_.includes(pathname, 'customer')) {
      setActiveItem('customer');
    }
    if (_.includes(pathname, 'setting')) {
      setActiveItem('setting');
    }
  }, []);

  return (
    <MenuUI stackable tabular>
      <MenuUI.Item
        as={Link}
        to="/rate"
        name={t('Rate')}
        active={activeItem === 'rate'}
        onClick={handleItemClick}
      />
      <MenuUI.Item
        as={Link}
        to="/inventory"
        name={t('Inventory')}
        active={activeItem === 'inventory'}
        onClick={handleItemClick}
      />
      <MenuUI.Item
        as={Link}
        to="/transaction"
        name={t('Transaction')}
        active={activeItem === 'transaction'}
        onClick={handleItemClick}
      />
      <MenuUI.Item
        as={Link}
        to="/history"
        name={t('History')}
        active={activeItem === 'history'}
        onClick={handleItemClick}
      />
      <MenuUI.Item
        as={Link}
        to="/report"
        name={t('Report')}
        active={activeItem === 'report'}
        onClick={handleItemClick}
      />
      <MenuUI.Item
        as={Link}
        to="/customer"
        name={t('Customer')}
        active={activeItem === 'customer'}
        onClick={handleItemClick}
      />
      <MenuUI.Item
        as={Link}
        to="/setting"
        name={t('Setting')}
        active={activeItem === 'setting'}
        onClick={handleItemClick}
      />
    </MenuUI>
  );
};

export default Menu;
