import _ from 'lodash';
import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import {
  Label,
  Input,
  List,
  Modal,
  Button,
  Image,
  Header,
  Form as SemanticForm,
  Grid,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
  uploadImage,
  clearUploadImageStatus,
  clearUploadImageData,
  deleteImage,
} from '../actions';
import ErrorMessage from './ErrorMessage';
import { useTranslation } from 'react-i18next';

const renderError = ({ error, touched }) => {
  if (touched && error) {
    return (
      <div>
        <Label basic color="red" pointing>
          {error}
        </Label>
      </div>
    );
  }
};

const renderFile = (props) => {
  return (
    <div>
      <input
        type="file"
        onChange={({ target }) => {
          props.input.onChange(target.files);
        }}
        {...props}
      />
    </div>
  );
};

const ImageUploaModal = ({
  uploadImage,
  trigger,
  isFetching,
  isSuccess,
  errors,
  uploadedImages,
  clearUploadImageStatus,
  deleteImage,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  let submit;
  let form;

  const renderFileList = (files) => {
    return (
      <div>
        <List>
          {_.map(files, ({ name, key }) => {
            return (
              <div key={key}>
                <Grid padded>
                  <Grid.Row>
                    <Grid.Column width={12}>
                      <List.Item>{name}</List.Item>
                    </Grid.Column>
                    <Grid.Column>
                      <Button
                        icon="close"
                        size="mini"
                        onClick={(e) => {
                          onDeleteImage(e, key);
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            );
          })}
        </List>
      </div>
    );
  };

  const onSubmit = async (formValues) => {
    if (formValues.image) {
      const formData = new FormData();
      formData.append('image', formValues.image[0], formValues.image[0].name);
      uploadImage(formData);
    }
  };

  const onDeleteImage = (e, key) => {
    e.preventDefault();
    deleteImage(key);
  };

  return (
    <div>
      <Header as="h3">{t('Upload Photo')}</Header>
      <Form
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          form: renderForm,
          pristine,
          submitting,
          dirtySinceLastSubmit,
        }) => {
          submit = handleSubmit;
          form = renderForm;

          return (
            <Modal
              onClose={() => {
                form.restart();
                clearUploadImageStatus();
                setOpen(false);
              }}
              onOpen={() => {
                setOpen(true);
              }}
              open={open}
              trigger={
                <Button
                  type="text"
                  color="green"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  {trigger}
                </Button>
              }
            >
              <Modal.Header>{t('Upload Photo')}</Modal.Header>
              <Modal.Content>
                <SemanticForm onSubmit={handleSubmit}>
                  <Field name="image">
                    {(props) => {
                      return renderFile(props);
                    }}
                  </Field>
                </SemanticForm>
                <ErrorMessage errors={errors} />
              </Modal.Content>
              <Modal.Actions>
                <Button
                  content={
                    pristine || !isSuccess || dirtySinceLastSubmit
                      ? t('Upload')
                      : t('Success')
                  }
                  color={
                    pristine || !isSuccess || dirtySinceLastSubmit
                      ? 'grey'
                      : 'green'
                  }
                  disabled={pristine || submitting}
                  loading={isFetching}
                  type="submit"
                  onClick={(e) => submit(e)}
                />
                <Button
                  content={t('Cancel')}
                  onClick={() => {
                    form.restart();
                    clearUploadImageStatus();
                    setOpen(false);
                  }}
                />
              </Modal.Actions>
            </Modal>
          );
        }}
      />

      {renderFileList(uploadedImages)}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    isFetching: state.imageUpload.isFetching,
    isSuccess: state.imageUpload.isSuccess,
    errors: state.imageUpload.errors,
    uploadedImages: state.imageUpload.results,
  };
};

export default connect(mapStateToProps, {
  uploadImage,
  clearUploadImageData,
  clearUploadImageStatus,
  deleteImage,
})(ImageUploaModal);
