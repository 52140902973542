import React, { useState } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const ConfirmationModal = ({ trigger, header, content, handleOnSubmit }) => {
  const { t } = useTranslation();
  const onSubmit = (e) => {
    handleOnSubmit(e);
    setOpen(false);
  };
  const [open, setOpen] = useState(false);
  return (
    <div>
      <Modal
        onClose={() => {
          setOpen(false);
        }}
        onOpen={() => {
          setOpen(true);
        }}
        open={open}
        trigger={<Button color="red">{trigger}</Button>}
      >
        <Modal.Header>{header}</Modal.Header>
        <Modal.Content>{content}</Modal.Content>
        <Modal.Actions>
          <Button
            content={t('Cancel')}
            onClick={() => {
              setOpen(false);
            }}
          />
          <Button type="submit" color="red" onClick={onSubmit}>
            {t('Delete')}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
