import React from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import {
  Message,
  Input,
  Label,
  Button,
  Form as SemanticForm,
  Container,
  Grid,
} from 'semantic-ui-react';
import { fetchCurrentUser, resetPassword } from '../actions';
import ErrorMessage from './ErrorMessage';

const renderInput = ({ input, label, meta }) => {
  const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
  return (
    <div className={className}>
      <label>{label}</label>
      <Input {...input} />
      {renderError(meta)}
    </div>
  );
};

const renderError = ({ error, touched }) => {
  if (touched && error) {
    return (
      <Label basic color="red" pointing>
        {error}
      </Label>
    );
  }
};

const ResetPassword = ({
  resetPassword,
  fetchCurrentUser,
  isFetching,
  isSuccess,
  errors,
}) => {
  var params = new URLSearchParams(useLocation().search);
  var userId = params.get('userId');
  var token = params.get('token');
  const onSubmit = async (formValues) => {
    await resetPassword(formValues, userId, token);
    await fetchCurrentUser();
  };
  if (isSuccess) {
    return (
      <div>
        <Container>
          <Grid padded centered>
            <Grid.Row>
              <Grid.Column>
                <Message positive>
                  <Message.Header>Password reset</Message.Header>
                  <p>Password successfully reset</p>
                </Message>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Link to="/">
                  <Button content="Go to home" primary />
                </Link>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  } else {
    return (
      <div>
        <Container>
          <Grid padded centered>
            <Grid.Row>
              <Grid.Column computer={8} mobile={12}>
                <h1>Reset password</h1>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column computer={8} mobile={12}>
                <Form
                  onSubmit={onSubmit}
                  validate={(values) => {
                    const errors = {};
                    if (!values.password) {
                      errors.password = 'Enter a password';
                    }
                    if (!values.confirmationPassword) {
                      errors.confirmationPassword =
                        'Enter confirmation password';
                    }
                    return errors;
                  }}
                  render={({ handleSubmit }) => {
                    return (
                      <SemanticForm
                        className="ui form error"
                        onSubmit={handleSubmit}
                      >
                        <Field name="password" label="Password" type="password">
                          {(props) => {
                            return renderInput(props);
                          }}
                        </Field>
                        <Field
                          name="confirmationPassword"
                          label="Confirmation Password"
                          type="password"
                        >
                          {(props) => {
                            return renderInput(props);
                          }}
                        </Field>
                        <Button type="submit" primary loading={isFetching}>
                          Reset
                        </Button>
                      </SemanticForm>
                    );
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column computer={8} mobile={12}>
                <ErrorMessage errors={errors} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    isFetching: state.resetPassword.isFetching,
    isSuccess: state.resetPassword.isSuccess,
    errors: state.resetPassword.errors,
  };
};

export default connect(mapStateToProps, { resetPassword, fetchCurrentUser })(
  ResetPassword
);
