import React from 'react';
import _ from 'lodash';
import { Form, Field } from 'react-final-form';
import cc from 'currency-codes';
import { connect } from 'react-redux';
import {
  Form as SemanticForm,
  Input,
  Label,
  Grid,
  Dropdown,
  Checkbox,
} from 'semantic-ui-react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { signUp } from '../actions';
import ErrorMessage from './ErrorMessage';
import Layout from './Layout';
import { StyledButton } from './styledComponents';
import { useTranslation } from 'react-i18next';
import TOS from '../assets/Online Terms and Conditions.pdf';

const currencyCodes = (codes) => {
  return _.map(codes, (code) => {
    return {
      key: code,
      text: code,
      value: code,
    };
  });
};

const renderInput = ({ input, label, meta, placeholder }) => {
  const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
  return (
    <div className={className}>
      <label>{label}</label>
      <Input {...input} placeholder={placeholder} autoComplete="new-password" />
      {renderError(meta)}
    </div>
  );
};

const renderPhoneInput = ({ input, label, meta }) => {
  const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
  return (
    <div className={className}>
      <label>{label}</label>
      <PhoneInput {...input} placeholder="Enter phone number" />
      {renderError(meta)}
    </div>
  );
};

const renderDropdown = (props, currencies) => {
  const className = `field ${
    props.meta.error && props.meta.touched ? 'error' : ''
  }`;
  return (
    <div className={className}>
      <label>{props.label}</label>
      <Dropdown
        placeholder={props.placeholder}
        input={props.input}
        value={props.input.value}
        search
        selection
        options={currencies}
        onChange={(e, data) => {
          props.input.onChange(data.value);
        }}
      />
      {renderError(props.meta)}
    </div>
  );
};

const renderCheckBox = (props) => {
  const className = `field ${
    props.meta.error && props.meta.touched ? 'error' : ''
  }`;
  return (
    <div className={className}>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Checkbox
              onChange={(e, data) => {
                props.input.onChange(data.checked);
              }}
            />
          </Grid.Column>
          <Grid.Column width={12}>
            <label>
              I agree to{' '}
              <a href={TOS} target="_blank">
                {props.label}
              </a>
            </label>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {renderError(props.meta)}
    </div>
  );
};

const renderError = ({ error, touched }) => {
  if (touched && error) {
    return (
      <Label basic color="red" pointing>
        {error}
      </Label>
    );
  }
};

const Signup = ({ signUp, errors, isFetching }) => {
  const { t } = useTranslation();
  const onSubmit = (formValues) => {
    signUp(formValues);
  };
  return (
    <div>
      <Layout>
        <Grid padded centered>
          <Grid.Row>
            <Grid.Column computer={8} mobile={12}>
              <h1>{t('Sign Up')}</h1>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={8} mobile={12}>
              <Form
                onSubmit={onSubmit}
                validate={(values) => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = 'Enter your email';
                  }
                  if (!values.password) {
                    errors.password = 'Enter your password';
                  }
                  if (!values.confirmationPassword) {
                    errors.confirmationPassword =
                      'Enter your confirmation password';
                  }
                  if (!values.companyName) {
                    errors.companyName = 'Enter company name';
                  }
                  if (!values.baseCurrencyCode) {
                    errors.baseCurrencyCode = 'Enter base currency code';
                  }
                  if (!values.address) {
                    errors.address = 'Enter address';
                  }
                  if (!values.phoneNumber) {
                    errors.phoneNumber = 'Enter phone number';
                  }
                  if (!values.tos) {
                    errors.tos = 'Agree to terms and conditions';
                  }
                  return errors;
                }}
                render={({ handleSubmit }) => {
                  return (
                    <SemanticForm
                      className="ui form error"
                      onSubmit={handleSubmit}
                    >
                      <Field name="email" label={t('Email')} type="email">
                        {(props) => {
                          return renderInput(props);
                        }}
                      </Field>

                      <Field
                        name="password"
                        label={t('Password')}
                        type="password"
                      >
                        {(props) => {
                          return renderInput(props);
                        }}
                      </Field>

                      <Field
                        name="confirmationPassword"
                        label={t('Confirmation Password')}
                        type="password"
                      >
                        {(props) => {
                          return renderInput(props);
                        }}
                      </Field>

                      <Field
                        name="companyName"
                        label={t('Company Name')}
                        type="text"
                        placeholder="eg. Peter's Coin Shop"
                      >
                        {(props) => {
                          return renderInput(props);
                        }}
                      </Field>

                      <Field
                        name="address"
                        label={t('Company Address')}
                        type="text"
                        placeholder="eg. 123 Market St."
                      >
                        {(props) => {
                          return renderInput(props);
                        }}
                      </Field>

                      <Field
                        name="phoneNumber"
                        label={t('Phone Number')}
                        type="text"
                        placeholder="eg. 555-555-5555"
                      >
                        {(props) => {
                          return renderPhoneInput(props);
                        }}
                      </Field>

                      <Field
                        name="baseCurrencyCode"
                        label={t('Base Currency Code')}
                        type="text"
                        placeholder="eg. HKD"
                      >
                        {(props) => {
                          return renderDropdown(
                            props,
                            currencyCodes(cc.codes())
                          );
                        }}
                      </Field>
                      <Field
                        name="tos"
                        label="Terms and conditions"
                        type="checkbox"
                      >
                        {(props) => {
                          return renderCheckBox(props);
                        }}
                      </Field>
                      <StyledButton loading={isFetching} primary>
                        {t('Sign Up')}
                      </StyledButton>
                    </SemanticForm>
                  );
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={8} mobile={12}>
              <ErrorMessage errors={errors} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Layout>
    </div>
  );
};

const mapStatesToProps = (state) => {
  return {
    currentUser: state.currentUser,
    errors: state.signin.errors,
    isFetching: state.signin.isFetching,
  };
};

export default connect(mapStatesToProps, { signUp })(Signup);
