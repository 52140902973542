import React, { useState } from 'react';
import Layout from './Layout';
import { useLocation, Link } from 'react-router-dom';
import { Message, Grid } from 'semantic-ui-react';
import { StyledButton } from './styledComponents';

const SubscribeStatus = () => {
  var params = new URLSearchParams(useLocation().search);
  const renderStatus = (params) => {
    let header, message, positive, negative;
    if (params.get('session_id')) {
      header = 'Successfully subscribed';
      message =
        'You can now use Gold Gold Bird. Subscription can be managed from your profile settings.';
      positive = true;
      negative = false;
    } else if (params.get('cancel') === 'true') {
      header = 'Not completed';
      message = 'Your subscribtion was not completed';
      positive = false;
      negative = true;
    }
    return (
      <div>
        <Grid padded>
          <Grid.Row centered>
            <Grid.Column width={8}>
              <Message positive={positive} negative={negative}>
                <Message.Header>{header}</Message.Header>
                <p>{message}</p>
              </Message>
              <Link to="/">
                <StyledButton primary>Home</StyledButton>
              </Link>
              <Link to="/setting?active=profile">
                <StyledButton primary>Profile</StyledButton>
              </Link>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  };
  return (
    <div>
      <Layout>{renderStatus(params)}</Layout>
    </div>
  );
};

export default SubscribeStatus;
