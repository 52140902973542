import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon, Image, Dropdown, Menu } from 'semantic-ui-react';
import { fetchCurrentUser } from '../actions';
import SigninButton from './SigninButton';
import { StyledButton } from './styledComponents';
import iconImage from '../images/bird_icon.png';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';

const Header = ({ currentUser, fontColor }) => {
  const cookies = new Cookies();
  const { t, i18n } = useTranslation();

  const didChangeLanguage = (e, data) => {
    i18n.changeLanguage(data.value);
    cookies.set('i18next', data.value, { path: '/' });
  };
  const languageOptions = [
    {
      key: 'english',
      text: 'English',
      value: 'en',
    },
    {
      key: 'zh-TW',
      text: '繁體中文',
      value: 'zh-TW',
    },
    {
      key: 'th',
      text: 'Thai',
      value: 'th',
    },
  ];

  return (
    <div>
      <Menu secondary>
        <Menu.Item>
          <Link to="/" style={{ color: 'black' }}>
            <Image
              centered
              inline
              size="tiny"
              src={iconImage}
              avatar
              style={{ borderRadius: 0, paddingRight: '10px' }}
            />
            <span
              style={{
                fontSize: '200%',
                verticalAlign: 'bottom',
                fontWeight: 'bold',
                color: fontColor,
              }}
            >
              {t('Gold Gold Bird')}
            </span>
          </Link>
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item>
            <Dropdown
              placeholder="Select Language"
              selection
              defaultValue={cookies.get('i18next') ?? 'en'}
              onChange={didChangeLanguage}
              options={languageOptions}
            />
          </Menu.Item>
          <Menu.Item>
            {currentUser.id && (
              <Link to="/setting?active=profile">
                <Icon name="user" size="big" color="black" />
              </Link>
            )}
            {!currentUser.id && (
              <div>
                <SigninButton isLoading={false} isSignedIn={currentUser.id} />
                <Link to={'/signup'}>
                  <StyledButton primary>{t('Sign Up')}</StyledButton>
                </Link>
              </div>
            )}
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    </div>
  );
};

const mapStatesToProps = (state) => {
  return {
    signin: state.signin,
    currentUser: state.currentUser,
  };
};

export default connect(mapStatesToProps, { fetchCurrentUser })(Header);
