import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SearchCustomer from './SearchCustomer';
import {
  fetchCustomerTransactions,
  clearAllCustomerTransactions,
} from '../actions';
import HistoryTransactions from './HistoryTransactions';
import AuthenticatedLayout from './AuthenticatedLayout';
import { useTranslation } from 'react-i18next';

const Customer = ({
  fetchCustomerTransactions,
  clearAllCustomerTransactions,
  transactions,
}) => {
  const { t } = useTranslation();
  const [fetchedTransactions, setFetchedTransactions] = useState([]);
  useEffect(() => {
    setFetchedTransactions(transactions);
  }, [transactions]);

  useEffect(() => {
    return () => {
      clearAllCustomerTransactions();
    };
  }, []);
  const didSelectCustomer = async (customer) => {
    await fetchCustomerTransactions(customer.id);
  };
  const didClickClose = () => {
    setFetchedTransactions([]);
  };
  return (
    <div>
      <AuthenticatedLayout>
        <h2>{t('Customer')}</h2>
        <SearchCustomer
          didSelectCustomer={didSelectCustomer}
          didClickClose={didClickClose}
        />
        <HistoryTransactions transactions={fetchedTransactions} />
      </AuthenticatedLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isFetching: state.customerTransactions?.isFetching,
    errors: state.customerTransactions?.errors,
    transactions: state.customerTransactions?.results,
  };
};

export default connect(mapStateToProps, {
  fetchCustomerTransactions,
  clearAllCustomerTransactions,
})(Customer);
