import React from 'react';
import { Message } from 'semantic-ui-react';

const SuccessMessage = ({ message }) => {
  if (!message) {
    return null;
  } else {
    return <Message success header={message} />;
  }
};

export default SuccessMessage;
