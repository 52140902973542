import React from 'react';
import {
  Grid,
  Segment,
  Container,
  Header,
  Divider,
  List,
} from 'semantic-ui-react';
import '../App.css';
import { useTranslation } from 'react-i18next';
import TOS from '../assets/Online Terms and Conditions.pdf';
import PrivacyPolicy from '../assets/Online Privacy Policy.pdf';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <Segment
        inverted
        vertical
        style={{
          margin: '5em 0em 0em',
          padding: '3em 0em',
        }}
      >
        <Container textAlign="center">
          <Grid divided inverted stackable>
            <Grid.Column width={4}>
              <Header inverted as="h4" content={t('About')} />
              <List link inverted>
                <List.Item as="a" href="/aboutus">
                  {t('About Us')}
                </List.Item>
                <List.Item as="a" href="/faq">
                  {t('FAQ')}
                </List.Item>
                <List.Item as="a" href="/subscription-plan">
                  {t('Pricing')}
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={4}>
              <Header inverted as="h4" content={t('Media')} />
              <List link inverted>
                <List.Item as="a">Facebook</List.Item>
                <List.Item as="a">Twitter</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={7}>
              <Header inverted as="h4" content={t('Have a question?')} />
              <p>
                <a href="mailto:contact@goldgoldbird.com">{t('Contact us')}</a>
              </p>
            </Grid.Column>
          </Grid>

          <Divider inverted section />

          <List horizontal inverted divided link size="small">
            <List.Item>© 2021 FX Technology Inc</List.Item>
            <List.Item as="a" href="#">
              Site Map
            </List.Item>
            <List.Item as="a" href="mailto:support@goldgoldbird.com">
              {t('Contact us')}
            </List.Item>
            <List.Item as="a" href={TOS} target="_blank">
              {t('Terms and Conditions')}
            </List.Item>
            <List.Item as="a" href={PrivacyPolicy} target="_blank">
              {t('Privacy Policy')}
            </List.Item>
          </List>
        </Container>
      </Segment>
    </div>
  );
};

export default Footer;
