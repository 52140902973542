import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { signOut } from '../actions';
import { StyledButton } from './styledComponents';
import { useTranslation } from 'react-i18next';

const SigninButton = ({ isSignedIn, isLoading, signOut }) => {
  const { t } = useTranslation();
  const onSignOutClick = async () => {
    signOut();
  };

  const config = {
    text: isSignedIn ? t('Logout') : t('Login'),
    onClick: isSignedIn ? onSignOutClick : () => {},
  };

  if (isSignedIn) {
    return (
      <StyledButton primary onClick={config.onClick} loading={isLoading}>
        {config.text}
      </StyledButton>
    );
  } else {
    return (
      <Link to="/signin">
        <StyledButton primary onClick={config.onClick} loading={isLoading}>
          {config.text}
        </StyledButton>
      </Link>
    );
  }
};

export default connect(null, { signOut })(SigninButton);
