import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import {
  Modal,
  Label,
  Input,
  Button,
  Form as SemanticForm,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const renderInput = ({ input, placeholder, meta }) => {
  const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
  return (
    <div className={className}>
      <Input autoComplete="off" placeholder={placeholder} {...input} />
      {renderError(meta)}
    </div>
  );
};

const renderError = ({ error, touched }) => {
  if (touched && error) {
    return (
      <Label basic color="red" pointing>
        {error}
      </Label>
    );
  }
};

const EmailModal = ({ handleOnSubmit, children, headerTitle }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const onSubmit = (formValues) => {
    handleOnSubmit(formValues);
    setOpen(false);
  };

  let submit;
  let form;

  return (
    <div>
      <Modal
        onClose={() => {
          form.reset();
          setOpen(false);
        }}
        onOpen={() => {
          setOpen(true);
        }}
        open={open}
        trigger={children}
      >
        <Modal.Header>{headerTitle}</Modal.Header>
        <Modal.Content>
          <Form
            onSubmit={onSubmit}
            validate={(values) => {
              var errors = {};
              if (!values.email) {
                errors.email = t('Enter an email');
              }
              return errors;
            }}
            render={({ handleSubmit, form: renderForm }) => {
              submit = handleSubmit;
              form = renderForm;
              return (
                <SemanticForm onSubmit={handleSubmit}>
                  <Field name="email" placeholder={t('Email')}>
                    {(props) => {
                      return renderInput(props);
                    }}
                  </Field>
                </SemanticForm>
              );
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={t('Cancel')}
            onClick={() => {
              form.reset();
              setOpen(false);
            }}
          />
          <Button type="submit" primary onClick={(e) => submit(e)}>
            {t('Submit')}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default EmailModal;
