import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchInventory, createTransaction } from '../actions';
import CurrencyFormat from './CurrencyFormat';
import CurrencyFlag from 'react-currency-flags';
import ErrorMessage from './ErrorMessage';
import AuthenticatedLayout from './AuthenticatedLayout';
import { Table, Grid, Divider, Header, Icon, Popup } from 'semantic-ui-react';
import InventoryEntry from './InventoryEntry';
import { useTranslation } from 'react-i18next';

const Inventory = ({ currentUser, inventory, errors, fetchInventory }) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (currentUser.companies) {
      fetchInventory();
    }
  }, [currentUser]);

  /*useEffect(() => {
    if (inventory) {
      const baseCurrencyCode = currentUser?.selectedCompany?.baseCurrencyCode;
      if (baseCurrencyCode) {
        const baseCurrency = _.find(
          inventory,
          (o) => o.currencyCode === baseCurrencyCode
        );
        if (baseCurrency) {
          setBaseCurrencyAmount(baseCurrency.amount);
        }
      }
    }
  }, [inventory]);*/

  const renderTableRows = () => {
    return _.map(inventory, ({ currencyCode, amountCent, id }) => {
      return (
        <Table.Row key={id}>
          <Table.Cell>
            <Grid padded="horizontally">
              <Grid.Column width={3}>
                <CurrencyFlag currency={currencyCode} size="md" />
              </Grid.Column>
              <Grid.Column>
                <b style={{ fontSize: '16px' }}>{currencyCode}</b>
              </Grid.Column>
            </Grid>
          </Table.Cell>
          <Table.Cell>
            <CurrencyFormat cent={amountCent} style={{ fontSize: '16px' }} />
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const renderTable = () => {
    return (
      <div>
        <Divider horizontal>
          <Header as="h4">
            <Icon name="dollar sign" />
            {t('Inventory')}
          </Header>
        </Divider>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('Currency')}</Table.HeaderCell>
              <Table.HeaderCell>{t('Amount')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{renderTableRows()}</Table.Body>
        </Table>
      </div>
    );
  };

  const renderEmpty = () => {
    return (
      <div>
        <h3>{t('Start by adding some transaction')}</h3>
      </div>
    );
  };

  const renderInventoryEntryForm = () => {
    var isOwner = currentUser.selectedCompany?.ownerId === currentUser.id;
    if (isOwner) {
      return (
        <div>
          <Divider horizontal>
            <Popup
              content={t(
                'Add or subtract from inventory without affecting base currency'
              )}
              trigger={
                <Header as="h4">
                  <Icon name="plus" />
                  {t('Add or Remove')}
                </Header>
              }
            />
          </Divider>
          <InventoryEntry />
        </div>
      );
    } else {
      <div></div>;
    }
  };

  return (
    <div>
      <AuthenticatedLayout>
        <h2>{t('Inventory')}</h2>
        <ErrorMessage errors={errors} />
        <Grid padded>
          <Grid.Row>
            <Grid.Column>{renderInventoryEntryForm()}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {_.size(inventory) > 0 ? renderTable() : renderEmpty()}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </AuthenticatedLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  var inventory = [];
  if (state.inventory.results) {
    inventory = Object.values(state.inventory.results);
  }
  return {
    currentUser: state.currentUser,
    inventory: inventory,
    errors: state.inventory.errors,
    isSuccess: state.transactions.isSuccess,
  };
};

export default connect(mapStateToProps, { fetchInventory, createTransaction })(
  Inventory
);
