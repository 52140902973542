import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Cookies from 'universal-cookie';

import translationEn from '../locales/en/translation.json';
import translationHk from '../locales/zh-TW/translation.json';
import translationTh from '../locales/th/translation.json';

const cookies = new Cookies();
i18n
  //.use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: translationEn,
      },
      'zh-TW': {
        translation: translationHk,
      },
      th: {
        translation: translationTh,
      },
    },
    lng: cookies.get('i18next') ?? 'en', // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
    supportedLngs: ['en', 'zh-TW', 'th'],
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
