import React, { useState } from 'react';
import _ from 'lodash';
import cc from 'currency-codes';
import { Form, Field } from 'react-final-form';
import {
  Modal,
  Grid,
  Input,
  Dropdown,
  Button,
  Label,
  Select,
  Form as SemanticForm,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const TransactionEditModal = ({ rates, transaction, handleOnSubmit }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  let submit;
  let form;

  const onSubmit = (formValues) => {
    handleOnSubmit(formValues);
    setOpen(false);
  };

  const currencyCodes = () => {
    return _.map(cc.codes(), (code) => {
      return {
        key: code,
        text: code,
        value: code,
      };
    });
  };

  const renderInput = ({ input, placeholder, meta }) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
    return (
      <div className={className}>
        <label>{placeholder}</label>
        <Input autoComplete="off" placeholder={placeholder} {...input} />
        {renderError(meta)}
      </div>
    );
  };

  const renderDropdown = (props, updateRate) => {
    const className = `field ${
      props.meta.error && props.meta.touched ? 'error' : ''
    }`;
    return (
      <div className={className}>
        <label>{props.placeholder}</label>
        <Dropdown
          {...props}
          input={props.input}
          value={props.input.value}
          search
          selection
          options={currencyCodes()}
          onChange={(e, data) => {
            props.input.onChange(data.value);
            var rate = transaction.isBuy
              ? rates[data.value]?.buy
              : rates[data.value]?.sell;
            updateRate(rate);
          }}
        />
        {renderError(props.meta)}
      </div>
    );
  };

  const renderSelect = (props) => {
    const options = [
      { key: 'buy', text: t('Buy'), value: 'buy' },
      { key: 'sell', text: t('Sell'), value: 'sell' },
    ];
    return (
      <div className="field">
        <label>{props.placeholder}</label>
        <Select
          onChange={(e, value) => props.input.onChange(value.value)}
          value={props.input.value}
          compact
          options={options}
        />
      </div>
    );
  };

  const renderError = ({ error, touched }) => {
    if (touched && error) {
      return (
        <Label basic color="red" pointing>
          {error}
        </Label>
      );
    }
  };

  return (
    <div>
      <Modal
        onClose={() => {
          setOpen(false);
        }}
        onOpen={() => {
          setOpen(true);
        }}
        open={open}
        trigger={<Button>{t('Edit')}</Button>}
      >
        <Modal.Header>{t('Edit Transaction')}</Modal.Header>
        <Modal.Content>
          <Form
            onSubmit={onSubmit}
            initialValues={transaction}
            mutators={{
              updateRate: (args, state, utils) => {
                utils.changeValue(state, 'rate', () => args[0]);
              },
            }}
            validate={(values) => {
              const errors = {};
              if (!values.currencyCode) {
                errors.currencyCode = 'Enter a currency code';
              }
              if (!values.amount) {
                errors.amount = 'Enter an amount';
              }
              if (!values.rate) {
                errors.rate = 'Enter a rate';
              }
              return errors;
            }}
            render={({ handleSubmit, form: renderForm }) => {
              submit = handleSubmit;
              form = renderForm;
              return (
                <SemanticForm onSubmit={handleSubmit}>
                  <Grid columns={4} verticalAlign="middle">
                    <Grid.Column>
                      <Field
                        name="currencyCode"
                        placeholder={t('Currency Code')}
                      >
                        {(props) => {
                          return renderDropdown(
                            props,
                            form.mutators.updateRate
                          );
                        }}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <Field name="amount" placeholder={t('Amount')}>
                        {(props) => {
                          return renderInput(props);
                        }}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <Field name="rate" placeholder={t('Rate')}>
                        {(props) => {
                          return renderInput(props);
                        }}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <Field name="type" placeholder={t('Buy or sell')}>
                        {(props) => {
                          return renderSelect(props);
                        }}
                      </Field>
                    </Grid.Column>
                  </Grid>
                </SemanticForm>
              );
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={t('Cancel')}
            onClick={() => {
              form.reset();
              setOpen(false);
            }}
          />
          <Button type="submit" primary onClick={(e) => submit(e)}>
            {t('Submit')}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default TransactionEditModal;
