import _ from 'lodash';
import React from 'react';
import { Form, Field } from 'react-final-form';
import {
  Dropdown,
  Label,
  Input,
  Select,
  Button,
  Form as SemanticForm,
  Segment,
  Grid,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import cc from 'currency-codes';
import ErrorMessage from './ErrorMessage';
import { createTransaction, fetchInventory } from '../actions';
import { useTranslation } from 'react-i18next';

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

const validateNumber = (value) => {
  return isNumeric(value) && value > 0 ? undefined : 'Enter a number';
};

const currencyCodes = (codes) => {
  return _.map(codes, (code) => {
    return {
      key: code,
      text: code,
      value: code,
    };
  });
};

const renderError = ({ error, touched }) => {
  if (touched && error) {
    return (
      <div>
        <Label basic color="red" pointing>
          {error}
        </Label>
      </div>
    );
  }
};

const renderInput = ({ input, placeholder, meta }) => {
  const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
  return (
    <div className={className}>
      <Input autoComplete="off" placeholder={placeholder} {...input} />
      {renderError(meta)}
    </div>
  );
};

const InventoryEntry = ({
  isSuccess,
  errors,
  currentUser,
  createTransaction,
  fetchInventory,
}) => {
  const { t } = useTranslation();
  let reduxForm;

  const renderSelect = (props) => {
    const options = [
      { key: 'add', text: t('Add'), value: 'add' },
      { key: 'remove', text: t('Remove'), value: 'remove' },
    ];
    return (
      <div>
        <Select
          fluid
          onChange={(e, value) => props.input.onChange(value.value)}
          value={props.input.value}
          compact
          options={options}
        />
      </div>
    );
  };

  const renderDropdown = (props, currencies, updateRate) => {
    const className = `field ${
      props.meta.error && props.meta.touched ? 'error' : ''
    }`;
    return (
      <div className={className}>
        <Dropdown
          placeholder={props.placeholder}
          input={props.input}
          value={props.input.value}
          search
          selection
          options={currencies}
          onChange={(e, data) => {
            props.input.onChange(data.value);
            if (data.value === currentUser.selectedCompany?.baseCurrencyCode) {
              //pre-populate rate to 1 if base currency
              updateRate('1');
            }
          }}
        />
        {renderError(props.meta)}
      </div>
    );
  };
  const onSubmit = async (formValues) => {
    var payload = {};
    if (formValues.select === 'add') {
      payload = {
        buy: [
          {
            amount: formValues.amount,
            rate: formValues.rate,
            currencyCode: formValues.currencyCode,
          },
        ],
      };
    } else {
      payload = {
        sell: [
          {
            amount: formValues.amount,
            rate: formValues.rate,
            currencyCode: formValues.currencyCode,
          },
        ],
      };
    }
    await createTransaction(payload, null, false, false);
    reduxForm.restart();
    await fetchInventory();
  };
  const renderForm = () => {
    return (
      <div>
        <Form
          onSubmit={onSubmit}
          initialValues={{ select: 'add' }}
          mutators={{
            updateRate: (args, state, utils) => {
              utils.changeValue(state, 'rate', () => args[0]);
            },
          }}
          render={({ handleSubmit, form, pristine, submitting }) => {
            reduxForm = form;
            return (
              <SemanticForm onSubmit={handleSubmit}>
                <Segment>
                  <Grid centered>
                    <Grid.Column computer={4} mobile={16}>
                      <Field
                        name="currencyCode"
                        placeholder={t('Currency Code')}
                        validate={(value) =>
                          value ? undefined : 'Currency code needed'
                        }
                      >
                        {(props) => {
                          return renderDropdown(
                            props,
                            currencyCodes(cc.codes()),
                            form.mutators.updateRate
                          );
                        }}
                      </Field>
                    </Grid.Column>
                    <Grid.Column computer={4} mobile={16}>
                      <Field
                        name="amount"
                        placeholder={t('Amount')}
                        validate={validateNumber}
                      >
                        {(props) => {
                          return renderInput(props);
                        }}
                      </Field>
                    </Grid.Column>
                    <Grid.Column computer={4} mobile={16}>
                      <Field
                        name="rate"
                        placeholder={t('Rate')}
                        validate={validateNumber}
                      >
                        {(props) => {
                          return renderInput(props);
                        }}
                      </Field>
                    </Grid.Column>
                    <Grid.Column computer={2} mobile={8}>
                      <Field name="select" placeholder="Select">
                        {(props) => {
                          return renderSelect(props);
                        }}
                      </Field>
                    </Grid.Column>
                    <Grid.Column computer={2} mobile={8}>
                      <Button
                        fluid
                        content={
                          isSuccess && pristine ? t('Saved') : t('Submit')
                        }
                        type="submit"
                        loading={submitting}
                        disabled={pristine || submitting}
                        color={isSuccess && pristine ? 'green' : 'grey'}
                      />
                    </Grid.Column>
                  </Grid>
                </Segment>
              </SemanticForm>
            );
          }}
        />
      </div>
    );
  };
  return (
    <div>
      {renderForm()}
      <ErrorMessage errors={errors} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isSuccess: state.transactions.isSuccess,
    errors: state.transactions.errors,
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps, { createTransaction, fetchInventory })(
  InventoryEntry
);
