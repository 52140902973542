import _ from 'lodash';
import React, { useState, useRef, useEffect } from 'react';
import {
  Modal,
  Icon,
  Divider,
  Card,
  Segment,
  Grid,
  Header,
  Container,
  Image,
} from 'semantic-ui-react';
import NumberFormat from 'react-number-format';
import CurrencyFormat from './CurrencyFormat';
import { inv } from 'mathjs';
import { connect } from 'react-redux';
import CurrencyFlag from 'react-currency-flags';
import ReactToPrint from 'react-to-print';
import HistoryTransactions from './HistoryTransactions';
import TransactionTotal from './TransactionTotal';
import ReceiptEmailModal from './ReceiptEmailModal';
import ReceiptToPrint from './ReceiptToPrint';
import { StyledButton } from './styledComponents';
import { format } from 'date-fns';
import { fetchImageUrls } from '../actions';
import { useTranslation } from 'react-i18next';

const formatTime = (date) => {
  const dateObject = new Date(date);
  return `${dateObject.toLocaleDateString()} - ${dateObject.toLocaleTimeString()}`;
};

const TransactionDetailModal = ({
  transaction,
  transactions,
  currentUser,
  fetchImageUrls,
  imageUrls,
}) => {
  const { t } = useTranslation();
  const { customer } = transaction;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const asyncFetch = async () => {
      if (transaction.customer?.images?.length > 0) {
        fetchImageUrls(transaction.customer?.images);
      }
    };
    asyncFetch();
  }, []);

  const renderPersonalId = (customer) => {
    if (customer?.personalId && customer?.personalIdType) {
      var personalIdType;

      switch (customer?.personalIdType) {
        case 'driver_license':
          personalIdType = t('Driver License');
          break;
        case 'passport':
          personalIdType = t('Password');
          break;
        default:
          personalIdType = t('Other');
          break;
      }
      return (
        <div>
          <b>{`${personalIdType}: `}</b>
          {customer?.personalId}
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const renderEntry = (label, data) => {
    if (data) {
      return (
        <div>
          <b>{`${label}: `}</b>
          {data}
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const renderGroupInfo = (transactions) => {
    const { groupId, createdAt } = _.first(transactions) ?? {};
    return (
      <div>
        <Card>
          <Card.Content>
            <Card.Header>{t('Info')}</Card.Header>
            <Card.Description>
              <b>{`${t('Id')}:`} </b>
              {groupId}
              <br />
              <b>{`${t('Date')}:`} </b>
              {formatTime(createdAt)}
              <br />
            </Card.Description>
          </Card.Content>
        </Card>
        <br />
      </div>
    );
  };

  const renderTransaction = (
    { amountCent, rate, currencyCode, type, id } = {},
    index
  ) => {
    return (
      <div key={id}>
        <Segment>
          <Grid columns={4}>
            <Grid.Row>
              <Grid.Column width="4">
                <h3>{`${index + 1}. ${
                  type === 'buy' ? t('Customer Sold') : t('Customer Bought')
                } (${currencyCode})`}</h3>
              </Grid.Column>
              <Grid.Column width="2" textAlign="center">
                {renderFromFlag({ currencyCode, type })}
              </Grid.Column>
              <Grid.Column width="1" textAlign="center">
                <Icon name="long arrow alternate right" size="large" />
              </Grid.Column>
              <Grid.Column width="2" textAlign="center">
                {renderToFlag({ currencyCode, type })}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid columns={2} stackable textAlign="left">
            <Grid.Row>
              <Grid.Column>
                <Card>
                  <Card.Content>
                    <Card.Header>
                      {type === 'buy' ? t('Shop Bought') : t('Customer Bought')}
                    </Card.Header>
                    <Card.Description>
                      {`${currencyCode}`}
                      {'    '}
                      <CurrencyFlag size="md" currency={currencyCode} />
                      <br />
                      <b>{`${t('Amount')}:`} </b>
                      <CurrencyFormat cent={amountCent} />
                      <br />
                      <b>{`${t('Rate')}:`} </b>
                      <NumberFormat
                        value={parseFloat(rate).toPrecision(6)}
                        displayType={'text'}
                        fixedDecimalScale={true}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                      <br />
                      <b>{`${t('Rate inv')}:`}</b>
                      <NumberFormat
                        value={parseFloat(inv(rate)).toPrecision(6)}
                        displayType={'text'}
                        fixedDecimalScale={true}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                      <br />
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
              <Grid.Column>
                <Card>
                  <Card.Content>
                    <Card.Header>
                      {type === 'buy'
                        ? t('Customer received')
                        : t('Shop received')}
                    </Card.Header>
                    <Card.Description>
                      {`${currentUser?.selectedCompany?.baseCurrencyCode}`}
                      {'    '}
                      <CurrencyFlag
                        size="md"
                        currency={
                          currentUser?.selectedCompany?.baseCurrencyCode
                        }
                      />
                      <br />
                      <b>{`${t('Amount')}:`} </b>
                      <CurrencyFormat cent={amountCent} />
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <br />
      </div>
    );
  };

  const renderFromFlag = ({ currencyCode, type }, size = 'xl') => {
    var flagCode =
      type === 'buy'
        ? currencyCode
        : currentUser?.selectedCompany?.baseCurrencyCode;
    return <CurrencyFlag size={size} currency={flagCode} />;
  };

  const renderToFlag = ({ currencyCode, type }, size = 'xl') => {
    var flagCode =
      type === 'sell'
        ? currencyCode
        : currentUser?.selectedCompany?.baseCurrencyCode;
    return <CurrencyFlag size={size} currency={flagCode} />;
  };

  const renderTransactions = (transactions) => {
    return (
      <div>
        {_.map(transactions, (o, index) => {
          return renderTransaction(o, index);
        })}
      </div>
    );
  };

  const componentRef = useRef();

  const renderCustomerImages = ({ images }) => {
    return (
      <div>
        <Divider horizontal>{t('Image')}</Divider>
        {renderImages(images)}
      </div>
    );
  };

  const renderImages = (images) => {
    return (
      <div>
        <b>{`${images.length} ${t('Image')}`}</b>
        <br></br>
        <Image.Group size="medium">
          {_.map(images, (key) => {
            const url = imageUrls[key]?.url;
            return (
              <Image
                key={key}
                src={url}
                as="a"
                size="medium"
                href={url}
                target="_blank"
              />
            );
          })}
        </Image.Group>
      </div>
    );
  };

  return (
    <div>
      <Modal
        size="large"
        closeIcon
        onClose={() => {
          setOpen(false);
        }}
        onOpen={() => {
          setOpen(true);
        }}
        open={open}
        trigger={<Icon name="info" fitted />}
      >
        <Modal.Header>{t('Transaction Details')}</Modal.Header>
        <Modal.Content>
          <Grid padded>
            <Grid.Row>
              <ReceiptEmailModal groupId={_.first(transactions)?.groupId} />
              <ReactToPrint
                trigger={() => (
                  <StyledButton primary>{t('Print Receipt')}</StyledButton>
                )}
                content={() => componentRef.current}
              />
            </Grid.Row>
          </Grid>

          <div style={{ display: 'none' }}>
            <ReceiptToPrint ref={componentRef} transactions={transactions} />
          </div>

          <br />
          {customer && (
            <div>
              <Divider horizontal>{t('Customer')}</Divider>
              {renderEntry(t('Email'), customer?.email)}
              {renderEntry(t('Full Name'), customer?.fullName)}
              {renderEntry(t('Address'), customer?.address)}
              {renderPersonalId(customer)}
              {customer?.birthday &&
                renderEntry(
                  t('Birthday'),
                  format(new Date(customer?.birthday), 'P')
                )}
              {customer?.images?.length > 0 && renderCustomerImages(customer)}
            </div>
          )}
          <Divider horizontal>{t('Transaction')}</Divider>
          {renderGroupInfo(transactions)}
          {renderTransactions(transactions)}
          <HistoryTransactions
            showDetailIcon={false}
            transactions={transactions}
          />
          <br />
          <Divider horizontal>{t('Summary')}</Divider>
          <TransactionTotal transactions={transactions} />
        </Modal.Content>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    imageUrls: state.customerImage.results,
  };
};

export default connect(mapStateToProps, { fetchImageUrls })(
  TransactionDetailModal
);
