import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  Button,
  Card,
  Grid,
  Icon,
  List,
  Divider,
} from 'semantic-ui-react';
import { formatPhoneNumber } from 'react-phone-number-input';
import { addEmployee, fetchCurrentUser } from '../actions';
import EmailModal from './EmailModal';
import ErrorMessage from './ErrorMessage';
import { StyledButton } from './styledComponents';
import { useTranslation } from 'react-i18next';

const CompanySetting = ({
  currentUser,
  addEmployee,
  fetchCurrentUser,
  addEmployeeErrors,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    const fetch = async () => {
      fetchCurrentUser();
    };
    fetch();
  }, []);
  const handleEditClicked = () => {};

  const handleAddEmployeeClicked = async (formValue) => {
    await addEmployee(formValue.email);
    await fetchCurrentUser();
  };
  const handleResendClicked = async (email) => {
    await addEmployee(email);
  };

  const statusButton = ({ signupStatus, email }) => {
    if (signupStatus === 'INVITED') {
      return (
        <Button
          fluid
          content="Resend"
          onClick={() => handleResendClicked(email)}
        />
      );
    } else {
      return <Button fluid active content="Accepted" color="green" />;
    }
  };

  const renderEmployeeRows = (employees) => {
    return _.map(employees, (o) => {
      return (
        <Table.Row key={o.id}>
          <Table.Cell>{o.email}</Table.Cell>
          <Table.Cell>{statusButton(o)}</Table.Cell>
        </Table.Row>
      );
    });
  };

  const renderEmployees = (employees) => {
    return (
      <Table compact celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={12}>{t('Email')}</Table.HeaderCell>
            <Table.HeaderCell width={4}>Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{renderEmployeeRows(employees)}</Table.Body>
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan="4">
              <EmailModal
                headerTitle={t('Add Employee')}
                handleOnSubmit={handleAddEmployeeClicked}
              >
                <StyledButton
                  floated="right"
                  icon
                  labelPosition="left"
                  primary
                  size="small"
                >
                  <Icon name="user" /> {t('Add Employee')}
                </StyledButton>
              </EmailModal>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  };

  const renderCompanyCard = ({
    id,
    name,
    employeeIds,
    baseCurrencyCode,
    address,
    phoneNumber,
  }) => {
    return (
      <Card>
        <Card.Content>
          <Card.Header>
            <Grid>
              <Grid.Column floated="left" width="14">{`${name}`}</Grid.Column>
              <Grid.Column floated="right" width="2">
                <Icon name="edit" size="small" onClick={handleEditClicked} />
              </Grid.Column>
            </Grid>
          </Card.Header>
        </Card.Content>
        <Card.Content>
          <List>
            <List.Item>
              <b>{`${t('Company Id')}:`} </b>
              {id}
            </List.Item>
            <List.Item>
              <b>{`${t('Number of employees')}:`} </b>
              {employeeIds.length}
            </List.Item>
            <List.Item>
              <b>{`${t('Base Currency Code')}:`} </b>
              {baseCurrencyCode}
            </List.Item>
            {address && (
              <List.Item>
                <b>{`${t('Address')}:`} </b>
                {address}
              </List.Item>
            )}
            {phoneNumber && (
              <List.Item>
                <b>{`${t('Phone Number')}:`} </b>
                {formatPhoneNumber(phoneNumber)}
              </List.Item>
            )}
          </List>
        </Card.Content>
      </Card>
    );
  };
  return (
    <div>
      <h2>{t('Company Setting')}</h2>
      {renderCompanyCard(currentUser.selectedCompany)}
      <Divider horizontal>{t('Employee')}</Divider>
      {renderEmployees(currentUser.selectedCompany.employeeIds)}
      {addEmployeeErrors && <ErrorMessage errors={addEmployeeErrors} />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    addEmployeeErrors: state.addEmployee.errors,
  };
};

export default connect(mapStateToProps, { addEmployee, fetchCurrentUser })(
  CompanySetting
);
