import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

export const LinkedButton = styled(Button)({
  color: 'black!important',
  padding: '0!important',
  border: 'none!important',
  background: 'none!important',
  fontWeight: 'normal!important',
});

export const StyledButton = styled(Button)({
  color: 'black!important',
});
