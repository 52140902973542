import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchCurrentUser } from '../actions';

const CurrentUser = ({ fetchCurrentUser, signin, children }) => {
  useEffect(() => {
    const fetchAsync = async () => {
      await fetchCurrentUser();
    };
    fetchAsync();
  }, [signin]);
  return <div>{children}</div>;
};

const mapStatesToProps = (state) => {
  return {
    signin: state.signin,
    currentUser: state.currentUser,
  };
};

export default connect(mapStatesToProps, { fetchCurrentUser })(CurrentUser);
