import _, { isNaN } from 'lodash';
import React, { useEffect, useState } from 'react';
import cc from 'currency-codes';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import {
  Button,
  Dropdown,
  Label,
  Icon,
  Input,
  Grid,
  Segment,
  Divider,
  Checkbox,
  Popup,
  Form as SemanticForm,
} from 'semantic-ui-react';
import NumberFormat from 'react-number-format';
import { inv } from 'mathjs';
import { connect } from 'react-redux';
import {
  fetchRates,
  fetchInventory,
  createTransaction,
  clearUploadImageData,
} from '../actions';
import ErrorMessage from './ErrorMessage';
import SuccessMessage from './SuccessMessage';
import DismissibleMessage from './WarningMessage';
import CustomerInput from './CustomerInput';
import SearchCustomer from './SearchCustomer';
import AuthenticatedLayout from './AuthenticatedLayout';
import { StyledButton } from './styledComponents';
import AutoReceiptEmailModal from './AutoReceiptEmailModal';
import TransactionTotal from './TransactionTotal';
import { useTranslation } from 'react-i18next';

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

const validateNumber = (value) => {
  return isNumeric(value) && value > 0 ? undefined : 'Enter a number';
};

const currencyCodes = (codes) => {
  return _.map(codes, (code) => {
    return {
      key: code,
      text: code,
      value: code,
    };
  });
};

const renderError = ({ error, touched }) => {
  if (touched && error) {
    return (
      <div>
        <Label basic color="red" pointing>
          {error}
        </Label>
      </div>
    );
  }
};

const renderDropdown = (props, name, rates, currencies, updateRate) => {
  const className = `field ${
    props.meta.error && props.meta.touched ? 'error' : ''
  }`;
  return (
    <div className={className}>
      <Dropdown
        placeholder={props.placeholder}
        input={props.input}
        value={props.input.value}
        search
        selection
        options={currencies}
        onChange={(e, data) => {
          props.input.onChange(data.value);
          var rate = props.isBuy
            ? rates[data.value]?.buy
            : rates[data.value]?.sell;
          updateRate(name, rate);
        }}
      />
      {renderError(props.meta)}
    </div>
  );
};

const renderInput = ({ input, placeholder, meta }) => {
  const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
  return (
    <div className={className}>
      <Input autoComplete="off" placeholder={placeholder} {...input} />
      {renderError(meta)}
    </div>
  );
};

const Transaction = ({
  rates,
  inventory,
  nonZeroInventory,
  currentUser,
  fetchRates,
  fetchInventory,
  createTransaction,
  clearUploadImageData,
  isFetching,
  isSuccess,
  errors,
  lastCustomerEmail,
}) => {
  const { t } = useTranslation();
  var reduxForm;
  const [warnings, setWarnings] = useState([]);
  const [successVisible, setSuccessVisible] = useState(false);
  const [warningVisible, setWarningVisible] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [disableCustomerInput, setDisableCustomerInput] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [showCustomerForm, setShowCustomerForm] = useState(false);
  const [ignoreInventory, setIgnoreInventory] = useState(false);
  const [showReceipt, setShowReceipt] = useState(false);
  const [personalIdType, setPersonalIdType] = useState(''); //hack around react final form
  const toggleSuccessMessage = () => {
    setSuccessVisible(true);
    window.setTimeout(() => {
      setSuccessVisible(false);
    }, 2000);
  };

  const calculateLowFund = () => {
    var filtered = _.filter(inventory, (o) => {
      if (o.amountCent / 100 < parseFloat(o.warningThreshold)) {
        return o;
      }
    });
    return _.map(filtered, (o) => {
      return `$${o.amountCent / 100} ${o.currencyCode} left`;
    });
  };

  useEffect(() => {
    const fetch = async () => {
      if (currentUser?.companies?.length > 0) {
        // Show default rate
        await fetchRates();

        // Show what currency is available
        await fetchInventory();
      }
    };
    fetch();
  }, [currentUser]);

  useEffect(() => {
    const fetch = async () => {
      if (isSuccess && hasSubmitted) {
        toggleSuccessMessage();
        reduxForm.reset();
        setIgnoreInventory(false);
        setDisableCustomerInput(false);
        setShowReceipt(true);
        // Show low fund warning if needed
        await fetchInventory();
      }
    };
    fetch();
  }, [isSuccess]);

  useEffect(() => {
    let warnings = calculateLowFund();
    if (warnings.length > 0) {
      setWarningVisible(true);
      setWarnings(warnings);
    } else {
      setWarningVisible(false);
    }
  }, [inventory]);

  useEffect(() => {
    return () => {
      setHasSubmitted(false);
      clearUploadImageData();
    };
  }, []);
  const onSubmit = async (formValues) => {
    setHasSubmitted(true);
    setShowCustomerForm(false);
    formValues.personalIdType = personalIdType; //hack around react final form
    await createTransaction(formValues, selectedCustomer?.id, ignoreInventory);
    clearUploadImageData();
  };

  const renderSubtotal = (item) => {
    if (item?.amount && item?.rate) {
      var baseCurrency = '';
      if (currentUser.companies) {
        baseCurrency = currentUser.companies[0].baseCurrencyCode;
      }
      const total =
        Math.round((item.amount * item.rate + Number.EPSILON) * 100) / 100;

      return (
        <div>
          <Grid>
            <Grid.Row>
              <Grid.Column width={6}>
                <b>Subtotal: </b>
                <NumberFormat
                  value={total}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                  suffix={` ${baseCurrency}`}
                />
              </Grid.Column>
              <Grid.Column width={6}>
                <b>Inverse rate: </b>
                <NumberFormat
                  value={inv(parseFloat(item.rate)).toPrecision(6)}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const renderCustomerForm = () => {
    return (
      <div>
        <Segment>
          <Button
            icon
            floated="right"
            onClick={() => {
              setDisableCustomerInput(false);
              setSelectedCustomer();
              setShowCustomerForm(false);
            }}
          >
            <Icon name="close" size="small" />
          </Button>

          <Grid container columns={2} divided relaxed stackable>
            <Grid.Row stretched={false}>
              <Grid.Column floated="right" width="2"></Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <SearchCustomer
                  didSelectCustomer={(customer) => {
                    setDisableCustomerInput(true);
                    setSelectedCustomer(customer);
                  }}
                  didClickClose={() => {
                    setDisableCustomerInput(false);
                    setSelectedCustomer();
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <CustomerInput
                  disableInput={disableCustomerInput}
                  handlePersonalIdType={(value) => setPersonalIdType(value)}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    );
  };

  const renderForm = (rates) => {
    return (
      <div>
        <Form
          onSubmit={onSubmit}
          mutators={{
            ...arrayMutators,
            updateRate: (args, state, utils) => {
              utils.changeValue(state, `${args[0]}.rate`, () => args[1]);
            },
          }}
          validate={(values) => {
            const errors = {};
            if (values.firstName && !values.lastName) {
              errors.lastName = 'Last name required';
            }
            if (!values.firstName && values.lastName) {
              errors.firstName = 'First name required';
            }
            return errors;
          }}
          render={({
            handleSubmit,
            form: {
              mutators: { push, pop },
            },
            form,
            submitting,
            pristine,
            values,
          }) => {
            reduxForm = form;
            return (
              <SemanticForm onSubmit={handleSubmit}>
                <Divider horizontal>{t('Customer')}</Divider>
                {showCustomerForm && renderCustomerForm()}
                {!showCustomerForm && (
                  <Grid padded>
                    <Grid.Row>
                      <Grid.Column mobile={16} computer={4}>
                        <Button
                          fluid
                          secondary
                          type="button"
                          onClick={() => setShowCustomerForm(true)}
                        >
                          {t('Add Customer')}
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                )}

                <Divider horizontal>{t('Buy')}</Divider>
                <Grid padded>
                  <Grid.Row>
                    <Grid.Column mobile={16} computer={4}>
                      <Button
                        fluid
                        secondary
                        type="button"
                        onClick={() => push('buy', undefined)}
                      >
                        {t('Buy Currency')}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <FieldArray name="buy">
                      {({ fields }) => {
                        return fields.map((name, index) => {
                          return (
                            <div key={name}>
                              <Grid padded columns={4}>
                                <Grid.Column mobile={15} computer={5}>
                                  <Field
                                    name={`${name}.currencyCode`}
                                    placeholder="Currency Code"
                                    isBuy={true}
                                    validate={(value) =>
                                      value ? undefined : 'Currency code needed'
                                    }
                                  >
                                    {(props) => {
                                      return renderDropdown(
                                        props,
                                        name,
                                        rates,
                                        currencyCodes(cc.codes()),
                                        form.mutators.updateRate
                                      );
                                    }}
                                  </Field>
                                </Grid.Column>
                                <Grid.Column mobile={15} computer={5}>
                                  <Field
                                    name={`${name}.amount`}
                                    placeholder="Amount"
                                    validate={validateNumber}
                                  >
                                    {(props) => {
                                      return renderInput(props);
                                    }}
                                  </Field>
                                </Grid.Column>
                                <Grid.Column mobile={15} computer={5}>
                                  <Field
                                    name={`${name}.rate`}
                                    placeholder="Buy Rate"
                                    validate={validateNumber}
                                  >
                                    {(props) => {
                                      return renderInput(props, index);
                                    }}
                                  </Field>
                                </Grid.Column>
                                <Grid.Column
                                  mobile={1}
                                  computer={1}
                                  floated="right"
                                >
                                  <Button
                                    icon
                                    onClick={() => fields.remove(index)}
                                  >
                                    <Icon name="close" />
                                  </Button>
                                </Grid.Column>
                              </Grid>
                              <Grid padded>
                                <Grid.Row>
                                  <Grid.Column>
                                    {renderSubtotal(values.buy[index])}
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            </div>
                          );
                        });
                      }}
                    </FieldArray>
                  </Grid.Row>
                </Grid>
                <Divider horizontal>{t('Sell')}</Divider>
                <Grid padded>
                  <Grid.Row>
                    <Grid.Column mobile={16} computer={4}>
                      <Button
                        fluid
                        secondary
                        type="button"
                        onClick={() => push('sell', undefined)}
                      >
                        {t('Sell Currency')}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <FieldArray name="sell">
                      {({ fields }) => {
                        return fields.map((name, index) => {
                          return (
                            <div key={name}>
                              <Grid padded columns={4}>
                                <Grid.Column mobile={15} computer={5}>
                                  <Field
                                    name={`${name}.currencyCode`}
                                    placeholder="Currency Code"
                                    isBuy={false}
                                    validate={(value) =>
                                      value ? undefined : 'Currency code needed'
                                    }
                                  >
                                    {(props) => {
                                      return renderDropdown(
                                        props,
                                        name,
                                        rates,
                                        currencyCodes(_.keys(nonZeroInventory)),
                                        form.mutators.updateRate
                                      );
                                    }}
                                  </Field>
                                </Grid.Column>
                                <Grid.Column mobile={15} computer={5}>
                                  <Field
                                    name={`${name}.amount`}
                                    placeholder="Amount"
                                    validate={validateNumber}
                                  >
                                    {(props) => {
                                      return renderInput(props);
                                    }}
                                  </Field>
                                </Grid.Column>
                                <Grid.Column mobile={15} computer={5}>
                                  <Field
                                    name={`${name}.rate`}
                                    placeholder="Sell Rate"
                                    validate={validateNumber}
                                  >
                                    {(props) => {
                                      return renderInput(props, index);
                                    }}
                                  </Field>
                                </Grid.Column>
                                <Grid.Column
                                  mobile={1}
                                  computer={1}
                                  floated="right"
                                >
                                  <Button
                                    icon
                                    onClick={() => fields.remove(index)}
                                  >
                                    <Icon name="close" />
                                  </Button>
                                </Grid.Column>
                              </Grid>
                              <Grid padded>
                                <Grid.Row>
                                  <Grid.Column>
                                    {renderSubtotal(values.sell[index])}
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            </div>
                          );
                        });
                      }}
                    </FieldArray>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <TransactionTotal formValues={values} title="Total" />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column mobile={4} computer={2}>
                      <StyledButton
                        primary
                        loading={isFetching}
                        disabled={submitting || pristine}
                      >
                        {t('Submit')}
                      </StyledButton>
                    </Grid.Column>
                    <Grid.Column width={3}>
                      <Popup
                        content={t(
                          'Submit transaction even if not enough inventory'
                        )}
                        trigger={
                          <Checkbox
                            label={t('Ignore Inventory')}
                            checked={ignoreInventory}
                            onChange={(e, data) => {
                              setIgnoreInventory(data.checked);
                            }}
                          />
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </SemanticForm>
            );
          }}
        />
      </div>
    );
  };

  return (
    <div>
      <AuthenticatedLayout>
        <h2>{t('Transaction')}</h2>
        {warningVisible && (
          <DismissibleMessage
            warning
            header={t('Low Inventory')}
            messages={warnings}
            onDismiss={() => setWarningVisible(false)}
          />
        )}
        {successVisible && <SuccessMessage message="Transaction recorded" />}
        {renderForm(rates)}
        <AutoReceiptEmailModal
          open={showReceipt}
          email={lastCustomerEmail}
          didPressClose={() => {
            setShowReceipt(false);
          }}
        />
        <ErrorMessage errors={errors} />
      </AuthenticatedLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  // Filter out empty inventory currencies
  var filteredInventory = _.filter(state.inventory.results, (o) => {
    return parseFloat(o.amountCent) > 0;
  });
  return {
    rates: _.mapKeys(state.rates, 'currencyCode'),
    inventory: state.inventory.results,
    nonZeroInventory: _.mapKeys(filteredInventory, 'currencyCode'),
    currentUser: state.currentUser,
    isFetching: state.transactions.isFetching,
    isSuccess: state.transactions.isSuccess,
    errors: state.transactions.errors,
    lastGroupId: state.transactions.lastGroupId,
    lastCustomerEmail: state.transactions.lastCustomerEmail,
  };
};

export default connect(mapStateToProps, {
  fetchRates,
  fetchInventory,
  createTransaction,
  clearUploadImageData,
})(Transaction);
