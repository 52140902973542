import React from 'react';
import NumberFormat from 'react-number-format';

const CurrencyFormat = ({ cent, ...args }) => {
  return (
    <NumberFormat
      value={cent / 100}
      displayType={'text'}
      thousandSeparator={true}
      fixedDecimalScale={true}
      decimalScale={2}
      prefix={'$'}
      {...args}
    />
  );
};

export default CurrencyFormat;
