import _ from 'lodash';
import React from 'react';
import { Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const ErrorMessage = ({ errors }) => {
  const { t } = useTranslation();
  const errorMessages = (errors) => {
    return _.map(errors, (error) => {
      return error.message;
    });
  };
  if (!errors) {
    return null;
  } else if (errors.length > 1) {
    return (
      <Message
        error
        header="Something went wrong"
        list={errorMessages(errors)}
      />
    );
  } else {
    return (
      <Message negative>
        <Message.Header>{t('Something went wrong')}</Message.Header>
        <p>{t(_.first(errors).message)}</p>
      </Message>
    );
  }
};

export default ErrorMessage;
