import React from 'react';
import Layout from './Layout';
import { Link } from 'react-router-dom';
import { Container, Header, Icon } from 'semantic-ui-react';
import { StyledButton } from './styledComponents';

const TermsCondition = () => {
  return (
    <div>
      <Layout>
        <Container text>
          <Header as="h2">Terms and Conditions</Header>
        </Container>
      </Layout>
    </div>
  );
};

export default TermsCondition;
