import React from 'react';
import { connect } from 'react-redux';
import { fetchCurrentUser } from '../actions';
import SigninButton from './SigninButton';
import { useTranslation } from 'react-i18next';

const Logout = ({ currentUser }) => {
  /*useEffect(() => {
    fetchCurrentUser();
  }, [signin]);*/
  const { t } = useTranslation();
  return (
    <div>
      <h2>{t('Logout')}</h2>
      <SigninButton isLoading={false} isSignedIn={currentUser.id} />
    </div>
  );
};

const mapStatesToProps = (state) => {
  return {
    signin: state.signin,
    currentUser: state.currentUser,
  };
};

export default connect(mapStatesToProps, { fetchCurrentUser })(Logout);
