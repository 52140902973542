import {
  SIGN_IN,
  SIGN_IN_ERROR,
  SIGN_OUT,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  SIGN_UP_ERROR,
  EMPLOYEE_SIGN_UP,
  EMPLOYEE_SIGN_UP_SUCCESS,
  EMPLOYEE_SIGN_UP_ERROR,
  CURRENT_USER,
  FETCH_RATES,
  CREATE_RATES,
  EDIT_RATES,
  DELETE_RATES,
  INVERSE_RATES,
  FETCH_INVENTORY,
  FETCH_INVENTORY_ERROR,
  EDIT_INVENTORY,
  EDIT_INVENTORY_SUCCESS,
  EDIT_INVENTORY_ERROR,
  EDIT_INVENTORIES,
  EDIT_INVENTORIES_SUCCESS,
  EDIT_INVENTORIES_ERROR,
  CREATE_TRANSACTION,
  CREATE_TRANSACTION_SUCCESS,
  CREATE_TRANSACTION_ERROR,
  FETCH_TRANSACTIONS,
  FETCH_TRANSACTIONS_SUCCESS,
  FETCH_TRANSACTIONS_ERROR,
  FETCH_MORE_TRANSACTIONS,
  FETCH_MORE_TRANSACTIONS_SUCCESS,
  FETCH_MORE_TRANSACTIONS_ERROR,
  EDIT_TRANSACTION,
  EDIT_TRANSACTION_SUCCESS,
  EDIT_TRANSACTION_ERROR,
  DELETE_TRANSACTION,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_ERROR,
  FETCH_REPORT,
  FETCH_REPORT_SUCCESS,
  FETCH_REPORT_ERROR,
  FETCH_CUSTOMERS,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMERS_ERROR,
  FETCH_CUSTOMER_TRANSACTION,
  FETCH_CUSTOMER_TRANSACTION_SUCCESS,
  FETCH_CUSTOMER_TRANSACTION_ERROR,
  CLEAR_ALL_CUSTOMER_TRANSACTIONS,
  ADD_EMPLOYEE,
  ADD_EMPLOYEE_SUCCESS,
  ADD_EMPLOYEE_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  SEND_RECEIPT,
  SEND_RECEIPT_SUCCESS,
  SEND_RECEIPT_ERROR,
  CLEAR_RECEIPT_DATA,
  EXPORT_REPORT,
  EXPORT_REPORT_SUCCESS,
  EXPORT_REPORT_ERROR,
  CLEAR_EXPORT_REPORT_DATA,
  FETCH_CURRENCY_REPORT,
  FETCH_CURRENCY_REPORT_SUCCESS,
  FETCH_CURRENCY_REPORT_ERROR,
  CLEAR_CURRENCY_REPORT_DATA,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_ERROR,
  CLEAR_UPLOAD_IMAGE_STATUS,
  CLEAR_UPLOAD_IMAGE_DATA,
  DELETE_IMAGE,
  FETCH_CUSTOMER_IMAGE_URLS,
  FETCH_CUSTOMER_IMAGE_URLS_SUCCESS,
  FETCH_CUSTOMER_IMAGE_URLS_ERROR,
  LOAD_DATA,
  TRANSACTION_PAGE_SIZE,
  FETCH_STRIPE_CHECKOUT_SESSION,
  FETCH_STRIPE_CHECKOUT_SESSION_SUCCESS,
  FETCH_STRIPE_CHECKOUT_SESSION_ERROR,
  FETCH_STRIPE_PORTAL,
  FETCH_STRIPE_PORTAL_SUCCESS,
  FETCH_STRIPE_PORTAL_ERROR,
  FETCH_ADMIN_COMPANIES,
  FETCH_ADMIN_COMPANIES_SUCCESS,
  FETCH_ADMIN_COMPANIES_ERROR,
  ADMIN_SIGN_IN,
  ADMIN_SIGN_IN_SUCCESS,
  ADMIN_SIGN_IN_ERROR,
} from './types';
import api from '../apis/api';
import history from '../history';
import _ from 'lodash';
import {
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
} from 'date-fns';

export const signIn = (formValues) => async (dispatch) => {
  try {
    const response = await api.post('/api/users/signin', { ...formValues });
    dispatch({ type: SIGN_IN, payload: response.data });
  } catch (error) {
    dispatch(errorActionCreator(SIGN_IN_ERROR, error.response.data));
  }
};

export const signUp = (formValues) => async (dispatch) => {
  try {
    dispatch({ type: SIGN_UP });
    const response = await api.post('/api/users/signup', { ...formValues });
    dispatch({ type: SIGN_UP_SUCCESS, payload: response.data });
    //TODO: push to subscription plan when payment is accepted on production
    history.push('/subscription-plan');
    //history.push('/');
  } catch (error) {
    dispatch(errorActionCreator(SIGN_UP_ERROR, error.response.data));
  }
};

export const employeeSignUp = (formValues, token) => async (dispatch) => {
  try {
    dispatch({ type: EMPLOYEE_SIGN_UP });
    const response = await api.post('/api/users/employee-signup', {
      ...formValues,
      token,
    });
    dispatch({ type: EMPLOYEE_SIGN_UP_SUCCESS, payload: response.data });
    history.push('/');
  } catch (error) {
    dispatch(errorActionCreator(EMPLOYEE_SIGN_UP_ERROR, error.response.data));
  }
};

export const signOut = () => async (dispatch) => {
  const response = await api.post('/api/users/signout');
  dispatch({ type: SIGN_OUT, payload: response.data });
  history.push('/');
};

export const fetchCurrentUser = () => async (dispatch) => {
  const response = await api.get('/api/users/currentuser');
  dispatch({ type: CURRENT_USER, payload: response.data });
};

export const fetchRates = () => async (dispatch, getState) => {
  //TODO: hardcoding to first company always
  const companyId = getState().currentUser.companies[0].id;
  const response = await api.get('/api/rate', {
    params: { companyId: companyId },
  });
  dispatch({ type: FETCH_RATES, payload: response.data });
};

export const inverseRates = (rates) => {
  return {
    type: INVERSE_RATES,
    payload: rates,
  };
};

export const createRate = (formValues) => async (dispatch, getState) => {
  const companyId = getState().currentUser.companies[0].id;
  const response = await api.post(
    '/api/rate',
    {
      ...formValues,
    },
    { params: { companyId } }
  );
  dispatch({ type: CREATE_RATES, payload: response.data });
};

export const editRate = (id, formValues) => async (dispatch, getState) => {
  const companyId = getState().currentUser.companies[0].id;
  const response = await api.patch(
    `/api/rate/${id}`,
    {
      ...formValues,
    },
    {
      params: { companyId },
    }
  );
  dispatch({ type: EDIT_RATES, payload: response.data });
};

export const deleteRate = (id) => async (dispatch, getState) => {
  const companyId = getState().currentUser.companies[0].id;
  await api.delete(`/api/rate/${id}`, {
    params: { companyId },
  });
  dispatch({ type: DELETE_RATES, payload: id });
};

export const fetchInventory = () => async (dispatch, getState) => {
  try {
    //TODO: hardcoding to first company always
    const companyId = getState().currentUser.companies[0].id;
    const response = await api.get('/api/inventory', {
      params: { company: companyId },
    });
    dispatch({ type: FETCH_INVENTORY, payload: response?.data });
  } catch (error) {
    dispatch(errorActionCreator(FETCH_INVENTORY_ERROR, error.response?.data));
  }
};

export const editInventory = (id, formValues) => async (dispatch, getState) => {
  try {
    dispatch({ type: EDIT_INVENTORY });
    //TODO: hardcoding to first company always
    const companyId = getState().currentUser.companies[0].id;
    const response = await api.patch(
      `/api/inventory/${id}`,
      { ...formValues },
      {
        params: { company: companyId },
      }
    );
    dispatch({ type: EDIT_INVENTORY_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch(errorActionCreator(EDIT_INVENTORY_ERROR, error.response.data));
  }
};

export const editInventories = (formValues) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_INVENTORIES });
    const response = await api.patch('/api/inventory/bulk', formValues);
    dispatch({ type: EDIT_INVENTORIES_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch(errorActionCreator(EDIT_INVENTORIES_ERROR, error.response.data));
  }
};

export const createTransaction =
  (
    formValues,
    customerId,
    ignoreInventory = false,
    modifyBaseInventory = true
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: CREATE_TRANSACTION });
      const company = getState().currentUser.companies[0].id;
      const images = _.map(getState().imageUpload.results, 'key');
      var history = [];
      _.map(formValues.buy, (value) => {
        var baseAmountCent = Math.round(
          (value.amount * value.rate + Number.EPSILON) * 100
        );
        var amountCent = Math.round(
          (parseFloat(value.amount) + Number.EPSILON) * 100
        );
        var transaction = {
          amount: value.amount,
          amountCent,
          baseAmountCent,
          currencyCode: value.currencyCode,
          rate: value.rate,
          type: 'buy',
        };
        history.push(transaction);
      });
      _.map(formValues.sell, (value) => {
        var baseAmountCent = Math.round(
          (value.amount * value.rate + Number.EPSILON) * 100
        );
        var amountCent = Math.round(
          (parseFloat(value.amount) + Number.EPSILON) * 100
        );
        var transaction = {
          amount: value.amount,
          amountCent,
          baseAmountCent,
          currencyCode: value.currencyCode,
          rate: value.rate,
          type: 'sell',
        };
        history.push(transaction);
      });
      const {
        firstName,
        lastName,
        address,
        personalId,
        personalIdType,
        email,
        birthday,
      } = formValues;
      const response = await api.post('/api/transactions', {
        history,
        company,
        firstName,
        lastName,
        address,
        personalId,
        personalIdType,
        customerId,
        ignoreInventory,
        modifyBaseInventory,
        email,
        birthday,
        images,
      });
      dispatch({ type: CREATE_TRANSACTION_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch(
        errorActionCreator(CREATE_TRANSACTION_ERROR, error.response.data)
      );
    }
  };

export const fetchTransaction =
  (start, end, currency) => async (dispatch, getState) => {
    try {
      dispatch({ type: FETCH_TRANSACTIONS });
      const currentUser = getState().currentUser;
      if (!currentUser.companies) {
        return;
      }
      const company = currentUser.companies[0].id;
      const startDate = startOfDay(start);
      const endDate = endOfDay(end);
      const response = await api.get('/api/transactions', {
        params: {
          company,
          startDate,
          endDate,
          currency,
          pageSize: TRANSACTION_PAGE_SIZE,
        },
      });
      dispatch({ type: FETCH_TRANSACTIONS_SUCCESS, payload: response?.data });
    } catch (error) {
      dispatch(
        errorActionCreator(FETCH_TRANSACTIONS_ERROR, error.response?.data)
      );
    }
  };

export const fetchMoreTransaction =
  (start, end, currency, lastDate) => async (dispatch, getState) => {
    try {
      dispatch({ type: FETCH_MORE_TRANSACTIONS });
      const currentUser = getState().currentUser;
      if (!currentUser.companies) {
        return;
      }
      const company = currentUser.companies[0].id;
      const startDate = startOfDay(start);
      const endDate = endOfDay(end);
      const response = await api.get('/api/transactions', {
        params: {
          company,
          startDate,
          endDate,
          currency,
          lastDate,
          pageSize: TRANSACTION_PAGE_SIZE,
        },
      });
      dispatch({
        type: FETCH_MORE_TRANSACTIONS_SUCCESS,
        payload: response?.data,
      });
    } catch (error) {
      dispatch(
        errorActionCreator(FETCH_MORE_TRANSACTIONS_ERROR, error.response?.data)
      );
    }
  };

export const editTransaction =
  (id, formValues) => async (dispatch, getState) => {
    try {
      dispatch({ type: EDIT_TRANSACTION });
      const currentUser = getState().currentUser;
      if (!currentUser.companies) {
        return;
      }
      const company = currentUser.companies[0].id;
      var baseAmountCent = Math.round(
        (formValues.amount * formValues.rate + Number.EPSILON) * 100
      );
      var amountCent = Math.round(
        (parseFloat(formValues.amount) + Number.EPSILON) * 100
      );
      formValues.amountCent = amountCent;
      formValues.baseAmountCent = baseAmountCent;
      const response = await api.patch(
        `/api/transactions/history/${id}`,
        { ...formValues },
        {
          params: {
            company,
          },
        }
      );
      dispatch({ type: EDIT_TRANSACTION_SUCCESS, payload: response?.data });
    } catch (error) {
      dispatch(
        errorActionCreator(EDIT_TRANSACTION_ERROR, error.response?.data)
      );
    }
  };

export const deleteTransaction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_TRANSACTION });
    const currentUser = getState().currentUser;
    if (!currentUser.companies) {
      return;
    }
    const company = currentUser.companies[0].id;
    const response = await api.delete(`/api/transactions/history/${id}`, {
      params: {
        company,
      },
    });
    dispatch({ type: DELETE_TRANSACTION_SUCCESS, payload: response?.data });
  } catch (error) {
    dispatch(
      errorActionCreator(DELETE_TRANSACTION_ERROR, error.response?.data)
    );
  }
};

export const fetchReport =
  (date, start, end, type) => async (dispatch, getState) => {
    try {
      const currentUser = getState().currentUser;
      if (!currentUser.companies) {
        return;
      }
      const company = currentUser.companies[0].id;
      dispatch({ type: FETCH_REPORT });
      var startDate, endDate;
      if (type === 'daily') {
        startDate = startOfDay(date);
        endDate = endOfDay(date);
      } else if (type === 'monthly') {
        startDate = startOfMonth(date);
        endDate = endOfMonth(date);
      } else if (type === 'yearly') {
        startDate = startOfYear(date);
        endDate = endOfYear(date);
      } else if (type === 'custom') {
        startDate = startOfDay(start);
        endDate = endOfDay(end);
      }
      const response = await api.get('/api/report', {
        params: {
          company,
          startDate,
          endDate,
        },
      });
      dispatch({ type: FETCH_REPORT_SUCCESS, payload: response?.data });
    } catch (error) {
      dispatch(errorActionCreator(FETCH_REPORT_ERROR, error.response?.data));
    }
  };

export const fetchCustomers = (name) => async (dispatch, getState) => {
  try {
    const currentUser = getState().currentUser;
    if (!currentUser.companies) {
      return;
    }
    const company = currentUser.companies[0].id;
    dispatch({ type: FETCH_CUSTOMERS });
    const response = await api.get('/api/customer', {
      params: {
        company,
        name,
      },
    });
    dispatch({ type: FETCH_CUSTOMERS_SUCCESS, payload: response?.data });
  } catch (error) {
    dispatch(errorActionCreator(FETCH_CUSTOMERS_ERROR, error.response?.data));
  }
};

export const fetchCustomerTransactions =
  (customerId) => async (dispatch, getState) => {
    try {
      const currentUser = getState().currentUser;
      if (!currentUser.companies) {
        return;
      }
      const company = currentUser.companies[0].id;

      dispatch({ type: FETCH_CUSTOMER_TRANSACTION });
      const response = await api.get(
        `/api/customer/${customerId}/transactions`,
        {
          params: {
            company,
          },
        }
      );
      dispatch({
        type: FETCH_CUSTOMER_TRANSACTION_SUCCESS,
        payload: response?.data,
      });
    } catch (error) {
      dispatch(
        errorActionCreator(
          FETCH_CUSTOMER_TRANSACTION_ERROR,
          error.response?.data
        )
      );
    }
  };

export const clearAllCustomerTransactions = () => async (dispatch) => {
  dispatch({ type: CLEAR_ALL_CUSTOMER_TRANSACTIONS });
};

export const addEmployee = (email) => async (dispatch, getState) => {
  try {
    const currentUser = getState().currentUser;
    if (!currentUser.companies) {
      return;
    }
    const company = currentUser.companies[0].id;
    dispatch({ type: ADD_EMPLOYEE });
    const response = await api.post(`/api/company/${company}/employee`, {
      email,
    });
    dispatch({ type: ADD_EMPLOYEE_SUCCESS, payload: response?.data });
  } catch (error) {
    dispatch(errorActionCreator(ADD_EMPLOYEE_ERROR, error.response?.data));
  }
};

export const resetPassword =
  (formValues, userId, token) => async (dispatch) => {
    try {
      const { password, confirmationPassword } = formValues;
      dispatch({ type: RESET_PASSWORD });
      const response = await api.post(`/api/users/reset-password`, {
        token,
        userId,
        password,
        confirmationPassword,
      });
      dispatch({ type: RESET_PASSWORD_SUCCESS, payload: response?.data });
    } catch (error) {
      dispatch(errorActionCreator(RESET_PASSWORD_ERROR, error.response?.data));
    }
  };

export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({ type: FORGOT_PASSWORD });
    const response = await api.get(`/api/users/forgot-password`, {
      params: {
        email: encodeURI(email),
      },
    });
    dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: response?.data });
  } catch (error) {
    dispatch(errorActionCreator(FORGOT_PASSWORD_ERROR, error.response?.data));
  }
};

export const sendReceipt =
  (email, groupId = null) =>
  async (dispatch, getState) => {
    try {
      const transactionId = groupId ?? getState().transactions?.lastGroupId;
      const company = getState().currentUser?.selectedCompany?.id;
      dispatch({ type: SEND_RECEIPT });
      const response = await api.get('/api/send-receipt', {
        params: {
          email,
          transactionId,
          company,
        },
      });
      dispatch({ type: SEND_RECEIPT_SUCCESS, payload: response?.data });
    } catch (error) {
      dispatch(errorActionCreator(SEND_RECEIPT_ERROR, error.response?.data));
    }
  };

export const clearReceipt = () => async (dispatch) => {
  dispatch({ type: CLEAR_RECEIPT_DATA });
};

export const exportReport =
  (date, start, end, type) => async (dispatch, getState) => {
    try {
      const company = getState().currentUser?.selectedCompany?.id;
      dispatch({ type: EXPORT_REPORT });
      var startDate, endDate;
      if (type === 'daily') {
        startDate = startOfDay(date);
        endDate = endOfDay(date);
      } else if (type === 'monthly') {
        startDate = startOfMonth(date);
        endDate = endOfMonth(date);
      } else if (type === 'yearly') {
        startDate = startOfYear(date);
        endDate = endOfYear(date);
      } else if (type === 'custom') {
        startDate = startOfDay(start);
        endDate = endOfDay(end);
      }
      const response = await api.get(`/api/report/export`, {
        params: {
          company,
          startDate,
          endDate,
        },
      });
      dispatch({ type: EXPORT_REPORT_SUCCESS, payload: response?.data });
    } catch (error) {
      dispatch(errorActionCreator(EXPORT_REPORT_ERROR, error.response?.data));
    }
  };

export const clearExportReport = () => async (dispatch, getState) => {
  dispatch({ type: CLEAR_EXPORT_REPORT_DATA });
};

export const fetchCurrencyReport =
  (date, start, end, type, currencyCode) => async (dispatch, getState) => {
    dispatch({ type: FETCH_CURRENCY_REPORT });
    try {
      const company = getState().currentUser?.selectedCompany?.id;
      var startDate, endDate;
      if (type === 'daily') {
        startDate = startOfDay(date);
        endDate = endOfDay(date);
      } else if (type === 'monthly') {
        startDate = startOfMonth(date);
        endDate = endOfMonth(date);
      } else if (type === 'yearly') {
        startDate = startOfYear(date);
        endDate = endOfYear(date);
      } else if (type === 'custom') {
        startDate = startOfDay(start);
        endDate = endOfDay(end);
      }
      const response = await api.get(`/api/report/currency`, {
        params: {
          company,
          startDate,
          endDate,
          currencyCode,
        },
      });
      dispatch({
        type: FETCH_CURRENCY_REPORT_SUCCESS,
        payload: response?.data,
      });
    } catch (error) {
      dispatch(
        errorActionCreator(FETCH_CURRENCY_REPORT_ERROR, error.response?.data)
      );
    }
  };

export const clearCurrencyReport = () => async (dispatch, getState) => {
  dispatch({ type: CLEAR_CURRENCY_REPORT_DATA });
};

export const uploadImage = (formData) => async (dispatch, getState) => {
  dispatch({ type: UPLOAD_IMAGE });
  try {
    const company = getState().currentUser?.selectedCompany?.id;
    console.log(company);
    const response = await api.post(`/api/upload`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
      params: { company },
    });
    dispatch({ type: UPLOAD_IMAGE_SUCCESS, payload: response?.data });
  } catch (error) {
    dispatch(errorActionCreator(UPLOAD_IMAGE_ERROR, error.response?.data));
  }
};

export const clearUploadImageStatus = () => async (dispatch) => {
  dispatch({ type: CLEAR_UPLOAD_IMAGE_STATUS });
};

export const clearUploadImageData = () => async (dispatch) => {
  dispatch({ type: CLEAR_UPLOAD_IMAGE_DATA });
};

export const deleteImage = (key) => async (dispatch) => {
  dispatch({ type: DELETE_IMAGE, payload: { key } });
};

export const fetchImageUrls = (keys) => async (dispatch, getState) => {
  const company = getState().currentUser?.selectedCompany?.id;
  dispatch({ type: FETCH_CUSTOMER_IMAGE_URLS });
  try {
    const response = await api.post(
      `/api/download-bulk`,
      { keys },
      { params: { company } }
    );
    dispatch({
      type: FETCH_CUSTOMER_IMAGE_URLS_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    dispatch(
      errorActionCreator(FETCH_CUSTOMER_IMAGE_URLS_ERROR, error.response?.data)
    );
  }
};

export const fetchStripeCheckoutSession =
  (priceId) => async (dispatch, getState) => {
    const company = getState().currentUser?.selectedCompany?.id;
    dispatch({ type: FETCH_STRIPE_CHECKOUT_SESSION });
    try {
      const response = await api.post(
        `/api/create-checkout-session`,
        { priceId },
        { params: { company } }
      );
      dispatch({
        type: FETCH_STRIPE_CHECKOUT_SESSION_SUCCESS,
        payload: response?.data,
      });
    } catch (error) {
      dispatch(
        errorActionCreator(
          FETCH_STRIPE_CHECKOUT_SESSION_ERROR,
          error.response?.data
        )
      );
    }
  };

export const fetchStripePortal = () => async (dispatch, getState) => {
  const company = getState().currentUser?.selectedCompany?.id;
  const customerId = getState().currentUser?.stripeCustomerId;
  dispatch({ type: FETCH_STRIPE_PORTAL });
  try {
    const response = await api.post(
      `/api/create-portal-session`,
      { customerId },
      { params: { company } }
    );
    dispatch({ type: FETCH_STRIPE_PORTAL_SUCCESS, payload: response?.data });
  } catch (error) {
    dispatch(
      errorActionCreator(FETCH_STRIPE_PORTAL_ERROR, error.response?.data)
    );
  }
};

export const fetchAdminCompanies = () => async (dispatch) => {
  dispatch({ type: FETCH_ADMIN_COMPANIES });
  try {
    const response = await api.get('/api/all-company');
    dispatch({ type: FETCH_ADMIN_COMPANIES_SUCCESS, payload: response?.data });
  } catch (error) {
    dispatch(
      errorActionCreator(FETCH_ADMIN_COMPANIES_ERROR, error.response?.data)
    );
  }
};

export const adminSignIn = (companyId) => async (dispatch) => {
  console.log('calling action');
  dispatch({ type: ADMIN_SIGN_IN });
  try {
    const response = await api.get('/api/users/admin-signin', {
      params: { companyId },
    });
    dispatch({ type: ADMIN_SIGN_IN_SUCCESS, payload: response?.data });
    history.push('/');
  } catch (error) {
    console.log(error);
    dispatch(errorActionCreator(ADMIN_SIGN_IN_ERROR, error.response?.data));
  }
};

export const load = (data) => ({ type: LOAD_DATA, data });

export const errorActionCreator = (errorType, error) => {
  return {
    type: errorType,
    error: true,
    payload: error,
  };
};
