import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import {
  Input,
  Grid,
  Menu,
  Statistic,
  Loader,
  Table,
  Divider,
  Container,
  Header,
  Icon,
  Button,
  Dropdown,
} from 'semantic-ui-react';
import cc from 'currency-codes';
import CurrencyFlag from 'react-currency-flags';
import NumberFormat from 'react-number-format';
import ErrorMessage from './ErrorMessage';
import CurrencyReport from './CurrencyReport';
import { connect } from 'react-redux';
import {
  fetchReport,
  exportReport,
  clearExportReport,
  fetchCurrencyReport,
  clearCurrencyReport,
} from '../actions';
import AuthenticatedLayout from './AuthenticatedLayout';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const Report = ({
  currentUser,
  fetchReport,
  fetchCurrencyReport,
  exportReport,
  clearExportReport,
  clearCurrencyReport,
  report,
  currencyData,
  currencyFetching,
  currencySuccess,
  currencyErrors,
  file,
  exportFetching,
  exportSuccess,
  exportErrors,
  isFetching,
  errors,
  currencyTransaction,
}) => {
  const { t } = useTranslation();
  const currencyCodes = () => {
    return _.map(cc.codes(), (code) => {
      return {
        key: code,
        text: code,
        value: code,
      };
    });
  };
  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [activeItem, setActiveItem] = useState('daily');
  const [selectedCurrency, setSelectedCurrency] = useState();

  useEffect(() => {
    const fetch = async () => {
      await fetchReport(date, startDate, endDate, activeItem);
    };
    fetch();
  }, [date, startDate, endDate, currentUser]);

  useEffect(() => {
    const download = async () => {
      if (exportSuccess && file) {
        var data = new Blob([file]);
        var csvURL = window.URL.createObjectURL(data);
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', 'Report.csv');
        tempLink.click();
      }
    };
    download();
  }, [exportSuccess]);

  useEffect(() => {
    const fetch = async () => {
      if (selectedCurrency) {
        await fetchCurrencyReport(
          date,
          startDate,
          endDate,
          activeItem,
          selectedCurrency
        );
      } else {
        clearCurrencyReport();
      }
    };
    fetch();
  }, [selectedCurrency, activeItem, date, startDate, endDate]);

  useEffect(() => {
    return () => {
      // Prevent downloading report again when rerendering component
      clearExportReport();
      clearCurrencyReport();
    };
  }, []);

  const downloadReport = async () => {
    await exportReport(date, startDate, endDate, activeItem);
  };
  const handleItemClick = async (e, { name, content }) => {
    setActiveItem(name);
    console.log(content);
    await fetchReport(date, startDate, endDate, name);
  };

  const renderPicker = () => {
    if (activeItem === 'daily') {
      return (
        <div>
          <DatePicker
            selected={date}
            onChange={(date) => setDate(date)}
            customInput={<Input type="text" />}
          />
        </div>
      );
    } else if (activeItem === 'monthly') {
      return (
        <div>
          <DatePicker
            selected={date}
            onChange={(date) => setDate(date)}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            customInput={<Input type="text" />}
          />
        </div>
      );
    } else if (activeItem === 'yearly') {
      return (
        <div>
          <DatePicker
            selected={date}
            onChange={(date) => setDate(date)}
            showYearPicker
            dateFormat="yyyy"
            customInput={<Input type="text" />}
          />
        </div>
      );
    } else if (activeItem === 'custom') {
      return (
        <div>
          <Grid>
            <Grid.Column mobile={16} computer={6}>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                customInput={
                  <Input
                    type="text"
                    label={{ basic: true, content: t('Start') }}
                    labelPosition="left"
                  />
                }
              />
            </Grid.Column>
            <Grid.Column mobile={16} computer={6}>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                customInput={
                  <Input
                    type="text"
                    label={{ basic: true, content: t('End') }}
                    labelPosition="left"
                  />
                }
              />
            </Grid.Column>
          </Grid>
        </div>
      );
    }
  };

  const renderReport = () => {
    var color = report?.profit > 0 ? 'green' : 'red';
    return (
      <div>
        <Statistic.Group size="small">
          <Statistic>
            <Statistic.Label>{t('Transactions')}:</Statistic.Label>
            <Statistic.Value>{report?.transactionNum ?? 0}</Statistic.Value>
          </Statistic>
          <Statistic color={color}>
            <Statistic.Label>{`${t('Profit')}:`}</Statistic.Label>
            <Statistic.Value>
              <NumberFormat
                value={report?.profit / 100 ?? 0}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                fixedDecimalScale
                decimalScale={2}
              />
            </Statistic.Value>
          </Statistic>
        </Statistic.Group>
      </div>
    );
  };

  const renderRow = () => {
    return _.map(currencyTransaction, (transaction) => {
      return (
        <Table.Row key={transaction.currencyCode}>
          <Table.Cell>
            <Grid>
              <Grid.Row centered columns={2}>
                <Grid.Column textAlign="center">
                  <CurrencyFlag currency={transaction.currencyCode} size="md" />
                </Grid.Column>
                <Grid.Column textAlign="center">
                  <b>{transaction.currencyCode}</b>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Table.Cell>
          <Table.Cell>
            <NumberFormat
              value={transaction.cost}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              fixedDecimalScale
              decimalScale={6}
            />
          </Table.Cell>
          <Table.Cell>
            <NumberFormat
              value={transaction.buy / 100}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              fixedDecimalScale
              decimalScale={2}
            />
          </Table.Cell>
          <Table.Cell>
            <NumberFormat
              value={transaction.sell / 100}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              fixedDecimalScale
              decimalScale={2}
            />
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const renderCurrencyTransaction = () => {
    return (
      <Table celled collapsing={false}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('Currency')}</Table.HeaderCell>
            <Table.HeaderCell>{t('Avg Cost')}</Table.HeaderCell>
            <Table.HeaderCell>{t('Buy')}</Table.HeaderCell>
            <Table.HeaderCell>{t('Sell')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{renderRow()}</Table.Body>
      </Table>
    );
  };

  const renderTransactionSection = () => {
    return (
      <div>
        <Divider horizontal>
          <Header as="h4">
            <Icon name="dollar" />
            {t('Transactions')}
          </Header>
        </Divider>
        <br></br>
        {renderCurrencyTransaction()}
      </div>
    );
  };

  const renderContent = () => {
    if (isFetching) {
      return (
        <Loader inline="centered" active size="medium" content="Loading" />
      );
    } else {
      return (
        <Grid.Column mobile={16} computer={12}>
          {renderPicker()}
          <br></br>
          <Divider horizontal>
            <Icon name="database" />
            {t('Report')}
          </Divider>
          <Container text>
            {t('Profits and transaction for date selected')}
          </Container>
          <br />
          {renderReport()}
          {renderTransactionSection()}
          <br></br>
          <Divider horizontal>
            <Icon name="database" />
            {t('Export')}
          </Divider>
          <Container text>
            {t(
              'Export all transactions including amounts, currency, rates within date range to a CSV file'
            )}
          </Container>
          <br></br>
          <Button primary loading={exportFetching} onClick={downloadReport}>
            {t('Export')}
          </Button>
          <ErrorMessage errors={exportErrors} />
          <Divider horizontal>
            <Icon name="database" />
            {t('Currency Transactions')}
          </Divider>
          <Container text>
            {t(
              'Filter currency data within date range, includes amount and number of transactions'
            )}
          </Container>
          <br></br>
          <Dropdown
            clearable
            selection
            search
            value={selectedCurrency}
            onChange={(e, data) => setSelectedCurrency(data.value)}
            onClose={() => console.log('on close')}
            options={currencyCodes()}
            placeholder={t('Currency')}
          />
          <br></br>
          <CurrencyReport
            report={currencyData}
            isFetching={currencyFetching}
            isSuccess={currencySuccess}
            errors={currencyErrors}
            baseCurrencyCode={
              currentUser.selectedCompany?.baseCurrencyCode ?? ''
            }
          />
          <br></br>
          <ErrorMessage errors={errors} />
        </Grid.Column>
      );
    }
  };

  return (
    <div>
      <AuthenticatedLayout>
        <h2>{t('Report')}</h2>
        <Grid divided="vertically">
          <Grid.Column mobile={16} computer={4}>
            <Menu vertical tabular>
              <Menu.Item
                name="daily"
                content={t('Daily')}
                active={activeItem === 'daily'}
                onClick={handleItemClick}
              />
              <Menu.Item
                name="monthly"
                content={t('Monthly')}
                active={activeItem === 'monthly'}
                onClick={handleItemClick}
              />
              <Menu.Item
                name="yearly"
                content={t('Yearly')}
                active={activeItem === 'yearly'}
                onClick={handleItemClick}
              />
              <Menu.Item
                name="custom"
                content={t('Custom')}
                active={activeItem === 'custom'}
                onClick={handleItemClick}
              />
            </Menu>
          </Grid.Column>
          {renderContent()}
        </Grid>
      </AuthenticatedLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  if (state.report.result) {
    state.report.result.profit = parseFloat(state.report.result.profit).toFixed(
      2
    );
  }
  return {
    currentUser: state.currentUser,
    isFetching: state.report.isFetching,
    isSuccess: state.report.isSuccess,
    errors: state.report.errors,
    report: state.report.result,
    currencyData: state.currencyReport.result,
    currencyFetching: state.currencyReport.isFetching,
    currencySuccess: state.currencyReport.isSuccess,
    currencyErrors: state.currencyReport.errors,
    exportFetching: state.exportReport.isFetching,
    exportSuccess: state.exportReport.isSuccess,
    exportErrors: state.exportReport.errors,
    file: state.exportReport.file,
    currencyTransaction: state.report.result?.transactions,
  };
};

export default connect(mapStateToProps, {
  fetchReport,
  exportReport,
  clearExportReport,
  clearCurrencyReport,
  fetchCurrencyReport,
})(Report);
