import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Table, Checkbox, Grid, Item } from 'semantic-ui-react';
import CurrencyFlag from 'react-currency-flags';
import NumberFormat from 'react-number-format';
import {
  fetchRates,
  inverseRates,
  createRate,
  editRate,
  deleteRate,
} from '../actions';
import RateModal from './RateModal';
import ConfirmationModal from './ConfirmationModal';
import AuthenticatedLayout from './AuthenticatedLayout';
import { inv } from 'mathjs';
import { StyledButton } from './styledComponents';
import { useTranslation } from 'react-i18next';

const Rate = ({
  fetchRates,
  inverseRates,
  deleteRate,
  createRate,
  editRate,
  rates,
  originalRates,
  currentUser,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isInverse, setIsInverse] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (currentUser.companies) {
      fetchRates();
    }
  }, [currentUser]);
  const onInverseToggleChanged = (e) => {
    e.preventDefault();
    setIsInverse(!isInverse);
    inverseRates(originalRates);
  };

  const onEditButtonClick = (e) => {
    e.preventDefault();
    setIsEdit(!isEdit);
  };

  const onDeleteButtonClick = (e, { id }) => {
    e.preventDefault();
    deleteRate(id);
  };

  const baseCurrencyCode = () => {
    var baseCurrencyCode;
    _.forEach(rates, (rate) => {
      baseCurrencyCode = rate.baseCurrencyCode;
    });
    return baseCurrencyCode;
  };

  const renderList = () => {
    const cells = rates.map((rate) => {
      return (
        <Table.Row key={rate.id}>
          <Table.Cell>
            <Grid padded="horizontally">
              <Grid.Column width={3}>
                <CurrencyFlag currency={rate.currencyCode} size="md" />
              </Grid.Column>
              <Grid.Column>
                <b>{rate.currencyCode}</b>
              </Grid.Column>
            </Grid>
          </Table.Cell>
          <Table.Cell>
            <NumberFormat
              value={rate.buy}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          </Table.Cell>
          <Table.Cell>
            <NumberFormat
              value={inv(parseFloat(rate.buy)).toPrecision(6)}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          </Table.Cell>
          <Table.Cell>
            <NumberFormat
              value={rate.sell}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          </Table.Cell>
          <Table.Cell>
            <NumberFormat
              value={inv(parseFloat(rate.sell)).toPrecision(6)}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          </Table.Cell>
          {isEdit === true && (
            <Table.Cell textAlign="center">
              {renderEditButtons(rate)}
            </Table.Cell>
          )}
        </Table.Row>
      );
    });
    return (
      <div>
        <Table celled fixed size="large">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <Grid padded="horizontally">
                  <Grid.Column width={3}>
                    {baseCurrencyCode() && (
                      <CurrencyFlag currency={baseCurrencyCode()} size="md" />
                    )}
                  </Grid.Column>
                  <Grid.Column>{baseCurrencyCode()}</Grid.Column>
                </Grid>
              </Table.HeaderCell>
              <Table.HeaderCell>{t('Buy')}</Table.HeaderCell>
              <Table.HeaderCell>{`${t('Buy')} (inv)`}</Table.HeaderCell>
              <Table.HeaderCell>{t('Sell')}</Table.HeaderCell>
              <Table.HeaderCell>{`${t('Sell')} (inv)`}</Table.HeaderCell>
              {isEdit === true && <Table.HeaderCell></Table.HeaderCell>}
            </Table.Row>
          </Table.Header>
          <Table.Body>{cells}</Table.Body>
        </Table>
      </div>
    );
  };
  const handleEditOnSubmit = (id, formValue) => {
    editRate(id, formValue);
  };
  const handleCreateOnSubmit = (formValue) => {
    createRate(formValue);
  };
  const renderEditButtons = (rate) => {
    return (
      <div>
        <Button.Group size="tiny">
          <RateModal
            rate={rate}
            header={t('Edit your rate')}
            handleOnSubmit={(formValue) => {
              handleEditOnSubmit(rate.id, formValue);
            }}
          >
            <Button color="blue" content={t('Edit')} />
          </RateModal>
          <Button.Or />
          <ConfirmationModal
            trigger={t('Delete')}
            header="Are you sure?"
            content="This will delete the rate and is not reversable"
            handleOnSubmit={(e) => {
              onDeleteButtonClick(e, rate);
            }}
          />
        </Button.Group>
      </div>
    );
  };
  return (
    <div>
      <AuthenticatedLayout>
        <Item.Group>
          <Item>
            <h2>{t('Daily Rate')}</h2>
          </Item>
          {!_.isEmpty(rates) && (
            <div>
              <Item.Group>
                <Item>
                  <Item.Content>
                    <Checkbox
                      disabled={isEdit}
                      onChange={onInverseToggleChanged}
                      label={t('Inverse')}
                      toggle
                    />
                    <Button
                      disabled={isInverse}
                      basic
                      color="blue"
                      onClick={onEditButtonClick}
                      floated="right"
                    >
                      {isEdit ? t('Cancel') : t('Edit')}
                    </Button>
                  </Item.Content>
                </Item>
                <Item>
                  <Item.Content>
                    <Item.Content>{renderList()}</Item.Content>
                  </Item.Content>
                </Item>
              </Item.Group>
            </div>
          )}

          <Item>
            <RateModal
              header={t('Add your rate')}
              handleOnSubmit={handleCreateOnSubmit}
            >
              <StyledButton disabled={isInverse} primary content={t('Add')} />
            </RateModal>
          </Item>
        </Item.Group>
      </AuthenticatedLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    rates: Object.values(state.rates),
    originalRates: state.rates,
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps, {
  fetchRates,
  inverseRates,
  deleteRate,
  createRate,
  editRate,
})(Rate);
