import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, Menu } from 'semantic-ui-react';
import InventorySetting from './InventorySetting';
import CompanySetting from './CompanySetting';
import Admin from './Admin';
import Logout from './Logout';
import Profile from './Profile';
import AuthenticatedLayout from './AuthenticatedLayout';
import { useTranslation } from 'react-i18next';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Setting = ({ currentUser }) => {
  const { t } = useTranslation();
  let query = useQuery();
  let active = query.get('active');
  const [activeItem, setActiveItem] = useState('inventory');
  const [isOwner, setIsOwner] = useState(false);

  useEffect(() => {
    if (currentUser?.selectedCompany) {
      setIsOwner(currentUser.selectedCompany.ownerId === currentUser.id);
    }
  }, [currentUser]);

  useEffect(() => {
    if (active) {
      setActiveItem(active);
    }
  }, [active]);

  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
  };
  return (
    <div>
      <AuthenticatedLayout>
        <Grid>
          <Grid.Column width={4}>
            <Menu fluid vertical tabular>
              <Menu.Item
                name="inventory"
                content={t('Inventory')}
                active={activeItem === 'inventory'}
                onClick={handleItemClick}
              />
              {isOwner && (
                <Menu.Item
                  name="company"
                  content={t('Company')}
                  active={activeItem === 'company'}
                  onClick={handleItemClick}
                />
              )}

              <Menu.Item
                name="profile"
                content={t('Profile')}
                active={activeItem === 'profile'}
                onClick={handleItemClick}
              />
              <Menu.Item
                name="logout"
                content={t('Logout')}
                active={activeItem === 'logout'}
                onClick={handleItemClick}
              />
              {currentUser.isAdmin && (
                <Menu.Item
                  name="admin"
                  content="Admin"
                  active={activeItem === 'admin'}
                  onClick={handleItemClick}
                />
              )}
            </Menu>
          </Grid.Column>

          <Grid.Column stretched width={12}>
            {activeItem === 'inventory' && <InventorySetting />}
            {activeItem === 'logout' && <Logout />}
            {activeItem === 'company' && <CompanySetting />}
            {activeItem === 'profile' && <Profile />}
            {activeItem === 'admin' && <Admin />}
          </Grid.Column>
        </Grid>
      </AuthenticatedLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};
export default connect(mapStateToProps, {})(Setting);
