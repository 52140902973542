export const errorReducer = (state, action) => {
  if (!action.error) {
    return {
      ...state,
      errors: null,
    };
  }
  return {
    ...state,
    errors: action.payload.errors,
  };
};
