import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Image, List } from 'semantic-ui-react';
import { StyledButton } from './styledComponents';
import { fetchStripePortal, fetchStripeCheckoutSession } from '../actions';
import ErrorMessage from './ErrorMessage';
import { format, isFuture } from 'date-fns';
import { useTranslation } from 'react-i18next';

const Profile = ({
  currentUser,
  fetchStripePortal,
  fetchStripeCheckoutSession,
  portalUrl,
  checkoutSessionUrl,
  errors,
}) => {
  const { t } = useTranslation();
  const getYear = (dateString) => {
    const date = new Date(dateString);
    return date.getFullYear();
  };

  const isOwner = (user) => {
    return user.id === user.selectedCompany?.ownerId;
  };

  const handleManageSubscription = async (e) => {
    e.preventDefault();
    await fetchStripePortal();
  };

  const handleResubscribe = async (e) => {
    e.preventDefault();
    await fetchStripeCheckoutSession(process.env.REACT_APP_STRIPE_PRICE_ID);
  };
  if (portalUrl) {
    return (window.location.href = `${portalUrl}`);
  }
  if (checkoutSessionUrl) {
    return (window.location.href = `${checkoutSessionUrl}`);
  }

  const renderSubscription = () => {
    return (
      <div>
        {currentUser.subscriptionStatus === 'active' && (
          <StyledButton primary size="large" onClick={handleManageSubscription}>
            {t('Manage Subscription')}
          </StyledButton>
        )}
        {currentUser.subscriptionStatus === 'canceled' && (
          <StyledButton primary size="large" onClick={handleResubscribe}>
            {t('Resubscribe')}
          </StyledButton>
        )}
        <ErrorMessage errors={errors} />
      </div>
    );
  };
  const renderSubscriptionPlanButton = () => {
    return (
      <div>
        <Link to="/subscription-plan">
          <StyledButton primary size="large">
            {t('Subscribe')}
          </StyledButton>
        </Link>
      </div>
    );
  };
  return (
    <div>
      <h2>{t('Profile')}</h2>
      <Card>
        <Image
          src="https://react.semantic-ui.com/images/avatar/large/matthew.png"
          wrapped
          ui={false}
        />
        <Card.Content>
          <Card.Header>
            {isOwner(currentUser) ? t('Owner') : t('Employee')}
          </Card.Header>
          <Card.Meta>
            <span className="date">{`${t('Joined in')}: ${getYear(
              currentUser.createdAt
            )}`}</span>
          </Card.Meta>
          <Card.Description>
            <List>
              <List.Item icon="mail" content={currentUser.email} />
              {currentUser.subscriptionEndDate && (
                <List.Item>
                  <b>{`${t('Expire on')}:`} </b>
                  {`${format(
                    new Date(currentUser.subscriptionEndDate),
                    'PPP'
                  )}`}
                </List.Item>
              )}
            </List>
          </Card.Description>
        </Card.Content>
      </Card>
      {currentUser.stripeCustomerId && renderSubscription()}
      {!currentUser.stripeCustomerId &&
        !currentUser.isFree &&
        renderSubscriptionPlanButton()}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    portalUrl: state.stripe.portalUrl,
    checkoutSessionUrl: state.stripe.sessionUrl,
    errors: state.stripe.portalErrors,
  };
};

export default connect(mapStateToProps, {
  fetchStripePortal,
  fetchStripeCheckoutSession,
})(Profile);
