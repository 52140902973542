import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Card,
  Grid,
  Icon,
  List,
  Search,
  Divider,
  Image,
} from 'semantic-ui-react';
import ErrorMessage from './ErrorMessage';
import { connect } from 'react-redux';
import { fetchCustomers, fetchImageUrls } from '../actions';
import { useTranslation } from 'react-i18next';

const SearchCustomer = ({
  customers,
  isFetching,
  errors,
  fetchCustomers,
  fetchImageUrls,
  didSelectCustomer,
  didClickClose,
  imageUrls,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [results, setResults] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState();

  useEffect(() => {
    var fetchedResults = [];
    _.map(customers, (o) => {
      var result = {
        title: `${o.fullName}`,
        description: o.address ? `${o.address}` : '',
        id: o.id,
      };
      fetchedResults.push(result);
    });
    setResults(fetchedResults);
  }, [customers]);

  const debouncedSave = useCallback(
    _.debounce(async (data) => {
      //make network call
      await fetchCustomers(data.value);
    }, 300)
  );

  const handleSearchChange = (e, data) => {
    setValue(data.value);
    debouncedSave(data);
  };

  const handleSelectCustomer = (e, data) => {
    setValue(data.result.title); //set search bar title
    const customer = _.find(customers, { id: data.result.id });
    setSelectedCustomer(customer);
    if (didSelectCustomer) {
      didSelectCustomer(customer);
      if (customer?.images?.length > 0) {
        fetchImageUrls(customer.images);
      }
    }
  };

  const handleCloseClicked = (e) => {
    if (didClickClose) {
      didClickClose(e);
    }
    setValue('');
    setResults([]);
    setSelectedCustomer();
  };

  const renderCustomerImages = ({ images }) => {
    return (
      <div>
        <Divider horizontal>{t('Image')}</Divider>
        {renderImages(images)}
      </div>
    );
  };

  const renderImages = (images) => {
    return (
      <div>
        <b>{`${images.length} ${t('Image')}`}</b>
        <br></br>
        <Image.Group size="medium">
          {_.map(images, (key) => {
            const url = imageUrls[key]?.url;
            return (
              <Image
                key={key}
                src={url}
                as="a"
                size="medium"
                href={url}
                target="_blank"
              />
            );
          })}
        </Image.Group>
      </div>
    );
  };

  const renderCustomer = (customer) => {
    return (
      <div>
        <Card>
          <Card.Content>
            <Card.Header>
              <Grid>
                <Grid.Column
                  floated="left"
                  width="14"
                >{`${customer.fullName}`}</Grid.Column>
                <Grid.Column floated="right" width="2">
                  <Icon
                    name="close"
                    size="small"
                    onClick={handleCloseClicked}
                  />
                </Grid.Column>
              </Grid>
            </Card.Header>
          </Card.Content>
          {(customer.address || customer.personalId || customer.email) && (
            <Card.Content>
              <List>
                {customer.address && (
                  <List.Item>
                    {`${t('Address')}:`} {customer.address}
                  </List.Item>
                )}
                {customer.personalId && (
                  <List.Item>
                    {`${t('Personal Id')}:`}
                    {customer.personalId}
                  </List.Item>
                )}
                {customer.email && (
                  <List.Item>
                    {`${t('Email')}:`} {customer.email}
                  </List.Item>
                )}
              </List>
            </Card.Content>
          )}
        </Card>
        {customer?.images?.length > 0 &&
          imageUrls &&
          renderCustomerImages(customer)}
        <ErrorMessage errors={errors} />
      </div>
    );
  };

  return (
    <div>
      <Search
        input={{ fluid: true, label: t('Search') }}
        loading={isFetching}
        onResultSelect={handleSelectCustomer}
        onSearchChange={handleSearchChange}
        results={results}
        value={value}
      />
      <br />
      {selectedCustomer && renderCustomer(selectedCustomer)}
      <br />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    customers: state.customers.results,
    isFetching: state.customers.isFetching,
    errors: state.customers.errors,
    imageUrls: state.customerImage.results,
  };
};

export default connect(mapStateToProps, { fetchCustomers, fetchImageUrls })(
  SearchCustomer
);
