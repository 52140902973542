import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchCurrentUser } from '../actions';
import LandingPage from './LandingPage';

const Home = ({ currentUser, signin }) => {
  if (currentUser.id || signin.isSignedIn) {
    //Default signed in page
    return <Redirect to="/transaction" />;
  } else {
    return (
      <div>
        <LandingPage />
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return { currentUser: state.currentUser, signin: state.signin };
};

export default connect(mapStateToProps, { fetchCurrentUser })(Home);
