import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import CurrencyFlag from 'react-currency-flags';
import { Grid, Table, Button } from 'semantic-ui-react';
import CurrencyFormat from './CurrencyFormat';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import TransactionEditModal from './TransactionEditModal';
import ConfirmationModal from './ConfirmationModal';
import TransactionDetailModal from './TransactionDetailModal';
import { editTransaction, deleteTransaction, fetchRates } from '../actions';
import { useTranslation } from 'react-i18next';

const HistoryTransactions = ({
  rates,
  editTransaction,
  deleteTransaction,
  fetchRates,
  currentUser,
  transactions,
  isEdit,
  showDetailIcon = true,
}) => {
  const { t } = useTranslation();
  var hasRenderedDate = {};
  var hasRenderedCustomer = {};
  var hasRenderedGroup = {};
  var hasRenderedDetail = {};

  useEffect(() => {
    var group = _.groupBy(transactions, 'groupId');
    setTransactionsGroup(group);
    const getRates = async () => {
      await fetchRates();
    };
    if (currentUser.companies) {
      getRates();
    }
  }, [transactions, currentUser]);

  const [transactionsGroup, setTransactionsGroup] = useState({});

  const handleEditOnSubmit = (id, formValues) => {
    editTransaction(id, formValues);
  };

  const handleDeleteOnSubmit = (e, transaction) => {
    deleteTransaction(transaction.id);
  };

  const renderEditButtons = (transaction) => {
    return (
      <div>
        <Button.Group size="tiny">
          <TransactionEditModal
            rates={rates}
            transaction={transaction}
            handleOnSubmit={(formValue) => {
              handleEditOnSubmit(transaction.id, formValue);
            }}
          />
          <Button.Or />
          <ConfirmationModal
            trigger={t('Delete')}
            header={`${t('Are you sure')}?`}
            content={t('This will delte the transaction and is not reversable')}
            handleOnSubmit={(e) => {
              handleDeleteOnSubmit(e, transaction);
            }}
          />
        </Button.Group>
      </div>
    );
  };

  const renderGroup = (groupId) => {
    if (hasRenderedGroup[groupId]) {
      return null;
    } else {
      hasRenderedGroup[groupId] = true;
      return (
        <Table.Cell rowSpan={transactionsGroup[groupId]?.length}>
          {groupId}
        </Table.Cell>
      );
    }
  };

  const renderDate = (groupId, value) => {
    if (hasRenderedDate[groupId]) {
      return null;
    } else {
      hasRenderedDate[groupId] = true;
      return (
        <Table.Cell rowSpan={transactionsGroup[groupId]?.length}>
          {value}
        </Table.Cell>
      );
    }
  };

  const renderCustomer = (groupId, value) => {
    if (hasRenderedCustomer[groupId]) {
      return null;
    } else {
      hasRenderedCustomer[groupId] = true;
      return (
        <Table.Cell
          textAlign="center"
          rowSpan={transactionsGroup[groupId]?.length}
        >
          {value}
        </Table.Cell>
      );
    }
  };

  const renderDetail = (groupId, value) => {
    if (hasRenderedDetail[groupId]) {
      return null;
    } else {
      hasRenderedDetail[groupId] = true;
      return (
        <Table.Cell
          textAlign="center"
          rowSpan={transactionsGroup[groupId]?.length}
        >
          <TransactionDetailModal
            transaction={value}
            transactions={transactionsGroup[groupId]}
          />
        </Table.Cell>
      );
    }
  };

  const formatTime = (date) => {
    const dateObject = new Date(date);
    return `${dateObject.toLocaleDateString()} - ${dateObject.toLocaleTimeString()}`;
  };

  const renderRow = () => {
    return _.map(transactions, (transaction, index) => {
      const positive = transaction.type === 'buy' ? true : false;
      const customerName = `${transaction.customer?.firstName ?? 'n/a'} ${
        transaction.customer?.lastName ?? ''
      }`;
      const createdTime = formatTime(transaction.createdAt);
      const {
        groupId,
        amountCent,
        baseAmountCent,
        id,
        type,
        currencyCode,
        rate,
      } = transaction;

      return (
        <Table.Row key={id}>
          {renderGroup(groupId, groupId)}
          {renderDate(groupId, createdTime)}
          {renderCustomer(groupId, customerName)}
          <Table.Cell>
            <CurrencyFormat cent={amountCent} />
          </Table.Cell>
          <Table.Cell positive={positive}>
            {transaction.type === 'buy' ? 'buy' : ''}
          </Table.Cell>
          <Table.Cell negative={!positive}>
            {transaction.type === 'sell' ? 'sell' : ''}
          </Table.Cell>
          <Table.Cell>
            <Grid>
              <Grid.Row centered columns={2}>
                <Grid.Column textAlign="center">
                  <CurrencyFlag currency={currencyCode} size="md" />
                </Grid.Column>
                <Grid.Column textAlign="center">
                  <b>{currencyCode}</b>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Table.Cell>
          <Table.Cell>
            <NumberFormat
              value={parseFloat(rate).toPrecision(6)}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          </Table.Cell>
          <Table.Cell>
            <CurrencyFormat
              cent={baseAmountCent}
              suffix={` (${type === 'buy' ? 'Give' : 'Received'})`}
            />
          </Table.Cell>
          {showDetailIcon && renderDetail(groupId, transaction)}
          {isEdit && (
            <Table.Cell textAlign="center">
              {renderEditButtons(transaction)}
            </Table.Cell>
          )}
        </Table.Row>
      );
    });
  };

  const renderTable = () => {
    if (_.size(transactions) > 0) {
      return (
        <Table celled structured>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('Id')}</Table.HeaderCell>
              <Table.HeaderCell>{t('Time')}</Table.HeaderCell>
              <Table.HeaderCell>{t('Customer')}</Table.HeaderCell>
              <Table.HeaderCell>{t('Amount')}</Table.HeaderCell>
              <Table.HeaderCell>{t('Buy')}</Table.HeaderCell>
              <Table.HeaderCell>{t('Sell')}</Table.HeaderCell>
              <Table.HeaderCell>{t('Currency')}</Table.HeaderCell>
              <Table.HeaderCell>{t('Rate')}</Table.HeaderCell>
              <Table.HeaderCell>{`${t('Amount')} (${
                currentUser.selectedCompany.baseCurrencyCode
              })`}</Table.HeaderCell>
              {showDetailIcon && (
                <Table.HeaderCell>{t('Detail')}</Table.HeaderCell>
              )}
              {isEdit && <Table.HeaderCell />}
            </Table.Row>
          </Table.Header>
          <Table.Body>{renderRow()}</Table.Body>
        </Table>
      );
    } else {
      return <h3>{t('No transactions yet')}</h3>;
    }
  };
  return <div>{renderTable()}</div>;
};

const mapStateToProps = (state) => {
  return {
    rates: _.mapKeys(state.rates, 'currencyCode'),
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps, {
  editTransaction,
  deleteTransaction,
  fetchRates,
})(HistoryTransactions);
