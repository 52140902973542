import _ from 'lodash';
import React from 'react';
import { Message } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { isFuture, differenceInCalendarDays } from 'date-fns';
import { useTranslation } from 'react-i18next';

const SubscriptionStatusHeader = ({ currentUser }) => {
  const { t } = useTranslation();
  const expireOn = new Date(currentUser?.subscriptionEndDate);
  const status = currentUser?.subscriptionStatus;
  var isOwner = currentUser.selectedCompany?.ownerId === currentUser.id;
  if (!_.isEmpty(currentUser) && isOwner) {
    if (!isFuture(expireOn) && !currentUser?.isFree) {
      console.log(currentUser);
      return (
        <div>
          <Message error>
            <Message.Header>{t('Subscription expired')}</Message.Header>
            <p>
              {t('Resubscribe in order to continue using')}{' '}
              <a href="/setting?active=profile">{t('Subscribe')}</a>
              &nbsp;
            </p>
          </Message>
          <br />
        </div>
      );
    } else if (status === 'canceled') {
      return (
        <div>
          <Message warning>
            <Message.Header>{`Subscription is canceled and expiring in ${differenceInCalendarDays(
              expireOn,
              new Date()
            )} day`}</Message.Header>
            <p>
              {t('Resubscribe in order to continue using')}{' '}
              <a href="/setting?active=profile">{t('Subscribe')}</a>
              &nbsp;
            </p>
          </Message>
          <br />
        </div>
      );
    } else {
      return <div></div>;
    }
  } else {
    return <div></div>;
  }
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps, null)(SubscriptionStatusHeader);
