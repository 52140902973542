import React, { useState, useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import {
  Form as SemanticForm,
  Input,
  Label,
  Grid,
  Message,
} from 'semantic-ui-react';
import { signIn } from '../actions';
import ErrorMessage from './ErrorMessage';
import EmailModal from './EmailModal';
import { forgotPassword } from '../actions';
import Layout from './Layout';
import { LinkedButton, StyledButton } from './styledComponents';
import { useTranslation } from 'react-i18next';

const renderInput = ({ input, label, meta }) => {
  const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
  return (
    <div className={className}>
      <label>{label}</label>
      <Input {...input} />
      {renderError(meta)}
    </div>
  );
};

const renderError = ({ error, touched }) => {
  if (touched && error) {
    return (
      <Label basic color="red" pointing>
        {error}
      </Label>
    );
  }
};

const Signin = ({
  signIn,
  forgotPassword,
  isSignedIn,
  location,
  errors,
  forgotPasswordErrors,
  forgotPasswordSuccess,
}) => {
  const { t } = useTranslation();

  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  useEffect(() => {
    if (isSignedIn) {
      setRedirectToReferrer(true);
    }
  }, [isSignedIn]);

  const onSubmit = async (formValues) => {
    await signIn(formValues);
  };

  const handleOnForgotPassword = async (formValues) => {
    await forgotPassword(formValues.email);
  };

  const { from } = location.state || { from: { pathname: '/transaction' } };
  if (redirectToReferrer === true) {
    return <Redirect to={from} />;
  }
  let submit;
  return (
    <div>
      <Layout>
        <Grid padded centered>
          <Grid.Row>
            <Grid.Column computer={8} mobile={12}>
              <h1>{t('Login')}</h1>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={8} mobile={12}>
              <Form
                onSubmit={onSubmit}
                validate={(values) => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = 'Enter your email';
                  }
                  if (!values.password) {
                    errors.password = 'Enter your password';
                  }
                  return errors;
                }}
                render={({ handleSubmit }) => {
                  submit = handleSubmit;
                  return (
                    <SemanticForm
                      className="ui form error"
                      onSubmit={handleSubmit}
                    >
                      <Field name="email" label={t('Email')} type="email">
                        {(props) => {
                          return renderInput(props);
                        }}
                      </Field>
                      <Field
                        name="password"
                        label={t('Password')}
                        type="password"
                      >
                        {(props) => {
                          return renderInput(props);
                        }}
                      </Field>
                    </SemanticForm>
                  );
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={8} mobile={12}>
              <Link to="/signup">
                <LinkedButton content={t('Create Account')} />
              </Link>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={8} mobile={12}>
              <EmailModal
                headerTitle={t('Forgot Password')}
                handleOnSubmit={handleOnForgotPassword}
              >
                <LinkedButton content={t('Forgot Password')} />
              </EmailModal>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={8} mobile={12}>
              <StyledButton primary onClick={(e) => submit(e)}>
                {t('Submit')}
              </StyledButton>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={8} mobile={12}>
              <ErrorMessage errors={errors} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={8} mobile={12}>
              <ErrorMessage errors={forgotPasswordErrors} />
            </Grid.Column>
          </Grid.Row>
          {forgotPasswordSuccess && (
            <Grid.Row>
              <Grid.Column computer={8} mobile={12}>
                <Message
                  success
                  header="Reset password email sent"
                  content="Check your email to follow the instruction."
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.signin.isSignedIn,
    errors: state.signin.errors,
    forgotPasswordErrors: state.forgotPassword.errors,
    forgotPasswordSuccess: state.forgotPassword.isSuccess,
  };
};

export default connect(mapStateToProps, { signIn, forgotPassword })(Signin);
