import React from 'react';
import { Switch, Route, Router } from 'react-router-dom';
import history from './history';
import PrivateRoute from './components/PrivateRoute';
import Home from './components/Home';
import Signup from './components/Signup';
import EmployeeSignup from './components/EmployeeSignup';
import Signin from './components/Signin';
import Rate from './components/Rate';
import Inventory from './components/Inventory';
import Transaction from './components/Transaction';
import History from './components/History';
import Report from './components/Report';
import Customer from './components/Customer';
import Setting from './components/Setting';
import ResetPassword from './components/ResetPassword';
import CurrentUser from './components/CurrentUser';
import AboutUs from './components/AboutUs';
import FAQ from './components/FAQ';
import Pricing from './components/Pricing';
import TermsCondition from './components/TermsCondition';
import PrivacyPolicy from './components/PrivacyPolicy';
import SubscriptionPlan from './components/SubscriptionPlan';
import SubscribeStatus from './components/SubscribeStatus';
import Admin from './components/Admin';
import i18n from './translation/i18n';

function App() {
  return (
    <div className="content">
      <CurrentUser>
        <Router history={history}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/signup" component={Signup} />
            <Route path="/employee-signup" component={EmployeeSignup} />
            <Route path="/signin" component={Signin} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/aboutus" component={AboutUs} />
            <Route path="/faq" component={FAQ} />
            <Route path="/pricing" component={Pricing} />
            <Route path="/terms" component={TermsCondition} />
            <Route path="/privacypolicy" component={PrivacyPolicy} />
            <Route path="/subscription-plan" component={SubscriptionPlan} />
            <PrivateRoute path="/rate" component={Rate} />
            <PrivateRoute path="/inventory" component={Inventory} />
            <PrivateRoute path="/transaction" component={Transaction} />
            <PrivateRoute path="/history" component={History} />
            <PrivateRoute path="/report" component={Report} />
            <PrivateRoute path="/customer" component={Customer} />
            <PrivateRoute path="/setting" component={Setting} />
            <PrivateRoute path="/subscribe" component={SubscribeStatus} />
            <PrivateRoute path="/admin" component={Admin} />
          </Switch>
        </Router>
      </CurrentUser>
    </div>
  );
}

export default App;
