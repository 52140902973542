import React from 'react';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Form as SemanticForm,
  Button,
  Input,
  Label,
  Grid,
  Checkbox,
} from 'semantic-ui-react';
import { employeeSignUp } from '../actions';
import ErrorMessage from './ErrorMessage';
import TOS from '../assets/Online Terms and Conditions.pdf';

const renderInput = ({ input, label, meta }) => {
  const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
  return (
    <div className={className}>
      <label>{label}</label>
      <Input {...input} />
      {renderError(meta)}
    </div>
  );
};

const renderCheckBox = (props) => {
  const className = `field ${
    props.meta.error && props.meta.touched ? 'error' : ''
  }`;
  return (
    <div className={className}>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Checkbox
              onChange={(e, data) => {
                props.input.onChange(data.checked);
              }}
            />
          </Grid.Column>
          <Grid.Column width={12}>
            <label>
              I agree to{' '}
              <a href={TOS} target="_blank">
                {props.label}
              </a>
            </label>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {renderError(props.meta)}
    </div>
  );
};

const renderError = ({ error, touched }) => {
  if (touched && error) {
    return (
      <Label basic color="red" pointing>
        {error}
      </Label>
    );
  }
};

const EmployeeSignup = ({ employeeSignUp, errors, currentUser }) => {
  var params = new URLSearchParams(useLocation().search);
  const onSubmit = (formValues) => {
    employeeSignUp(formValues, params.get('token'));
  };

  return (
    <div>
      <Grid padded centered>
        <Grid.Row>
          <Grid.Column computer={8} mobile={12}>
            <h1>Employee Signup</h1>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={8} mobile={12}>
            <Form
              onSubmit={onSubmit}
              validate={(values) => {
                const errors = {};
                if (!values.password) {
                  errors.password = 'Enter your password';
                }
                if (!values.confirmationPassword) {
                  errors.confirmationPassword =
                    'Enter your confirmation password';
                }
                if (!values.tos) {
                  errors.tos = 'Agree to terms and conditions';
                }
                return errors;
              }}
              render={({ handleSubmit }) => {
                return (
                  <SemanticForm
                    className="ui form error"
                    onSubmit={handleSubmit}
                  >
                    <Field name="password" label="Password" type="password">
                      {(props) => {
                        return renderInput(props);
                      }}
                    </Field>

                    <Field
                      name="confirmationPassword"
                      label="Confirmation password"
                      type="password"
                    >
                      {(props) => {
                        return renderInput(props);
                      }}
                    </Field>
                    <Field
                      name="tos"
                      label="Terms and conditions"
                      type="checkbox"
                    >
                      {(props) => {
                        return renderCheckBox(props);
                      }}
                    </Field>
                    <Button className="ui primary button">Sign Up</Button>
                  </SemanticForm>
                );
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={8} mobile={12}>
            <ErrorMessage errors={errors} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

const mapStatesToProps = (state) => {
  return {
    currentUser: state.currentUser,
    errors: state.signin.errors,
  };
};

export default connect(mapStatesToProps, { employeeSignUp })(EmployeeSignup);
