import React from 'react';
import Layout from './Layout';
import { Link } from 'react-router-dom';
import { Container, Header, Icon } from 'semantic-ui-react';
import { StyledButton } from './styledComponents';

const Pricing = () => {
  return (
    <div>
      <Layout>
        <Container text>
          <Header as="h2">Pricing</Header>
          <Link to="/signup">
            <StyledButton primary size="large">
              Get Started
              <Icon name="right arrow" />
            </StyledButton>
          </Link>
        </Container>
      </Layout>
    </div>
  );
};

export default Pricing;
