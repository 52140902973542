import _ from 'lodash';
import React from 'react';
import { Message } from 'semantic-ui-react';

const WarningMessage = ({ messages, ...rest }) => {
  if (!messages) {
    return null;
  } else if (messages.length === 0) {
    return null;
  } else if (messages.length > 1) {
    return <Message {...rest} list={messages} />;
  } else {
    return <Message {...rest} content={_.first(messages)} />;
  }
};

export default WarningMessage;
